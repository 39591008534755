import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Image from "./Image";
import { BsWhatsapp } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import { HiOutlineSearch } from "react-icons/hi";
import { ImSpinner2 } from "react-icons/im";

const ResultsPage = () => {
  const [results, setResults] = useState([]);
  const [numResults, setNumResults] = useState(0);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const searchQuery = new URLSearchParams(location.search).get("query");
  const navigate = useNavigate();
  const [inputQuery, setInputQuery] = useState(searchQuery || "");

  useEffect(() => {
    const fetchData = async (query) => {
      try {
        const propertyResponse = await axios.get("/listing");
        const serviceResponse = await axios.get("/service-listing");
        const restaurantsResponse = await axios.get("/restaurants-listing");
        const retailersResponse = await axios.get("/retailers-listing");

        const allResults = [
          ...propertyResponse.data,
          ...serviceResponse.data,
          ...restaurantsResponse.data,
          ...retailersResponse.data,
        ];

        // Filter the results based on the search query
        const filteredResults = allResults.filter((result) => {
          const searchQuery = query.toLowerCase();
          const itemTitle = result.title.toLowerCase();
          const itemCategory = result.category.toLowerCase();
          const itemLocation = result.location.toLowerCase();
          const itemAddress = result.address.toLowerCase();
          const itemDescription = result.description.toLowerCase();

          return (
            itemTitle.includes(searchQuery) ||
            itemCategory.includes(searchQuery) ||
            itemLocation.includes(searchQuery) ||
            itemAddress.includes(searchQuery) ||
            itemDescription.includes(searchQuery)
          );
        });

        setResults(filteredResults);
        setLoading(false);
      } catch (error) {
        console.log("Error fetching results:", error);
        setLoading(false);
      }
    };

    fetchData(searchQuery);
  }, [searchQuery]);

  useEffect(() => {
    setNumResults(results.length);
  }, [results]);

  const handleInputChange = (e) => {
    setInputQuery(e.target.value);
  };

  const handleSearch = async () => {
    navigate(`/results?query=${inputQuery}`);
    setLoading(true);

    try {
      const propertyResponse = await axios.get("/listing");
      const serviceResponse = await axios.get("/service-listing");
      const restaurantsResponse = await axios.get("/restaurants-listing");
      const retailersResponse = await axios.get("/retailers-listing");

      const allResults = [
        ...propertyResponse.data,
        ...serviceResponse.data,
        ...restaurantsResponse.data,
        ...retailersResponse.data,
      ];

      // Filter the results based on the search query
      const filteredResults = allResults.filter((result) => {
        const searchQuery = inputQuery.toLowerCase();
        const itemTitle = result.title.toLowerCase();
        const itemCategory = result.category.toLowerCase();
        const itemLocation = result.location.toLowerCase();
        const itemAddress = result.address.toLowerCase();
        const itemDescription = result.description.toLowerCase();

        return (
          itemTitle.includes(searchQuery) ||
          itemCategory.includes(searchQuery) ||
          itemLocation.includes(searchQuery) ||
          itemAddress.includes(searchQuery) ||
          itemDescription.includes(searchQuery)
        );
      });

      setResults(filteredResults);
      setNumResults(filteredResults.length);
    } catch (error) {
      console.log("Error fetching results:", error);
    } finally {
      setLoading(false);
    }
  };

  const renderResults = () => {
    if (loading) {
      return (
        <div className=" p-6 mx-auto ">
          <ImSpinner2
            className="animate-spin  text-2xl justify-center text-lime-600 mx-auto
         text-2x1"
          />
        </div>
      ); // Display a loading message or spinner
    }
    if (results.length === 0) {
      return <p>No results found.</p>;
    }

    return (
      <div className=" mt-8">
        <div className="grid sm:grid-cols-1 max-w-6xl mb-16 mx-auto ms:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {results.map((result) => (
            <Link to={`/result/${result._id}`} key={result._id}>
              <div className="w-full max-w-[352px] max z-auto cursor-pointer hover:shadow-2xl p-4 transition rounded-lg bg-white">
                <div className="relative aspect-square mb-4">
                  <div className="absolute inset-0 rounded-2xl overflow-hidden">
                    {result.photos?.[0] && (
                      <Image
                        className="img w-full h-full object-cover transform transition duration-200 ease-in-out hover:scale-120"
                        src={result.photos?.[0]}
                        alt=""
                      />
                    )}
                  </div>
                </div>
                <div className="w-full sm:w-auto lg:w-full">
                  <div>
                    <div className="flex gap-8 items-center justify-between">
                      <div className="">
                        <h2 className="font-bold text-xl ">{result.title}</h2>
                        <h2 className="text-md font-bold text-amber-600 mb-2">
                          {result.location}
                        </h2>
                        <h2 className="text-md text-lime-600 mb-4">
                          {result.address}
                        </h2>
                      </div>
                    </div>
                    <h2 className="text-sm text-gray-600  mb-2 truncate-3-lines">
                      {result.description}
                    </h2>
                    <div className="inline-block bg-green-100 font-bold text-green-700 rounded-full text-[12px] px-4 py-1 mb-2">
                      {result.category}
                    </div>
                    <h2 className="text-md font-medium mt-2 hover hidden md:block  text-sky-500 ">
                      {result.website}
                    </h2>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className=" mt-8 mb-8">
      <div className=" bg-gray-50 mx-auto rounded-xl px-4 py-4  max-w-6xl">
        <div className="flex lg:flex md:grid-cols-1 md:grid md:gap-2 ms:grid-cols-1 ms:grid ms:gap-2 sm:grid-cols-1 sm:grid sm:gap-2 ">
          <div className="relative flex-grow">
            <HiOutlineSearch className="absolute text-gray-400 h-8 w-8 inset-y-2 left-0 flex items-center pl-3" />

            <input
              id="search-input"
              type="text"
              placeholder="What are you looking for?"
              onChange={handleInputChange}
              className="w-full pl-10 rounded-xl py-3 text-md placeholder-gray-500 focus:outline-none"
            />
          </div>
          <button
            className="w-[20%] lg:w-[20%] sm:w-full bg-lime-600 px-4 py-3 text-white font-medium rounded-full 
          transition-colors duration-300 ease-in-out hover:bg-lime-600 focus:bg-lime-600 ml-2"
            onClick={handleSearch}
          >
            Find
          </button>
        </div>
        <div className="flex justify-between mx-auto mt-8 items-center">
          <p className=" font-bold text-gray-600 text-md">
            Search:{" "}
            <span className=" text-lime-600 font-normal text-lg ">
              {searchQuery}
            </span>{" "}
          </p>
          <p className=" text-gray-600 font-medium text-sm">
            Search results: ({numResults})
          </p>
        </div>
      </div>

      <div>{renderResults()}</div>
    </div>
  );
};

export default ResultsPage;
