import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  HiOutlineLocationMarker,
  HiX,
  HiChevronDoubleLeft,
  HiChevronDoubleRight,
  HiOutlineMail,
  HiOutlineGlobe,
} from "react-icons/hi";

import Image from "../components/Image";
import { BiImage } from "react-icons/bi";
import { BsWhatsapp } from "react-icons/bs";

export default function ResultDetails() {
  const { id } = useParams();
  const [result, setResult] = useState(null);
  const [showAllPhotos, setShowAllPhotos] = useState(false);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const propertyResponse = await axios.get("/listing");
        const serviceResponse = await axios.get("/service-listing");
        const restaurantsResponse = await axios.get("/restaurants-listing");
        const resultsResponse = await axios.get("/retailers-listing");

        const allResults = [
          ...propertyResponse.data,
          ...serviceResponse.data,
          ...restaurantsResponse.data,
          ...resultsResponse.data,
        ];

        const resultDetails = allResults.find((result) => result._id === id);
        setResult(resultDetails);
      } catch (error) {
        console.log("Error fetching result:", error);
      }
    };

    fetchData();
  }, [id]);

  if (!result) return "";

  if (showAllPhotos) {
    const handleNextPhoto = () => {
      setCurrentPhotoIndex((prevIndex) =>
        prevIndex === result.photos.length - 1 ? 0 : prevIndex + 1
      );
    };

    const handlePreviousPhoto = () => {
      setCurrentPhotoIndex((prevIndex) =>
        prevIndex === 0 ? result.photos.length - 1 : prevIndex - 1
      );
    };

    return (
      <div className="photo-modal mb-16 mt-16">
        <div className="modal-content">
          <h2 className="text-3xl mb-4">Photos of {result.title}</h2>
          <div className="close-button mt-16">
            <button
              onClick={() => setShowAllPhotos(false)}
              className="flex w-auto bg-lime-600 text-white items-center py-2 px-4 rounded-full"
            >
              <HiX />
              Close photos
            </button>
          </div>
          <div className="photo-grid h-[550px] relative">
            {result?.photos?.length > 0 && (
              <>
                <button
                  className="absolute text-white left-0 top-1/2 transform -translate-y-1/2 bg-lime-500 px-2 w-8 py-1 rounded-full"
                  onClick={handlePreviousPhoto}
                >
                  <HiChevronDoubleLeft />
                </button>
                <div className="image-container">
                  <img
                    className="mx-auto max-h-[750px] h-full object-contain"
                    src={result.photos[currentPhotoIndex]}
                    alt=""
                  />
                </div>
                <button
                  className="absolute text-white right-0 top-1/2 transform -translate-y-1/2 bg-lime-500 w-8 px-2 py-1 rounded-full"
                  onClick={handleNextPhoto}
                >
                  <HiChevronDoubleRight />
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <section className="mt-16  max-w-7xl mx-auto lg:max-w-6xl md:max-w-5xl  mb-28">
      <div className="">
        {/* Text Details */}
        <div className="flex mx-auto px-4  xl:flex lg:flex  md:grid md:grid-cols-1 sm:grid sm:grid-cols-1 max-w-6xl items-center gap-4 mb-2">
          {/* Title */}
          <div className="flex items-center  max-w-3xl">
            <h1 className="text-2xl font-bold">{result.title}</h1>
          </div>

          {/* Address and Location */}
          <div className="flex gap-1   ">
            <HiOutlineLocationMarker className="text-gray-600" />
            <p className=" text-black text-sm font-medium">{result.address}</p>
            <h3 className="text-sm text-lime-600 font-medium ">
              {result.location}
            </h3>
          </div>
        </div>
        <div className="flex justify-between mx-auto  gap-6 max-w-6xl">
          <div className=" grid-flow-col ">
            <div className="relative border mx-auto flex-grow sm:flex-grow md:flex-grow-0 lg:flex-grow xl:flex-grow-0  p-2 rounded-2xl border-gray-100">
              <div className="grid grid-cols-3 gap-2 text-gray-600">
                <div className="col-span-3 text-gray-600">
                  {result.photos && result.photos.length > 0 ? (
                    <div className="grid grid-cols-3 gap-2 mt-2 text-gray-500">
                      {result.photos.slice(1, 7).map((photo, index) => (
                        <div key={index}>
                          {photo && (
                            <Image
                              className="w-full h-56 lg:h-56 md:h-56 sm:h-28  object-cover rounded-lg transform transition duration-200 ease-in-out hover:scale-105"
                              src={photo}
                              alt=""
                            />
                          )}
                          <button
                            onClick={() => setShowAllPhotos(true)}
                            className="flex gap-1 absolute font-bold text-xs bg-lime-600 px-4 text-white rounded-2xl py-2 bottom-2 w-auto right-2"
                          >
                            <BiImage className="w-4 h-4" />
                            Show more
                          </button>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="flex items-center justify-center h-24">
                      <p className="text-gray-400 text-center">No Images</p>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <h2 className="mb-1 mt-4 font-bold  text-md">Description</h2>
            <p className=" mb-6 text-gray-600 text-sm max-w-3xl">
              {result.description}
            </p>

            <div className=" flex flex-col gap-2">
              <div>
                <p className="text-sm font-bold text-amber-600 ">
                  {result.cuisines}
                </p>
              </div>
              <div>
                <p className="text-sm font-bold text-amber-600 ">
                  {result.meals}
                </p>
              </div>
            </div>
          </div>

          <div className="sm:hidden  md:hidden lg:block xl:block flex-grow">
            <div className=" max-w-md border p-3 rounded-2xl border-gray-100">
              <div>
                <div className=" mb-3">
                  <div className="relative">
                    <div className=" inset-0 rounded-2xl overflow-hidden">
                      {result.photos?.[0] && (
                        <Image
                          className="img w-full h-52  object-cover transform transition duration-200 ease-in-out hover:scale-120"
                          src={result.photos?.[0]}
                          alt=""
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="inline-block  bg-red-100 font-bold text-red-700 rounded-full text-[12px] px-4 py-1">
                  {result.category}
                </div>
                <div className=" py-3 flex justify-between items-center">
                  <div>
                    <a
                      href={result.website}
                      className="flex items-center gap-1 text-sky-600 font-bold text-lg hover:text-lime-600 hover:underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <HiOutlineGlobe className="text-lime-600 " />{" "}
                      <div>
                        <p className="text-lime-600 text-sm font-semibold ">
                          Website
                        </p>
                      </div>
                    </a>{" "}
                  </div>
                </div>{" "}
                <p className="text-sm px-2 mb-3 text-gray-500">
                  Connect with the result by email, calling or messaging on
                  WhatsApp.
                </p>
                <div className="flex flex-col gap-y-4">
                  <button
                    className="bg-blue-50 p-1 w-24 justify-center text-sm rounded-full flex items-center gap-2"
                    onClick={(e) => {
                      e.preventDefault();

                      const name = document.getElementById("name-input").value;
                      const message =
                        document.getElementById("message-input").value;

                      const subject = `I am Interested in this ${result.title}`;
                      let body = message;

                      if (message) {
                        body = `I am Interested in this ${result.title} my name is ${name}`;
                      }

                      if (name) {
                        body = `My name is ${name}.\n\n${body}`;
                      }

                      window.location.href = `mailto:${
                        result.email
                      }?subject=${subject}&body=${encodeURIComponent(body)}`;
                    }}
                  >
                    <HiOutlineMail className="text-lime-600 w-5 h-5" />
                    <p className="text-lime-600 font-medium">Email</p>
                  </button>

                  <div className="flex gap-x-2">
                    <button
                      className="  border-lime-600 bg-white text-center hover:border-lime-600 border-2 text-lime-600 font-bold rounded-full px-4 py-2 h-12  justify-center text-sm w-full transition"
                      onClick={(e) => {
                        e.preventDefault();
                        window.location.href = `tel:${result.call}`;
                      }}
                    >
                      Call
                    </button>

                    <button
                      className="bg-green-100 rounded-full w-[50%] flex text-center justify-center p-2"
                      onClick={(e) => {
                        e.preventDefault();
                        const name = document.querySelector("#name")?.value;
                        const message =
                          document.getElementById("message")?.value;

                        const url = `https://wa.me/${result.call}?text=Hello, I am interested in this result ${result.title}. ${message}`;
                        window.open(url, "_blank");
                      }}
                    >
                      <BsWhatsapp className="justify-center text-green-600 w-7 h-7 mt-0.5" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Contact Form (Responsive) */}
        <div className="lg:hidden lm:mt-4 md:hidden ms:mt-4  sm:mt-4 ">
          <div className=" max-w-md border p-3 rounded-2xl border-gray-100">
            <div>
              <div className=" mb-3">
                <div className="relative">
                  <div className=" inset-0 rounded-2xl overflow-hidden">
                    {result.photos?.[0] && (
                      <Image
                        className="img w-full h-52  object-cover transform transition duration-200 ease-in-out hover:scale-120"
                        src={result.photos?.[0]}
                        alt=""
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="inline-block  bg-red-100 font-bold text-red-700 rounded-full text-[12px] px-4 py-1">
                {result.category}
              </div>
              <div className=" py-3 flex justify-between items-center">
                <div>
                  <a
                    href={result.website}
                    className="flex items-center gap-1 text-sky-600 font-bold text-lg hover:text-lime-600 hover:underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <HiOutlineGlobe className="text-lime-600 " />{" "}
                    <div>
                      <p className="text-lime-600 text-sm font-semibold ">
                        Website
                      </p>
                    </div>
                  </a>{" "}
                </div>
              </div>{" "}
              <p className="text-sm px-2 mb-3 text-gray-500">
                Connect with the result by email, calling or messaging on
                WhatsApp.
              </p>
              <div className="flex flex-col gap-y-4">
                <button
                  className="bg-blue-50 p-1 w-24 justify-center text-sm rounded-full flex items-center gap-2"
                  onClick={(e) => {
                    e.preventDefault();

                    const name = document.getElementById("name-input").value;
                    const message =
                      document.getElementById("message-input").value;

                    const subject = `I am Interested in this ${result.ti}`;
                    let body = message;

                    if (message) {
                      body = `I am Interested in this ${result.title} my name is ${name}`;
                    }

                    if (name) {
                      body = `My name is ${name}.\n\n${body}`;
                    }

                    window.location.href = `mailto:${
                      result.email
                    }?subject=${subject}&body=${encodeURIComponent(body)}`;
                  }}
                >
                  <HiOutlineMail className="text-lime-600 w-5 h-5" />
                  <p className="text-lime-600 font-medium">Email</p>
                </button>

                <div className="flex gap-x-2">
                  <button
                    className="  border-lime-600 bg-white text-center hover:border-lime-600 border-2 text-lime-600 font-bold rounded-full px-4 py-2 h-12  justify-center text-sm w-full transition"
                    onClick={(e) => {
                      e.preventDefault();
                      window.location.href = `tel:${result.call}`;
                    }}
                  >
                    Call
                  </button>

                  <button
                    className="bg-green-100 rounded-full w-[50%] flex text-center justify-center p-2"
                    onClick={(e) => {
                      e.preventDefault();
                      const name = document.querySelector("#name")?.value;
                      const message = document.getElementById("message")?.value;

                      const url = `https://wa.me/${result.call}?text=Hello, I am interested in this result ${result.title}. ${message}`;
                      window.open(url, "_blank");
                    }}
                  >
                    <BsWhatsapp className="justify-center text-green-600 w-7 h-7 mt-0.5" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
