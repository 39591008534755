import React, { useRef, useEffect } from "react";
import Image from "../assets/img/house-banner.jpg";
import Search from "../components/Search";
import HomeSearch from "./HomeSearch";
import Categories from "./Categories";

const Banner = () => {
  const ref = useRef();

  useEffect(() => {
    const handleScroll = () => {
      const posY = window.pageYOffset / 2;
      ref.current.style.backgroundPositionY = `${posY}px`;

      const scrollPos = window.pageYOffset;
      const height = window.innerHeight;
      const bannerHeight = ref.current.offsetHeight;
      const content = ref.current.querySelector(".content");

      if (scrollPos < bannerHeight) {
        const progress = (scrollPos / bannerHeight) * 100;
        const opacity = 1 - progress / 100;
        const scale = 1 - progress / 300;
        const translateY = (1 - scale) * 50;
        content.style.opacity = opacity;
        content.style.transform = `scale(${scale}) translateY(${translateY}px)`;
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section>
      <div className="flex flex-col lg:flex-row">
        {/* text */}
        <div className="  flex flex-col items-center lg:items-start text-center lg:text-left justify-center flex-1 px-4 lg:px-0 content">
          <div className=" max-w-3xl justify-center mx-auto mb-14 mt-8">
            <h1 className="text-4xl lg:text-5xl md:pt-4 text-center text-amber-600 font-bold  mb-3">
              What are you looking for?
            </h1>
          </div>
          <div>
            {/* <p className="max-w-[500px] mb-16  text-gray-700 text-lg ">
              Find Your Perfect Match for Properties, Products, Services and
              More
            </p> */}
          </div>
        </div>
      </div>
      <div className=" py-6">
        <Categories />
      </div>

      <HomeSearch />
    </section>
  );
};

export default Banner;
