import { Link, useLocation } from "react-router-dom";
import {
  HiUserCircle,
  HiViewList,
  HiHeart,
  HiOutlineHome,
  HiHome,
  HiHand,
  HiShoppingCart,
} from "react-icons/hi";
import { useContext } from "react";
import { UserContext } from "./UserContext";
import { BiFoodMenu, BiFoodTag } from "react-icons/bi";

export default function AccountNav() {
  const { pathname } = useLocation();
  let subpage = pathname.split("/")?.[2];
  if (subpage === undefined) {
    subpage = "profile";
  }
  function linkClasses(type = null) {
    let classes = "py-2 px-6  rounded-xl";
    if (type === subpage) {
      classes += "py-2 px-6 text-lime-600 rounded-full";
    }
    return classes;
  }

  const { user } = useContext(UserContext);

  return (
    <nav className=" max-w-6xl  mx-auto rounded-xl bg-slate-50 flex gap-4 mt-1 mb-4 items-center justify-center py-4 text-sm font-medium text-gray-500">
      <Link className={linkClasses("profile")} to={"/account"}>
        <HiUserCircle className="inline-block w-4 h-4 mr-1" />
        My account
      </Link>
      {user?.role === "admin" && (
        <Link className={linkClasses("listings")} to={"/account/listings"}>
          <HiHome className="inline-block w-4 h-4 mr-1" />
          Admin Property listings
        </Link>
      )}
      {user?.role === "admin" && (
        <Link className={linkClasses("service")} to={"/account/service"}>
          <HiHand className="inline-block w-4 h-4 mr-1" />
          Admin Service listings
        </Link>
      )}
      {user?.role === "admin" && (
        <Link
          className={linkClasses("restaurants")}
          to={"/account/restaurants"}
        >
          <BiFoodMenu className="inline-block w-4 h-4 mr-1" />
          Admin Restaurant listings
        </Link>
      )}
      {user?.role === "admin" && (
        <Link className={linkClasses("retailers")} to={"/account/retailers"}>
          <HiShoppingCart className="inline-block w-4 h-4 mr-1" />
          Admin retailers listings
        </Link>
      )}
      {/* <Link className={linkClasses("favourites")} to={"/account/favourites"}>
        <HiHeart className="inline-block w-4 h-4 mr-1" />
        My favourites
      </Link> */}
    </nav>
  );
}
