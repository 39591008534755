export default function TermsPage() {
  return (
    <section className="py-10">
      <div className="bg-gradient-to-b from-lime-600 to-lime-600 p-24">
        <h1 className="text-center font-bold text-white text-3xl">
          Terms of Service
        </h1>
      </div>
      <div className="">
        <div
          className=" relative inset-0 flex items-center justify-center"
          aria-hidden="true"
        >
          <div className="max-w-5xl  mx-auto bg-white pr-8 pl-8 py-10 rounded-xl ">
            <h2 className="text-md text-gray-800 font-bold">
              About this Application
            </h2>
            <p className="text-sm mb-4">
              These Terms of Service ("Terms") govern your use of the Hoji Inc
              website and services. By signing up and using our platform, you
              agree to comply with these Terms. Please read them carefully.
            </p>

            <h2 className="text-md text-gray-800 font-bold">
              Acceptance of Terms:
            </h2>
            <p className="text-sm mb-4">
              By creating an account and using Hoji Inc, you agree to these
              Terms and any future amendments or modifications. If you do not
              agree with these Terms, please refrain from using our platform.
            </p>

            <h2 className="text-md text-gray-800 font-bold">
              User Responsibilities:{" "}
            </h2>
            <p className="text-sm mb-4">
              As a user of Hoji Inc, you are responsible for maintaining the
              confidentiality of your account login credentials. You agree to
              provide accurate and up-to-date information during the
              registration process. You must not use Hoji Inc for any illegal or
              unauthorized activities, and you shall comply with all applicable
              laws and regulations.
            </p>

            <h2 className="text-md text-gray-800 font-bold">User Content:</h2>
            <p className="text-sm mb-4">
              By submitting information, ratings, comments, or any other content
              on Hoji Inc, you grant us a non-exclusive, worldwide, royalty-free
              license to use, reproduce, modify, and display such content for
              the purpose of operating and improving our services.
            </p>

            <h2 className="text-md text-gray-800 font-bold">
              Ratings and Comments:
            </h2>
            <p className="text-sm mb-4">
              Users may rate companies and provide comments based on their
              experiences. You acknowledge that the ratings and comments reflect
              personal opinions and experiences, and Hoji Inc does not endorse
              or verify the accuracy of such information. We reserve the right
              to moderate, edit, or remove any content that violates our
              guidelines or terms.
            </p>

            <h2 className="text-md text-gray-800 font-bold">
              Intellectual Property:
            </h2>
            <p className="text-sm mb-4">
              Hoji Inc and its associated logos, trademarks, and content are the
              property of Hoji Incorporated (Pvt) Ltd. You agree not to
              reproduce, modify, or distribute any copyrighted materials without
              our prior written consent.
            </p>

            <h2 className="text-md text-gray-800 font-bold">
              Limitation of Liability:
            </h2>
            <p className="text-sm mb-4">
              Hoji Inc strives to provide accurate and reliable information, but
              we do not guarantee the completeness, accuracy, or availability of
              our platform. We shall not be liable for any direct, indirect,
              incidental, or consequential damages arising from your use or
              inability to use Hoji Inc.
            </p>

            <h2 className="text-md text-gray-800 font-bold">
              Third-Party Links:
            </h2>
            <p className="text-sm mb-4">
              Hoji Inc may contain links to third-party websites or services
              that are not owned or controlled by us. We do not endorse or
              assume any responsibility for the content, privacy policies, or
              practices of these third-party websites. Your interactions with
              any third-party website are solely between you and the third
              party.
            </p>

            <h2 className="text-md text-gray-800 font-bold">Termination:</h2>
            <p className="text-sm mb-4">
              We reserve the right to terminate or suspend your account and
              access to Hoji Inc at any time and for any reason without prior
              notice. You may also terminate your account by contacting our
              support team.
            </p>

            <h2 className="text-md text-gray-800 font-bold">Governing Law:</h2>
            <p className="text-sm mb-4">
              These Terms shall be governed by and construed in accordance with
              the laws of Zimbabwe. Any legal action or proceeding arising out
              of or relating to these Terms shall be brought exclusively in the
              courts located in Harare, Zimbabwe.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
