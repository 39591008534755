import { useEffect } from "react";
import RestaurantCategories from "../components/RestaurantCategories";
import RestaurantFilter from "../components/RestaurantFilter";
import RestaurantsBanner from "../components/RestaurantsBanner";

import ReactGA from "react-ga";

export default function RestaurantsPage() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <section className=" bg-white">
      <div className=" mb-16">
        <RestaurantsBanner />
      </div>

      <div className=" pl-16 mb-4">
        <h3 className=" text-xl font-bold">Top listings</h3>
      </div>
      <div>
        <RestaurantFilter />
      </div>
    </section>
  );
}

//`url(${Image})`
