import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ImSpinner2 } from "react-icons/im";
import Image from "./Image";
import { BsLightningCharge } from "react-icons/bs";

const selectedIds = [
  "65a0f72982dca085521f297f",
  "6470b4283027b4f62e02868c",
  "6470743128e9006882722c5f",
  "6486dcbeb8ef806207a4b50e",
];

export default function FoodSlider() {
  const [restaurants, setRestaurants] = useState([]);

  useEffect(() => {
    axios.get("/restaurants-listing").then((res) => {
      const filteredRestaurants = res.data.filter((restaurant) =>
        selectedIds.includes(restaurant._id)
      );
      setRestaurants(filteredRestaurants);
    });
  }, []);

  return (
    <section className=" py-8 bg-orange-50">
      <div className=" max-w-6xl  p-8 mt-8 mx-auto">
        <div className=" max-w-lg mb-4 px-8">
          <h1 className=" text-4xl  font-bold">Incase you are hungry!</h1>
          <p className=" text-gray-500 font-light">
            Discover our curated selection of Restaurants.
          </p>
        </div>

        {restaurants.length > 0 ? (
          <div className="grid   lg:grid-cols-4 ms:grid-cols-2 sm:grid-cols-1 grid-cols-2 md:grid-cols-4 gap-4 flex-wrap sm:px-4 px-16 justify-center">
            {restaurants.map((restaurant) => (
              <Link to={"/restaurants/" + restaurant._id} key={restaurant._id}>
                <div className="max-w-xl mb-8  sm:flex-col cursor-pointer md:flex-col p-2 transition rounded-xl flex lg:flex-col">
                  <div className="">
                    <div className="rounded-xl sm:mb-4 lg:w-66 lg:h-56 lg:m-2 overflow-hidden">
                      <div className="absolute flex gap-1 items-center text-white font-normal text-xs bg-lime-100 m-2 py-1 px-4 rounded-xl bg-opacity-50">
                        <BsLightningCharge />
                        Sponsored
                      </div>

                      {restaurant.photos?.[1] && (
                        <Image
                          className="img sm:w-full w-66 h-56 object-cover"
                          src={restaurant.photos?.[1]}
                          alt=""
                        />
                      )}
                    </div>
                  </div>
                  <div className=" px-3 w-full sm:w-auto lg:w-full">
                    <div>
                      <div className="flex gap-8 text-left items-center justify-between">
                        <div className="">
                          <h2 className="font-bold lg:text-xl md:text-lg text-xl ">
                            {restaurant.title}
                          </h2>
                          <h2 className="text-sm font-medium text-gray-600 mb-2">
                            {restaurant.location}
                          </h2>
                          <h2 className="text-sm max-w-xs hidden text-lime-600 mb-4">
                            {restaurant.address}
                          </h2>
                        </div>
                      </div>
                      <div className="text-left">
                        <div className="inline-block border border-lime-700 font-bold text-lime-700 rounded-full text-[12px] px-4 py-1 mb-2">
                          {restaurant.category}
                        </div>
                        <h2 className="text-sm font-medium mt-2 hover hidden text-sky-500">
                          {restaurant.website}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        ) : (
          <ImSpinner2 className="animate-spin text-2xl justify-center text-lime-600 mx-auto text-2x1" />
        )}
      </div>
    </section>
  );
}
