import axios from "axios";
import { ImSpinner2 } from "react-icons/im";
import { Link } from "react-router-dom/dist";
import Image from "./Image";
import { useState } from "react";
import { useEffect } from "react";
import { HiSearch } from "react-icons/hi";
import { useContext } from "react";
import { UserContext } from "../UserContext";
import { BsGraphUp } from "react-icons/bs";

export default function ServiceFilter() {
  const [searchValue, setSearchValue] = useState("");
  const [locationSearchValue, setLocationSearchValue] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [services, setServices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState({});
  const [clickCounts, setClickCounts] = useState({});
  const { user } = useContext(UserContext);

  const incrementClickCount = async (itemId) => {
    try {
      await axios.post("/clicks", { itemId });

      setClickCounts((prevClickCounts) => ({
        ...prevClickCounts,
        [itemId]: (prevClickCounts[itemId] || 0) + 1,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };
  const handleSearch = () => {
    setSelectedLocation(locationSearchValue);
    fetchServices();
  };

  const fetchServices = async () => {
    setIsLoading(true);
    const categoryQueryParam = selectedCategory
      ? `category=${encodeURIComponent(selectedCategory)}`
      : "";
    const locationQueryParam = selectedLocation
      ? `location=${encodeURIComponent(selectedLocation)}`
      : "";
    const queryParams = [categoryQueryParam, locationQueryParam]
      .filter(Boolean)
      .join("&");
    const url = queryParams
      ? `/service-listing?${queryParams}`
      : "/service-listing";
    try {
      const response = await axios.get(url);
      const filteredServices = response.data.filter((service) => {
        // Filtering services based on search criteria
        const searchKeyword = searchValue.toLowerCase();
        const locationKeyword = selectedLocation.toLowerCase();

        return (
          (!selectedLocation ||
            service.location.toLowerCase().includes(locationKeyword) ||
            service.address.toLowerCase().includes(locationKeyword)) &&
          (service.title.toLowerCase().includes(searchKeyword) ||
            service.description.toLowerCase().includes(searchKeyword) ||
            service.location.toLowerCase().includes(searchKeyword))
        );
      });
      setServices(filteredServices);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchServices();
  }, [selectedCategory, selectedLocation]);

  const handleImageLoad = (retailerId) => {
    setImageLoading((prevLoading) => ({
      ...prevLoading,
      [retailerId]: false,
    }));
  };

  const handleImageError = (retailerId) => {
    setImageLoading((prevLoading) => ({
      ...prevLoading,
      [retailerId]: false,
    }));
    // Handle image loading error if needed
  };

  useEffect(() => {
    const fetchClickCounts = async () => {
      try {
        const response = await axios.get("/clicks");
        const { clickCounts } = response.data;
        setClickCounts(clickCounts);
      } catch (error) {
        console.error(error);
      }
    };

    fetchClickCounts();
  }, []);

  return (
    <div className="min-h-screen">
      <div className="flex-row items-center ">
        <div className="flex-col mx-auto max-w-max bg-white rounded-md pb-1">
          <div className="flex lg:flex items-center justify-center gap-1 sm:grid sm:grid-cols-1 py-2 px-4 ">
            <div className="relative">
              <HiSearch className="absolute text-gray-400  h-8 w-8 inset-y-1 left-0 flex items-center pl-3" />
              <input
                type="text"
                placeholder="Service title or keyword"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                className="w-full text-md px-2 placeholder-gray-500 border py-2 rounded-lg pl-10 bg-slate-100 focus:outline-none"
              />
            </div>

            <div className="flex items-center">
              <div className="relative">
                <HiSearch className="absolute text-gray-400  h-8 w-8 inset-y-1 left-0 flex items-center pl-3" />
                <input
                  type="text"
                  placeholder="Search location"
                  value={locationSearchValue}
                  onChange={(e) => setLocationSearchValue(e.target.value)}
                  className="w-full text-md placeholder-gray-500 border py-2 rounded-lg pl-10 bg-slate-100 focus:outline-none"
                />
              </div>
              <button
                className="px-4 ml-2 h-10 w-36 lg:px-2 md:px-2 py-2 text-md font-medium text-white bg-lime-600 rounded-md hover:bg-lime-600 focus:outline-none focus:bg-lime-600"
                onClick={handleSearch}
              >
                Search
              </button>
            </div>
          </div>
          <div className=" ml-4 relative max-w-[195px] mb-4">
            <select
              className=" border-b-2 p-2 "
              value={selectedCategory || ""}
              onChange={handleCategoryChange}
            >
              <option value="">Select a category</option>
              <option value="Air conditioning">Air conditioning</option>
              <option value="Appliances">Appliances</option>
              <option value="Bathroom">Bathroom</option>
              <option value="Bedroom">Bedroom</option>
              <option value="Borehole">Borehole</option>
              <option value="Cabinets">Cabinets</option>
              <option value="Car services">Car services</option>
              <option value="Carpentry">Carpentry</option>
              <option value="Catering">Catering</option>
              <option value="Chefs braai">Chefs braai</option>
              <option value="Cleaning">Cleaning</option>
              <option value="Construction">Construction</option>
              <option value="Curtains/cushions">Curtains/cushions</option>
              <option value="Dining room">Dining room</option>
              <option value="Doors">Doors</option>
              <option value="Electrician">Electrician</option>
              <option value="Events decor">Events decor</option>
              <option value="Fencing">Fencing</option>
              <option value="Flooring">Flooring</option>
              <option value="Fuel delivery">Fuel delivery</option>
              <option value="Furniture">Furniture</option>
              <option value="Garage">Garage</option>
              <option value="Gardening">Gardening</option>
              <option value="Gas delivery">Gas delivery</option>
              <option value="Gym">Gym</option>
              <option value="Gates">Gates</option>
              <option value="Home gym">Home gym</option>
              <option value="Home office">Home office</option>
              <option value="Insurance">Insurance</option>
              <option value="Kitchen">Kitchen</option>
              <option value="Kitchen utensils">Kitchen utensils</option>
              <option value="Lighting">Lighting</option>
              <option value="Linen">Linen</option>
              <option value="Living room">Living room</option>
              <option value="Mowing lawn">Mowing lawn</option>
              <option value="Motor bike delivery">Motor bike delivery</option>
              <option value="Moving truck">Moving truck</option>
              <option value="Other">Other</option>
              <option value="Painting">Painting</option>
              <option value="Patio">Patio</option>
              <option value="Plumbing">Plumbing</option>
              <option value="Pool">Pool</option>
              <option value="Pool cleaning">Pool cleaning</option>
              <option value="Renovations">Renovations</option>
              <option value="Roofing">Roofing</option>
              <option value="Security">Security</option>
              <option value="Security cameras">Security cameras</option>
              <option value="Solar">Solar</option>
              <option value="Tiling">Tiling</option>
              <option value="Trash pickup">Trash pickup</option>
              <option value="Upholstery">Upholstery</option>
              <option value="Water delivery">Water delivery</option>
              <option value="Windows">Windows</option>
              <option value="Wifi">Wifi</option>
            </select>
            {/* <div className="pointer-events-none absolute inset-y-0 right-6 flex items-center px-2 text-gray-700">
              <HiChevronDown />
            </div> */}
          </div>
        </div>
      </div>

      <div className="grid sm:grid-cols-1 max-w-6xl mb-16 mx-auto ms:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6">
        {isLoading ? (
          <ImSpinner2
            className="animate-spin text-2xl justify-center text-lime-600 mx-auto
       text-2x1"
          />
        ) : services.length > 0 ? (
          services.map((service) => (
            <Link
              to={"/service/" + service._id}
              key={service._id}
              onClick={() => incrementClickCount(service._id)}
            >
              <div className="w-full max-w-[352px] max z-auto cursor-pointer hover:shadow-2xl p-4 transition rounded-lg bg-white">
                <div className="relative aspect-square mb-4">
                  <div className="absolute inset-0 rounded-2xl overflow-hidden">
                    {service.photos?.[0] && (
                      <Image
                        className="img w-full h-full object-cover transform transition duration-200 ease-in-out hover:scale-120"
                        src={service.photos?.[0]}
                        alt=""
                        onLoad={() => handleImageLoad(service._id)}
                        onError={() => handleImageError(service._id)}
                      />
                    )}
                    {imageLoading[service._id] && (
                      <div className="absolute inset-0 flex items-center justify-center">
                        <ImSpinner2 className="animate-spin text-2xl text-lime-600" />
                      </div>
                    )}
                  </div>
                </div>

                <div className="flex justify-between">
                  <h2 className="font-bold ">{service.title}</h2>
                </div>
                <h2 className=" font-medium text-lime-600">
                  {service.location}
                </h2>
                <h2 className=" truncate text-sm mb-1 text-gray-600">
                  {service.description}
                </h2>
                <div className=" flex items-center justify-between">
                  <div className="inline-block bg-gray-100 font-bold text-gray-700 rounded-full text-[12px] px-4 py-1 mb-2">
                    {service.category}
                  </div>

                  {user && user.role === "admin" && (
                    <div className="flex items-center gap-2">
                      <BsGraphUp className="text-sm font-medium text-green-600" />
                      <span className="text-sm font-medium text-gray-500">
                        {clickCounts[service._id] || 0}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </Link>
          ))
        ) : (
          <div className="mx-auto max-w-xs justify-center">
            <p className="  justify-center text-gray-300 font-medium">
              No services found.
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
