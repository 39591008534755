import React from "react";
import { FaAndroid } from "react-icons/fa";
import { Link } from "react-router-dom";

function MobileApp2() {
  return (
    <section class=" 2xl:py-18 2xl:bg-white">
      <div class="px-4 mx-auto rounded-2xl overflow-hidden bg-gradient-to-r from-amber-600 to-lime-600 max-w-6xl sm:px-6 lg:px-12">
        <div
          style={{
            backgroundImage: `url(https://assets.website-files.com/5837424ae11409586f837994/61195e21f792d7065d2f56ad_noise.png)`,
          }}
          class="py-10 sm:py-16 lg:py-16 2xl:pl-24"
        >
          <div class="grid items-center grid-cols-1 gap-y-12 lg:grid-cols-2 lg:gap-x-8 2xl:gap-x-20">
            <div className=" z-10">
              <h2 class="text-3xl font-bold leading-tight text-white sm:text-4xl lg:text-5xl lg:leading-tight">
                Download Our Brand New App Now!
              </h2>
              <p class="mt-4 text-base text-gray-50">
                ✨Explore businesses in Zimbabwe at lightning speed with our
                cutting-edge Android app! Don't miss out on the ultimate
                convenience and efficiency – download it today!
              </p>

              <div class="flex flex-row w-[300px] items-center mt-8  lg:mt-12">
                <button
                  className="text-lg font-bold border-2 text-amber-700 rounded-full py-2 px-8 bg-white flex items-center"
                  onClick={() => {
                    const link = document.createElement("a");
                    link.href =
                      "https://dl.dropboxusercontent.com/scl/fi/irtyml4rqsqkx2ggpeqpc/hojiinc-v1.1.0.apk?rlkey=exmd0sy6kbrqczytn5on6tnax&dl=0";
                    link.download = "hojiinc-v1.1.0.apk";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  }}
                >
                  <span>Download for Android</span>
                  <FaAndroid className="ml-2" />
                </button>
              </div>
            </div>

            <div class="relative px-4">
              <svg
                class="absolute z-0 inset-x-0 bottom-0 left-1/2 -translate-x-1/2 -mb-48 lg:-mb-72 text-yellow-300 w-[460px] h-[460px] sm:w-[600px] sm:h-[600px]"
                fill="currentColor"
                viewBox="0 0 8 8"
              >
                <circle cx="4" cy="4" r="3" />
              </svg>
              <img
                class="relative w-full max-w-xs mx-auto -mb-60 lg:-mb-64"
                src="https://i.ibb.co/QdCtTyF/android.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MobileApp2;
