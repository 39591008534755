import { HiChevronDown } from "react-icons/hi";
import AccountNav from "../AccountNav";
import { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import axios from "axios";
import PhotosUploader from "../components/PhotosUploader";

export default function NewServiceForm() {
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [call, setCall] = useState("");
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");
  const [address, setAddress] = useState("");
  const [website, setWebsite] = useState("");
  const [price, setPrice] = useState("");
  const [email, setEmail] = useState("");
  const [addedPhotos, setaddedPhotos] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const currentUserID = localStorage.getItem("userId");

  useEffect(() => {
    if (!id) {
      return;
    }
    axios.get("/service-listings/" + id).then((res) => {
      const { data } = res;
      setTitle(data.title);
      setCategory(data.category);
      setDescription(data.description);
      setPrice(data.price);
      setAddress(data.address);
      setaddedPhotos(data.photos);
      setCall(data.call);
      setEmail(data.email);
      setLocation(data.location);
      setWebsite(data.website);
    });
  }, [id]);

  function inputHeader(text) {
    return <h2 className="text-2xl mt-4">{text}</h2>;
  }

  function inputDescription(text) {
    return <p className="text-sm text-gray-500">{text}</p>;
  }

  function preInput(Header, description) {
    return (
      <>
        {inputHeader(Header)}
        {inputDescription(description)}
      </>
    );
  }

  async function saveService(ev) {
    ev.preventDefault();
    const serviceData = {
      title,
      category,
      call,
      description,
      location,
      address,
      website,
      price,
      email,
      addedPhotos,
      creator: currentUserID,
    };
    try {
      if (id) {
        //update
        await axios.put("/service-listings", {
          id,
          ...serviceData,
        });
        setRedirect(true);
      } else {
        //create
        await axios.post("/services", serviceData);
        setRedirect(true);
      }
    } catch (err) {
      console.error(err);
      setRedirect(true);
    }
  }

  if (redirect) {
    return <Navigate to="/account/service" />;
  }

  return (
    <div className="container mx-auto mb-8">
      <AccountNav />
      <form onSubmit={saveService}>
        <div className="">
          {preInput("Category", "What type of service is it?")}
          <div className="relative w-full mb-4">
            <select
              className="form-input"
              value={category}
              onChange={(ev) => setCategory(ev.target.value)}
            >
              <option value="">Select a category</option>
              <option value="Construction">Construction</option>
              <option value="Solar ">Solar</option>
              <option value="Air conditioning">Air conditioning</option>
              <option value="Renovations ">Renovations </option>
              <option value="Wifi ">Wifi </option>
              <option value="Gardening">Gardening</option>
              <option value="Painting">Painting</option>
              <option value="Borehole">Borehole</option>
              <option value="Movers">Movers</option>
              <option value="Interior Design">Interior Design</option>
              <option value="Cleaning">Cleaning</option>
              <option value="Car services  ">Car services </option>
              <option value="Plumbing">Plumbing</option>
              <option value="Carpentry">Carpentry</option>
              <option value="Water delivery ">Water delivery </option>
              <option value="Trash pickup">Trash pickup</option>
              <option value="Motor bike delivery ">Motor bike delivery </option>
              <option value="Fuel delivery">Fuel delivery</option>
              <option value="Electrician">Electrician</option>
              <option value="Linen">Linen</option>
              <option value="Moving truck">Moving truck</option>
              <option value="Catering ">Catering </option>
              <option value="Gas delivery ">Gas delivery </option>
              <option value="Pool cleaning ">Pool cleaning </option>
              <option value="Insurance">Insurance </option>
              <option value="Security">Security </option>
              <option value="Furniture">Furniture </option>
              <option value="IT solutions">IT solutions</option>
              <option value="Media">Media</option>
              <option value="Home office">Home office</option>
              <option value="Branding">Branding</option>
              <option value="Cosmetics">Cosmetics</option>
              <option value="Car rental">Car rental</option>
              <option value="Wedding Planners">Wedding Planners</option>
              <option value="Printing">Printing</option>
              <option value="House Help">House Help</option>
              <option value="Travel Tours">Travel Tours</option>
              <option value="Other">Other</option>
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <HiChevronDown />
            </div>
          </div>

          {preInput("Title", "Give your service a name")}
          <input
            className="form-input"
            type="text"
            value={title}
            onChange={(ev) => setTitle(ev.target.value)}
          />
          {preInput("Description", "Describe your service")}
          <textarea
            className="form-input"
            value={description}
            onChange={(ev) => setDescription(ev.target.value)}
          />
          {preInput("Location", "Where is your service located?")}
          <input
            className="form-input"
            type="text"
            value={location}
            onChange={(ev) => setLocation(ev.target.value)}
          />
          {preInput("Address", "What is the address of your service?")}
          <input
            className="form-input"
            type="text"
            value={address}
            onChange={(ev) => setAddress(ev.target.value)}
          />
          {preInput("Website", "What is the website of your service?")}
          <input
            className="form-input"
            type="text"
            value={website}
            onChange={(ev) => setWebsite(ev.target.value)}
          />
          {preInput("Price", "What is the price of your service?")}
          <input
            className="form-input"
            type="text"
            value={price}
            onChange={(ev) => setPrice(ev.target.value)}
          />
          {preInput(
            "Service Call",
            "What is the phone number of your service?"
          )}
          <input
            className="form-input"
            type="text"
            value={call}
            onChange={(ev) => setCall(ev.target.value)}
          />
          {preInput("Email", "What is the email of your service?")}
          <input
            className="form-input"
            type="text"
            value={email}
            onChange={(ev) => setEmail(ev.target.value)}
          />
          {preInput("Photos", "Add photos of your service")}
          <PhotosUploader addedPhotos={addedPhotos} onChange={setaddedPhotos} />

          <button
            className="bg-lime-600 mt-8 text-white p-2 rounded-lg"
            type="submit"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
}
