import React, { useEffect } from "react";
import Banner from "../components/Banner";
import MobileApp from "../components/MobileApp";
import Faq from "../components/Faq";
import ReactGA from "react-ga";
import AdsBanner from "../components/AdsBanner";
import MobileApp2 from "../components/MobileApp2";
import RetailersSlider from "../components/RetailersSlider";
import ServicesSlider from "../components/ServicesSlider";
import BigAd from "../components/BigAd";
import FoodSlider from "../components/FoodSlider";

const Home = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <div className="h-auto bg-white mb-16">
      <Banner />
      <div className=" mb-8 mx-auto mt-16">
        <MobileApp2 />
      </div>
      <div className="">
        <RetailersSlider />
      </div>

      <div className="">
        <ServicesSlider />
      </div>

      <BigAd />

      <div className=" mb-8 auto mt-16">
        <AdsBanner />
      </div>
      <FoodSlider />

      <div className=" ml-16 mt-6 mb-6 flex-wrap"></div>

      {/* <ServicesCTA /> */}
      {/* <div className=" max-auto sm:items-start">
        <MobileApp />
      </div> */}
      <div>
        <Faq />
      </div>
    </div>
  );
};

export default Home;
