import { Link } from "react-router-dom";
import { HiOutlinePencil, HiOutlineTrash, HiPlus } from "react-icons/hi";
import AccountNav from "../AccountNav";
import { useEffect, useState } from "react";
import axios from "axios";
import Image from "../components/Image";

export default function PropertiesPage() {
  const [listings, setListings] = useState([]);

  useEffect(() => {
    axios.get("/user-listings").then(({ data }) => {
      setListings(data);
    });
  }, []);

  const handleDeleteListing = (listingId) => {
    axios.delete(`/listings/${listingId}`).then(() => {
      setListings((prevListings) =>
        prevListings.filter((listing) => listing._id !== listingId)
      );
    });
  };

  return (
    <div className=" min-h-screen">
      <AccountNav />
      <div className="text-center">
        <Link
          className="bg-lime-600 text-white inline-flex py-2 gap-1 items-center px-4 rounded-full"
          to={"/account/listings/new"}
        >
          <HiPlus />
          Add new property
        </Link>
      </div>
      <div className=" max-w-5xl mx-auto mt-4">
        {listings.length > 0 &&
          listings.map((list) => (
            <div
              key={list._id}
              className="flex gap-4 mb-6 bg-white border border-gray-100 p-4 rounded-2xl"
            >
              <div className="">
                {list.photos.length > 0 && (
                  <div className="flex w-32 h-32 bg-gray-300">
                    <Image
                      className="object-cover"
                      src={list.photos[0]}
                      alt=""
                    />
                  </div>
                )}
              </div>

              <div className="flex-grow">
                <h2 className="text-xl">{list.title}</h2>
                <p className="text-sm mt-2">{list.description}</p>
                <div className="inline-block mt-4 bg-gray-100 font-bold text-gray-700 rounded-full text-[12px] px-8 py-1">
                  {list.category}
                </div>
              </div>

              <div className=" py-2">
                <Link
                  key={list._id}
                  to={"/account/listings/" + list._id}
                  className="text-gray-600 mb-16 flex items-center gap-2 font-normal px-4 bg-gray-100 rounded-md"
                >
                  <HiOutlinePencil />
                  Edit
                </Link>
                <button
                  className="text-red-600 flex items-center gap-2 font-normal px-4 bg-red-100 rounded-md"
                  onClick={() => handleDeleteListing(list._id)}
                >
                  <HiOutlineTrash />
                  Delete
                </button>
              </div>
            </div>
          ))}
        {listings.length === 0 && (
          <p className="text-center mt-4">No properties added yet.</p>
        )}
      </div>
    </div>
  );
}
