import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { FaHome, FaHammer, FaHamburger, FaShoppingBag } from "react-icons/fa";

const categoryVariants = {
  hover: {
    scale: 1.05,
    transition: { duration: 0.3 },
  },
  tap: {
    scale: 0.9,
    transition: { duration: 0.3 },
  },
};

const categories = [
  {
    name: "Properties",
    color: "orange",
    icon: FaHome,
    link: "/property",
  },
  {
    name: "Companies",
    color: "orange",
    icon: FaHammer,
    link: "/services",
  },
  {
    name: "Restaurants",
    color: "orange",
    icon: FaHamburger,
    link: "/restaurants",
  },
  {
    name: "Shops",
    color: "orange",
    icon: FaShoppingBag,
    link: "/retailers",
  },
];

function Category({ name, color, icon: Icon, link }) {
  const iconColor = `text-${color}-600`;

  return (
    <Link to={link}>
      <motion.div
        className={`flex flex-row bg-gray-50 py-2 rounded-full items-center justify-center `}
        variants={categoryVariants}
        whileHover="hover"
        whileTap="tap"
      >
        <div className=" mr-2">
          <Icon className={iconColor} />
        </div>
        <h2 className="text-lg text-amber-600 font-medium  text-center">
          {name}
        </h2>
      </motion.div>
    </Link>
  );
}

export default function Categories() {
  return (
    <div className="grid max-w-xs md:max-w-4xl mx-auto lg:grid-cols-4 ms:grid-cols-2 sm:grid-cols-1 grid-cols-2 md:grid-cols-4 gap-4 flex-wrap sm:px-4 px-16 justify-center">
      {categories.map((category, index) => (
        <Category
          key={index}
          name={category.name}
          color={category.color}
          icon={category.icon}
          link={category.link}
        />
      ))}
    </div>
  );
}
