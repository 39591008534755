import axios from "axios";
import { ImSpinner2 } from "react-icons/im";
import { Link } from "react-router-dom";
import Image from "./Image";
import { useContext } from "react";
import { UserContext } from "../UserContext";
import { useState } from "react";
import { useEffect } from "react";
import { HiChevronDown, HiSearch } from "react-icons/hi";
import { BiArea, BiBath, BiBed } from "react-icons/bi";
import { BsGraphUp } from "react-icons/bs";

const PRICE_TOLERANCE = 100;

export default function PropertyFilter() {
  const [searchValue, setSearchValue] = useState("");
  const [locationSearchValue, setLocationSearchValue] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [clickCounts, setClickCounts] = useState({});
  const [properties, setProperties] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useContext(UserContext);

  const incrementClickCount = async (itemId) => {
    try {
      console.log("Incrementing click count for itemId:", itemId);
      await axios.post("/clicks", { itemId });
      console.log("Click count incremented for itemId:", itemId);
      setClickCounts((prevClickCounts) => ({
        ...prevClickCounts,
        [itemId]: (prevClickCounts[itemId] || 0) + 1,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleSearch = () => {
    setSelectedLocation(locationSearchValue);
    fetchProperties();
  };

  const fetchProperties = async () => {
    setIsLoading(true);

    const categoryQueryParam =
      selectedCategory !== "Default"
        ? `category=${encodeURIComponent(selectedCategory)}`
        : "";
    const locationQueryParam = selectedLocation
      ? `location=${encodeURIComponent(selectedLocation)}`
      : "";
    const minPriceQueryParam = minPrice ? `minPrice=${minPrice}` : "";
    const maxPriceQueryParam = maxPrice ? `maxPrice=${maxPrice}` : "";
    const queryParams = [
      categoryQueryParam,
      locationQueryParam,
      minPriceQueryParam,
      maxPriceQueryParam,
    ]
      .filter(Boolean)
      .join("&");
    const url = queryParams ? `/listing?${queryParams}` : "/listing";

    try {
      const response = await axios.get(url);
      const filteredProperties = response.data.filter((property) => {
        const propertyLocation = property.location.toLowerCase();
        const propertyAddress = property.address.toLowerCase();
        const propertyDescription = property.description.toLowerCase();
        const searchValueLower = searchValue.toLowerCase();
        const selectedLocationLower = selectedLocation.toLowerCase();
        const propertyPrice = Number(property.price.replace(/,/g, ""));
        const propertyBaths = Number(property.baths);
        const propertyBedrooms = Number(property.bedrooms);
        const propertySpace = Number(property.space);

        const isCategoryMatch =
          !selectedCategory ||
          selectedCategory === "Default" ||
          property.category === selectedCategory;

        const isPriceInRange =
          (!minPrice || propertyPrice >= minPrice - PRICE_TOLERANCE) &&
          (!maxPrice || propertyPrice <= maxPrice + PRICE_TOLERANCE);

        const isBathsMatch =
          !selectedCategory ||
          selectedCategory === "Default" ||
          propertyBaths === Number(selectedCategory);

        const isBedroomsMatch =
          !selectedLocation ||
          selectedLocation === "Default" ||
          propertyBedrooms === Number(selectedLocation);

        const isSpaceMatch =
          !locationSearchValue ||
          locationSearchValue === "Default" ||
          propertySpace === Number(locationSearchValue);

        return (
          (!selectedLocation ||
            propertyLocation.includes(selectedLocationLower) ||
            propertyAddress.includes(selectedLocationLower)) &&
          (property.title.toLowerCase().includes(searchValueLower) ||
            propertyDescription.toLowerCase().includes(searchValueLower)) &&
          isPriceInRange &&
          isBathsMatch &&
          isCategoryMatch &&
          isBedroomsMatch &&
          isSpaceMatch
        );
      });

      setProperties(filteredProperties);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProperties();
  }, [selectedCategory, selectedLocation, minPrice, maxPrice]);

  useEffect(() => {
    const fetchClickCounts = async () => {
      try {
        const response = await axios.get("/clicks");
        const { clickCounts } = response.data;
        setClickCounts(clickCounts);
      } catch (error) {
        console.error(error);
      }
    };

    fetchClickCounts();
  }, []);

  return (
    <div className="min-h-screen">
      <div className="flex-row items-center ">
        <div className="flex-col mx-auto max-w-max bg-white rounded-md pb-1">
          <div className=" flex lg:flex items-center justify-center gap-1 sm:grid sm:grid-cols-1 py-2 px-4 ">
            <div className="relative">
              <HiSearch className="absolute text-gray-400  h-8 w-8 inset-y-2 left-0 flex items-center pl-3" />
              <input
                type="text"
                placeholder="number of beds or keyword"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                className="w-full text-md px-2 placeholder-gray-500 border py-2 rounded-lg pl-10 bg-slate-100 focus:outline-none"
              />
            </div>

            <div className="flex items-center">
              <div className="relative">
                <HiSearch className="absolute text-gray-400  h-8 w-8 inset-y-2 left-0 flex items-center pl-3" />
                <input
                  type="text"
                  placeholder="Search property location"
                  value={locationSearchValue}
                  onChange={(e) => setLocationSearchValue(e.target.value)}
                  className="w-full text-md placeholder-gray-500 border py-2 rounded-lg pl-10 bg-slate-100 focus:outline-none"
                />
              </div>
              <button
                className="px-4 ml-2 h-10 w-36 lg:px-2 md:px-2 py-2 text-md font-medium text-white bg-lime-600 rounded-md hover:bg-lime-600 focus:outline-none focus:bg-lime-600"
                onClick={handleSearch}
              >
                Search
              </button>
            </div>
          </div>
          <div className="flex items-center md:flex ms:grid-cols-1 ms:grid sm:grid sm:grid-cols-1 sm:gap-2 ms:gap-2 gap-8">
            <div className=" ml-4 relative max-w-[195px] mb-4">
              <select
                className=" border-b-2 p-2 "
                value={selectedCategory || ""}
                onChange={handleCategoryChange}
              >
                <option value="Default">All categories</option>
                <option value="House">House</option>
                <option value="Apartment">Apartment</option>
                <option value="Land">Land</option>
                <option value="Commercial">Commercial</option>
                <option value="Office">Office</option>
                <option value="Short-Stays">Short-Stays</option>
              </select>
            </div>
            <div className="flex  gap-4">
              <div>
                <input
                  type="number"
                  placeholder="Min $"
                  value={minPrice}
                  onChange={(e) => setMinPrice(e.target.value)}
                  className="w-40 sm:w-14 md:w-40 lg:md:w-40 sm:pl-2 px-2 pl-11 text-sm border-b-2 border-gray-300"
                />
              </div>
              <div>
                <input
                  type="number"
                  placeholder="Max $"
                  value={maxPrice}
                  onChange={(e) => setMaxPrice(e.target.value)}
                  className="w-40 sm:w-14 md:w-40 lg:md:w-40 sm:pl-2 px-2 pl-11 text-sm border-b-2 border-gray-300"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="grid sm:grid-cols-1 mx-auto ms:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6">
        {isLoading ? (
          <ImSpinner2
            className="animate-spin  text-2xl justify-center text-lime-600 mx-auto
         text-2x1"
          />
        ) : properties.length > 0 ? (
          properties.map((property) => (
            <Link
              to={"/property/" + property._id}
              key={property._id}
              onClick={() => incrementClickCount(property._id)}
            >
              <div className="w-full max-w-[352px] max z-auto cursor-pointer hover:shadow-2xl p-4 transition rounded-lg bg-white">
                <div className="relative aspect-square mb-4">
                  <div className="absolute inset-0 rounded-2xl overflow-hidden">
                    {property.photos?.[1] && (
                      <Image
                        className="img w-full  h-full object-cover transform transition duration-200 ease-in-out hover:scale-120"
                        src={property.photos?.[1]}
                        alt=""
                      />
                    )}
                  </div>
                </div>
                <div className=" flex items-center justify-between">
                  <div className="inline-block bg-gray-100 font-bold text-gray-700 rounded-full text-[12px] px-4 py-1 mb-2">
                    {property.category}
                  </div>

                  {user && user.role === "admin" && (
                    <div className="flex items-center gap-2">
                      <BsGraphUp className="text-sm font-medium text-green-600" />
                      <span className="text-sm font-medium text-gray-500">
                        {clickCounts[property._id] || 0}
                      </span>
                    </div>
                  )}
                </div>

                <h2 className="font-bold mb-2">{property.title}</h2>
                <div className="flex gap-1">
                  <h3 className="text-sm truncate mb-4">{property.address},</h3>
                  <h3 className="text-sm font-semibold text-orange-500 truncate mb-4">
                    {property.location}
                  </h3>
                </div>

                <div className="flex gap-x-2 mb-2">
                  <div className="flex items-center text-gray-600 gap-1 text-sm">
                    <div className="text-[16px]">
                      <BiBed />
                    </div>
                    <div>{property.beds} beds</div>
                  </div>
                  <div className="flex items-center text-gray-600 gap-1 text-sm">
                    <div className="text-[16px]">
                      <BiBath />
                    </div>
                    <div>{property.baths} baths</div>
                  </div>
                  <div className="flex items-center text-gray-600 gap-1 text-sm">
                    <div className="text-[16px]">
                      <BiArea />
                    </div>
                    <div>{property.space}</div>
                  </div>
                </div>

                <h2 className="font-bold flex text-lg text-orange-600">
                  ${Number(property.price).toLocaleString()}
                  {property.listingsOptions &&
                  property.listingsOptions.length > 0 ? (
                    property.listingsOptions.map((listingsOptions, index) => (
                      <div key={index} className="flex gap-2 items-center">
                        <span className="text-gray-500 text-sm font-medium">
                          {listingsOptions}
                        </span>
                      </div>
                    ))
                  ) : (
                    <p></p>
                  )}
                </h2>
              </div>
            </Link>
          ))
        ) : (
          <div className="mx-auto max-w-xs justify-center">
            <p className="  justify-center text-gray-300 font-medium">
              No properties found.
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
