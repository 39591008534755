import React, { useState } from "react";
import axios from "axios";
import { Navigate } from "react-router-dom";

export default function SignUp() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [signUpError, setsignUpError] = useState(false);

  const [isChecked, setIsChecked] = useState(true);

  function handleCheckboxChange() {
    setIsChecked(!isChecked);
  }

  async function checkEmailExists(email) {
    try {
      const response = await axios.get("/check-email", {
        params: {
          email,
        },
      });
      return response.data.exists;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async function registerUser(ev) {
    ev.preventDefault();

    let isValid = true;

    // Validate name
    if (!name) {
      setNameError("Name is required");
      isValid = false;
    } else {
      setNameError("");
    }

    // Validate email
    if (!email) {
      setEmailError("Email is required");
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Invalid email address");
      isValid = false;
    } else {
      const emailExists = await checkEmailExists(email);
      if (emailExists) {
        setEmailError("Email already exists");
        isValid = false;
      } else {
        setEmailError("");
      }
    }

    // Validate password
    if (!password) {
      setPasswordError("Password is required");
      isValid = false;
    } else if (password.length < 8) {
      setPasswordError("Password must be at least 8 characters long");
      isValid = false;
    } else {
      setPasswordError("");
    }

    if (isValid) {
      try {
        await axios.post("/SignUp", {
          name,
          email,
          password,
        });
        setRedirect(true);
        setsignUpError("");
      } catch (e) {
        if (e.response) {
          if (e.response.status === 409) {
            setsignUpError("Email already exists");
          } else {
            setsignUpError("Something went wrong. Please try again later");
          }
        } else {
          setsignUpError("Network error. Please try again later");
        }
      }
    }
  }

  if (redirect) {
    return <Navigate to={"/Signin"} />;
  }

  return (
    <section className="py-8 bg-gray-50 sm:py-8 lg:py-8">
      <div className="flex-row px-4 lg:flex-row md:flex ms:grid ms:grid-cols-1 ms:gap-6 sm:flex-col sm:gap-4   mx-auto  items-center max-w-7xl sm:px-6 lg:px-8">
        <div className="max-w-2xl mx-auto">
          <h2 className="text-3xl font-bold leading-tight text-amber-600 sm:text-4xl lg:text-5xl">
            Welcome to Hoji-inc!
          </h2>
          <p className="max-w-xl mx-auto mt-4 text-base leading-relaxed text-gray-600">
            Sign up now to access exclusive property listings and services. With
            Hoji-inc, you'll have access to a wide range of properties and
            services, all at your fingertips. Join us today and discover your
            dream home or unlock new opportunities for your business.
          </p>
        </div>

        <div className="relative max-w-md mx-auto ">
          <div className="overflow-hidden bg-white rounded-xl shadow-md">
            <div className="px-4 py-6 sm:px-8 sm:py-7">
              <form onSubmit={registerUser}>
                <div className="space-y-5">
                  <div>
                    <label
                      for=""
                      className="text-base font-medium text-gray-900"
                    >
                      {" "}
                      First & Last name{" "}
                    </label>
                    <div className="mt-2.5 relative text-gray-400 focus-within:text-gray-600">
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg
                          className="w-5 h-5"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                          />
                        </svg>
                      </div>

                      <input
                        type="name"
                        value={name}
                        onChange={(ev) => setName(ev.target.value)}
                        placeholder="Enter your full name"
                        className="block w-full py-4 pl-10 pr-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-xl focus:outline-none focus:border-amber-600 caret-amber-600"
                      />
                    </div>
                    {nameError && (
                      <div className="mt-2 text-sm text-red-500">
                        {nameError}
                      </div>
                    )}
                  </div>

                  <div>
                    <label
                      for=""
                      className="text-base font-medium text-gray-900"
                    >
                      {" "}
                      Email address{" "}
                    </label>
                    <div className="mt-2.5 relative text-gray-400 focus-within:text-gray-600">
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg
                          className="w-5 h-5"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                          />
                        </svg>
                      </div>

                      <input
                        type="email"
                        value={email}
                        onChange={(ev) => setEmail(ev.target.value)}
                        placeholder="Enter email to get started"
                        className="block w-full py-4 pl-10 pr-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-xl focus:outline-none focus:border-amber-600 caret-amber-600"
                      />
                    </div>
                    {emailError && (
                      <div className="mt-2 text-sm text-red-500">
                        {emailError}
                      </div>
                    )}
                  </div>

                  <div>
                    <label
                      for=""
                      className="text-base font-medium text-gray-900"
                    >
                      {" "}
                      Password{" "}
                    </label>
                    <div className="mt-2.5 relative text-gray-400 focus-within:text-gray-600">
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg
                          className="w-5 h-5"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M12 11c0 3.517-1.009 6.799-2.753 9.571m-3.44-2.04l.054-.09A13.916 13.916 0 008 11a4 4 0 118 0c0 1.017-.07 2.019-.203 3m-2.118 6.844A21.88 21.88 0 0015.171 17m3.839 1.132c.645-2.266.99-4.659.99-7.132A8 8 0 008 4.07M3 15.364c.64-1.319 1-2.8 1-4.364 0-1.457.39-2.823 1.07-4"
                          />
                        </svg>
                      </div>

                      <input
                        type="password"
                        value={password}
                        onChange={(ev) => setPassword(ev.target.value)}
                        placeholder="Enter your password"
                        className="block w-full py-4 pl-10 pr-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-xl focus:outline-none focus:border-amber-600 caret-amber-600"
                      />
                    </div>
                    {passwordError && (
                      <div className="mt-2 text-sm text-red-500">
                        {passwordError}
                      </div>
                    )}
                    {signUpError && <div className="error">{signUpError}</div>}
                  </div>

                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      name="agree"
                      id="agree"
                      className="w-5 h-5 text-green-500 bg-white border-gray-200 rounded"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />

                    <label
                      for="agree"
                      className="ml-3 text-sm font-medium text-gray-500"
                    >
                      I agree to Hoji Inc ’s{" "}
                      <a
                        href="/terms"
                        title=""
                        className="text-amber-600 hover:text-amber-600 hover:underline"
                      >
                        Terms of Service
                      </a>{" "}
                      and{" "}
                      <a
                        href="/terms"
                        title=""
                        className="text-amber-600 hover:text-amber-600 hover:underline"
                      >
                        Privacy Policy
                      </a>
                    </label>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className={`inline-flex items-center justify-center w-full px-4 py-4 text-base font-semibold text-white transition-all duration-200 border rounded-xl focus:outline-none hover:bg-amber-600 focus:bg-amber-600 ${
                        isChecked
                          ? "bg-amber-600"
                          : "bg-gray-400 cursor-not-allowed"
                      }`}
                      disabled={!isChecked}
                    >
                      Create account
                    </button>
                  </div>

                  <div className="text-center">
                    <p className="text-base text-gray-600">
                      Already have an account?{" "}
                      <a
                        href="/SignIn"
                        title=""
                        className="font-medium text-orange-500 transition-all duration-200 hover:text-orange-600 hover:underline"
                      >
                        Login here
                      </a>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
