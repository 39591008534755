import { Link } from "react-router-dom";

export default function AboutPage() {
  return (
    <div>
      <div className="py-10 bg-gray-50 sm:py-16 lg:py-24">
        <div className="max-w-5xl px-4 mx-auto sm:px-6 lg:px-8">
          <div className="mx-auto text-left md:max-w-lg lg:max-w-2xl md:text-center">
            <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl lg:leading-tight">
              <span className="relative inline-block">
                <span className="absolute inline-block w-full h-2 bg-cyan-300 bottom-1.5"></span>
                <span className="relative text-amber-600">About Us</span>
              </span>
            </h2>
          </div>
          <div className="text-center text-lg text-gray-500 mt-8">
            Our platform is designed to cater to business and consumer needs. We
            understand that searching for the perfect property, retailer, or
            service can be overwhelming and time-consuming, which is why we
            created Hoji Inc to make the process as easy and enjoyable as
            possible.
          </div>

          <div className="grid grid-cols-1 mt-8 md:mt-20 gap-y-6 md:grid-cols-2 gap-x-10">
            <div>
              <h3 className="text-3xl font-bold  text-gray-900">
                For Consumers
              </h3>
              <p className="mt-4 text-lg text-gray-700">
                At Hoji Inc, we believe that finding the perfect property,
                reliable retailer, or trusted service provider should be an
                enjoyable experience. That’s why we have made it our mission to
                provide a handpicked selection of listings, backed by
                exceptional customer service and user-friendly technology. For
                our users seeking properties, products and services, we aim to
                simplify the processes of:
              </p>

              <ul className="list-disc list-inside mt-4 text-lg text-gray-700">
                <li>Buying, selling, or renting a property</li>
                <li>Finding trusted and reliable service providers</li>
                <li>
                  Locating capable and professional retailers with the desired
                  products
                </li>
              </ul>

              <p className="mt-4 text-lg text-gray-700">
                By providing a comprehensive platform that caters to the unique
                needs and preferences of our users. Our ultimate goal is to help
                users find their perfect match with ease and confidence, and to
                become their trusted partner for all their product, property,
                and service needs.
              </p>

              <p className="mt-8 text-lg text-gray-700">
                We strive to provide accurate and up-to-date information, ensure
                that our listings meet high standards of quality, and maintain
                an open line of communication with our users. We are proud of
                the reputation we have built for ourselves as a reliable and
                trustworthy listings platform.
              </p>
            </div>

            <div>
              <div>
                <div className="h-full pr-12 mt-16  lg:order-2 ">
                  <div className="relative h-full lg:h-auto">
                    <div className="absolute w-full h-full -mb-12 rounded-2xl overflow-hidden bg-gradient-to-r from-lime-600 to-lime-600 top-12 left-12 xl:left-16 lg:top-0 lg:scale-y-105 lg:origin-top">
                      <img
                        className="object-cover  object-right w-full h-full scale-150"
                        src="https://cdn.rareblocks.xyz/collection/celebration/images/content/2/lines.svg"
                        alt=""
                      />
                    </div>
                    <div className="relative lg:-top-12">
                      <img
                        className="rounded-2xl shadow-2xl"
                        src="https://cdn.rareblocks.xyz/collection/celebration/images/content/2/girl-drinking-coffee.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 mt-8 items-center md:mt-20 gap-y-6 md:grid-cols-2 gap-x-10">
            <div>
              <div>
                <div className="h-full mb-20 pr-12 mt-16  lg:order-2 ">
                  <div className="relative h-full lg:h-auto">
                    <div className="absolute w-full h-full mb-12 rounded-2xl overflow-hidden bg-gradient-to-r from-lime-600 to-lime-600 top-12 left-12 xl:left-16 lg:top-0 lg:scale-y-105 lg:origin-top">
                      <img
                        className="object-cover  object-right w-full h-full scale-150"
                        src="https://cdn.rareblocks.xyz/collection/celebration/images/content/2/lines.svg"
                        alt=""
                      />
                    </div>
                    <div className="relative h-72 lg:-top-12">
                      <img
                        className="rounded-2xl shadow-2xl"
                        src="https://images.unsplash.com/photo-1521791136064-7986c2920216?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=869&q=80"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <h3 className="text-3xl font-bold  text-gray-900">
                Business 2 Business
              </h3>
              <p className="mt-4 text-lg text-left text text-gray-700">
                As Business 2 Business Service Providers Our Hoji Inc
                Application and Website are digital marketing and advertising
                platforms designed for small businesses and individuals in
                Zimbabwe. Our goal is to provide our clients with the
                opportunity to gain mass exposure for their properties,
                products, or services. Our directories can help any business
                achieve their goals. Whether customers are searching by name or
                by the products/services you provide, we are here to make sure
                consumers can find you. Businesses that advertise with Hoji Inc:
              </p>

              <ul className="list-disc list-inside mt-4 text-lg text-left text-gray-700">
                <li>Receive exposure to our entire user database</li>
                <li>Receive feedback from customer experiences</li>
                <li>
                  Can receive usage statistics of how many Hoji users interact
                  with their ads
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
