import React from "react";
import { Link } from "react-router-dom";

function PaymentFailed() {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Payment Failed
          </h2>
        </div>
        <div className="text-center">
          <p className="mt-2 text-sm text-gray-600">
            There was an error processing your payment. Please try again later.
          </p>
        </div>
        <div className="flex justify-center">
          <Link
            to="/"
            className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
          >
            Back to Home
          </Link>
        </div>
      </div>
    </div>
  );
}

export default PaymentFailed;
