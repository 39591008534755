import { useContext, useState } from "react";
import React from "react";
import { HouseContext } from "./HouseContext";
import { ImSpinner2 } from "react-icons/im";
import ServiceFilter from "./ServiceFilter";
import ServiceAdsBanner from "./ServiceAdsBanner";

const ServiceList = () => {
  const { houses, loading } = useContext(HouseContext);

  if (loading) {
    return (
      <ImSpinner2
        className="animate-spin text-2xl text-lime-600 mx-auto
      text-2x1 mt-[200px]"
      />
    );
  }

  if (houses.length < 1) {
    return (
      <div className="text-center text-3x1 text-gray-400 mt-48">
        Sorry, nothing found
      </div>
    );
  }

  return (
    <section className=" bg-white">
      <div>
        <ServiceAdsBanner />
      </div>
      <div className="lg:mt-14 gap-6 md:mt-12 sm:mt-8">
        <div className="container mx-auto">
          <div className="font-bold max-w-2xl mx-auto text-center text-[28px] text-amber-600 mt-14 p-2">
            Unlock opportunities with
            <span className="text-lime-600"> leading</span> businesses
          </div>
          <div
            className=" font-normal max-w-2xl mx-auto text-center text-md text-gray-600 mb-4
     "
          >
            Connect with Zimbabwe's top companies and entrepreneurs
          </div>

          <div>
            <ServiceFilter />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceList;
