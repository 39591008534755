import React from "react";
import { GA4React } from "ga-4-react";
import { useLocation } from "react-router-dom";
//import routes and router from react-router-dom
import { Route, Routes } from "react-router-dom";

//import components
import Header from "./components/Header";
import Footer from "./components/Footer";

//import pages
import Home from "./pages/Home";
import PropertyDetails from "./pages/PropertyDetails";
import axios from "axios";
import { UserContextProvider } from "./UserContext";
import AccountPage from "./pages/AccountPage";
import PropertiesPage from "./pages/PropertiesPage";
import NewPropertyForm from "./pages/NewPropertyForm";
import MyFavourites from "./pages/MyFavourites";
import ListingsPage from "./pages/ListingsPage";
import ServicesList from "./components/ServicesList";
import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/contactPage";
import MyServices from "./pages/MyServices";
import NewServiceForm from "./pages/NewServiceForm";
import PricingPage from "./pages/PricingPage";
import SignUpPage from "./pages/SignUpPage";
import TermsPage from "./pages/TermsPage";
import SignInPage from "./pages/SignInPage";
import ServiceDetails from "./pages/ServiceDetails";
import RestaurantsPage from "./pages/RestaurantsPage";
import RetailersPage from "./pages/RetaillersPage";
import NewRestaurantPage from "./pages/NewRestaurantPage";
import MyRestaurants from "./pages/MyRestaurant";
import RestaurantDetails from "./pages/RestaurantDetails";
import MyRetails from "./pages/MyRetails";
import NewRetailerForm from "./pages/NewRetailerForm";
import RetailerDetails from "./pages/RetailerDetails";
import ResultsPage from "./components/ResultsPage";
import Advertise from "./pages/Advertise";
import AdsDisclaimer from "./pages/AdsDisclaimer";
import ResultDetails from "./components/ResultDetails";
import PrivacyPolicy from "./pages/PrivacyPolicy";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.withCredentials = true;

// LocalstorageService

axios.interceptors.request.use(
  (config) => {
    const accessToken = document.cookie.replace(
      /(?:(?:^|.*;\s*)accessToken\s*\=\s*([^;]*).*$)|^.*$/,
      "$1"
    );
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }

    if (
      config.url ===
      "https://backendservices.clicknpay.africa:2081/ecocashapi/initiate"
    ) {
      config.withCredentials = false;
      delete config.headers["Origin"];
    }

    console.log(config);
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    if (response.data && response.data.refresh_token) {
      const refreshToken = response.data.refresh_token;
      document.cookie = `refreshToken=${refreshToken}; expires=${new Date(
        Date.now() + 604800000
      ).toUTCString()}; path=/; secure; httpOnly`;
    }

    if (response.data && response.data.access_token) {
      const accessToken = response.data.access_token;
      document.cookie = `accessToken=${accessToken}; path=/; secure; httpOnly`;
    }

    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);
const ga4react = new GA4React("G-KNTWMJHTVN");
ga4react.initialize().catch((err) => console.error(err));

const App = () => {
  const location = useLocation();

  return (
    <UserContextProvider>
      <div className="w-full min-h-screen flex flex-col mx-auto bg-white body-font font-Inter ">
        <Header />
        <main className="px-4 min-h-screen">
          <Routes>
            <Route index element={<Home />} />

            <Route path="/results" element={<ResultsPage />} />
            <Route path="/SignIn/" element={<SignInPage />} />
            <Route path="/SignUp/" element={<SignUpPage />} />
            <Route path="/account" element={<AccountPage />} />
            <Route path="/property" element={<ListingsPage />} />
            <Route path="/About" element={<AboutPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/retailers" element={<RetailersPage />} />
            <Route path="/services" element={<ServicesList />} />
            <Route path="/restaurants" element={<RestaurantsPage />} />
            <Route path="/adsdisclaimer" element={<AdsDisclaimer />} />
            <Route path="/pricing" element={<PricingPage />} />
            <Route path="/terms" element={<TermsPage />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/advertise" element={<Advertise />} />
            <Route path="/account/favourites" element={<MyFavourites />} />
            <Route path="/account/retailers" element={<MyRetails />} />

            <Route path="/account/listings" element={<PropertiesPage />} />
            <Route path="/account/service" element={<MyServices />} />
            <Route path="/account/restaurants" element={<MyRestaurants />} />
            <Route
              path="/account/restaurants/new"
              element={<NewRestaurantPage />}
            />
            <Route
              path="/account/retailers/new"
              element={<NewRetailerForm />}
            />
            <Route path="/account/service/new" element={<NewServiceForm />} />
            <Route path="/account/listings/new" element={<NewPropertyForm />} />
            <Route path="/property/:id" element={<PropertyDetails />} />
            <Route path="/retailers/:id" element={<RetailerDetails />} />
            <Route path="/service/:id" element={<ServiceDetails />} />
            <Route path="/restaurants/:id" element={<RestaurantDetails />} />
            <Route path="/account/service/:id" element={<NewServiceForm />} />
            <Route path="/account/listings/:id" element={<NewPropertyForm />} />
            <Route path="/result/:id" element={<ResultDetails />} />

            <Route
              path="/account/retailers/:id"
              element={<NewRetailerForm />}
            />
            <Route
              path="/account/restaurants/:id"
              element={<NewRestaurantPage />}
            />
          </Routes>
        </main>
        <Footer />
      </div>
    </UserContextProvider>
  );
};

export default App;
