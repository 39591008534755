import axios from "axios";
import { ImSpinner2 } from "react-icons/im";
import { Link, useLocation } from "react-router-dom";
import Image from "./Image";
import RestaurantCategories from "./RestaurantCategories";
import { useState, useEffect } from "react";
import { HiSearch } from "react-icons/hi";
import ReactGA from "react-ga";

export default function RestaurantFilter() {
  const location = useLocation();

  const [searchValue, setSearchValue] = useState("");
  const [locationSearchValue, setLocationSearchValue] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedMeals, setSelectedMeals] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [restaurants, setRestaurants] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleCategoryChange = (event) => {
    const category = event.target.value;
    if (category === "All") {
      setSelectedCategory("");
      setSelectedMeals("");
      setSelectedLocation("");
    } else {
      setSelectedCategory(category);
    }
  };

  const handleSearch = () => {
    setSelectedLocation(locationSearchValue);
    fetchRestaurants();
  };

  const fetchRestaurants = async () => {
    setIsLoading(true);
    const categoryQueryParam = selectedCategory
      ? `category=${encodeURIComponent(selectedCategory)}`
      : "";
    const mealsQueryParam = selectedMeals
      ? `meals=${encodeURIComponent(selectedMeals)}`
      : "";
    const locationQueryParam = selectedLocation
      ? `location=${encodeURIComponent(selectedLocation)}`
      : "";
    const queryParams = [
      mealsQueryParam,
      categoryQueryParam,
      locationQueryParam,
      `search=${encodeURIComponent(searchValue)}`,
    ]
      .filter(Boolean)
      .join("&");
    const url = queryParams
      ? `/restaurants-listing?${queryParams}`
      : "/restaurants-listing";
    try {
      const response = await axios.get(url);
      const filteredRestaurants = response.data.filter((restaurant) => {
        // Check if the restaurant location or address contains the search keyword
        // Filtering services based on search criteria
        const searchKeyword = searchValue.toLowerCase();
        const locationKeyword = selectedLocation.toLowerCase();

        return (
          (!selectedLocation ||
            restaurant.location.toLowerCase().includes(locationKeyword) ||
            restaurant.address.toLowerCase().includes(locationKeyword)) &&
          (restaurant.title.toLowerCase().includes(searchKeyword) ||
            restaurant.description.toLowerCase().includes(searchKeyword) ||
            restaurant.location.toLowerCase().includes(searchKeyword) ||
            restaurant.cuisines.toLowerCase().includes(searchKeyword) ||
            restaurant.meals.toLowerCase().includes(searchKeyword))
        );
      });
      setRestaurants(filteredRestaurants);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    ReactGA.pageview(location.pathname); // Track page view on component mount and whenever the location changes
    fetchRestaurants();
  }, [selectedCategory, selectedLocation, selectedMeals, location]);
  return (
    <div className="min-h-screen">
      <RestaurantCategories
        onCategorySelect={setSelectedCategory}
        onMealsSelect={setSelectedMeals}
      />
      <div className="flex-row items-center mb-4 ">
        <div className="flex-col mx-auto max-w-max bg-white rounded-md pb-1">
          <div className="flex lg:flex md:flex items-center md:px-16 justify-center gap-1 sm:grid sm:grid-cols-1 py-2 px-4 ">
            <div className="relative">
              <HiSearch className="absolute text-gray-400  h-8 w-8 inset-y-2 left-0 flex items-center pl-3" />
              <input
                type="text"
                placeholder="Restaurant, meal or cuisine"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                className="w-full text-md px-2 placeholder-gray-500 border py-2 rounded-lg pl-10 bg-slate-100 focus:outline-none"
              />
            </div>

            <div className="flex items-center">
              <div className="relative">
                <HiSearch className="absolute text-gray-400  h-8 w-8 inset-y-2 left-0 flex items-center pl-3" />
                <input
                  type="text"
                  placeholder="Search location"
                  value={locationSearchValue}
                  onChange={(e) => setLocationSearchValue(e.target.value)}
                  className="w-full text-md placeholder-gray-500 border py-2 rounded-lg pl-10 bg-slate-100 focus:outline-none"
                />
              </div>
              <button
                className="px-4 ml-2 h-10 w-36 lg:px-2 md:px-2 py-2 text-md font-medium text-white bg-amber-600 rounded-md hover:bg-amber-600 focus:outline-none focus:bg-lime-600"
                onClick={handleSearch}
              >
                Search
              </button>
            </div>
            <div className="flex md:grid md:grid-cols-1 lg:flex ms:grid ms:grid-cols-1 sm:grid sm:grid-cols-1 sm:gap-4  gap-12">
              <div className=" ml-4 relative max-w-[195px]">
                <select className=" border-b-2 p-2" value={selectedMeals}>
                  <option value="">Type of Meals</option>
                  <option value="Construction">Breakfast</option>
                  <option value="Solar ">Family-style meals</option>
                  <option value="Air conditioning">
                    Takeaway with delivery
                  </option>
                  <option value="Renovations ">Takeaway</option>
                </select>
                {/* <div className="pointer-events-none absolute inset-y-0 right-6 flex items-center px-2 text-gray-700">
              <HiChevronDown />
            </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="grid sm:grid-cols-1 max-w-6xl mb-16 mx-auto ms:grid-cols-2 md:grid-cols-4 md:px-8 lg:grid-cols-4 gap-6">
        {isLoading ? (
          <ImSpinner2
            className="animate-spin  text-2xl justify-center text-amber-600 mx-auto
         text-2x1"
          />
        ) : restaurants.length > 0 ? (
          restaurants.map((restaurant) => {
            return (
              <Link to={"/restaurants/" + restaurant._id} key={restaurant._id}>
                <div className="w-full max-w-[352px] max z-auto cursor-pointer hover:shadow-2xl p-4 transition rounded-lg bg-white">
                  <div className="relative aspect-square mb-4">
                    <div className="absolute inset-0 rounded-2xl overflow-hidden">
                      {restaurant.photos?.[1] && (
                        <Image
                          className="img w-full h-full object-cover transform transition duration-200 ease-in-out hover:scale-120"
                          src={restaurant.photos?.[1]}
                          alt=""
                        />
                      )}
                    </div>
                  </div>
                  <div className="inline-block bg-orange-100 font-bold text-orange-700 rounded-full text-[12px] px-4 py-1 mb-2">
                    {restaurant.category}
                  </div>
                  <div>
                    <h2 className="font-bold ">{restaurant.title}</h2>
                  </div>

                  <h3 className="text-sm font-normal text-gray-500 truncate ">
                    {restaurant.cuisines}
                  </h3>
                  <h3 className="text-sm font-normal text-gray-500 truncate ">
                    {restaurant.meals}
                  </h3>
                  {/* <div className=" flex gap-2 items-center">
                    <h2 className=" text-sm font-medium text-gray-500">
                      Price range
                    </h2>
                    <h2 className="font-bold text-sm text-orange-600">
                      ${Number(minPrice).toLocaleString()}- $
                      {Number(maxPrice).toLocaleString()}
                    </h2>
                  </div> */}
                </div>
              </Link>
            );
          })
        ) : (
          <div className="mx-auto justify-center">
            <p className="  justify-center text-gray-300 font-medium">
              No Restaurants found.
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
