import { HiWifi } from "react-icons/hi";
import { RiCarLine, RiDropLine, RiLock2Line } from "react-icons/ri";
import { FaSolarPanel, FaWater, FaWind } from "react-icons/fa";

export default function Amenities({ selected, onChange }) {
  function handleCbClick(ev) {
    const { name, checked } = ev.target;
    // if (typeof onChange === "function")
    if (checked) {
      onChange([...selected, name]);
    } else {
      onChange([...selected.filter((selectedName) => selectedName !== name)]);
    }
  }
  return (
    <>
      <label className="border p-4 flex rounded-2xl gap-2 items-center cursor-pointer">
        <input
          type="checkbox"
          checked={selected.includes("wifi")}
          name="wifi"
          onChange={handleCbClick}
        />
        <HiWifi />
        Wifi
      </label>
      <label className="border p-4 flex rounded-2xl gap-2 items-center cursor-pointer">
        <input
          type="checkbox"
          checked={selected.includes("parking")}
          name="parking"
          onChange={handleCbClick}
        />
        <RiCarLine />
        Parking
      </label>
      <label className="border p-4 flex rounded-2xl gap-2 items-center cursor-pointer">
        <input
          type="checkbox"
          checked={selected.includes("swimmingPool")}
          name="swimmingPool"
          onChange={handleCbClick}
        />
        <FaWater />
        SwimmingPool
      </label>
      <label className="border p-4 flex rounded-2xl gap-2 items-center cursor-pointer">
        <input
          type="checkbox"
          checked={selected.includes("airConditioning")}
          name="airConditioning"
          onChange={handleCbClick}
        />
        <FaWind />
        Air Conditioning
      </label>
      <label className="border p-4 flex rounded-2xl gap-2 items-center cursor-pointer">
        <input
          type="checkbox"
          checked={selected.includes("solarBackup")}
          name="solarBackup"
          onChange={handleCbClick}
        />
        <FaSolarPanel />
        SolarBackup
      </label>
      <label className="border p-4 flex rounded-2xl gap-2 items-center cursor-pointer">
        <input
          type="checkbox"
          checked={selected.includes("borehole")}
          name="borehole"
          onChange={handleCbClick}
        />
        <RiDropLine />
        Borehole
      </label>
      <label className="border p-4 flex rounded-2xl gap-2 items-center cursor-pointer">
        <input
          type="checkbox"
          checked={selected.includes("walledGated")}
          name="walled & Gated"
          onChange={handleCbClick}
        />
        <RiLock2Line />
        Walled & Gated
      </label>
    </>
  );
}
