import React from "react";

import PropertyFilter from "../components/PropertyFilter";

const ListingsPage = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="h-auto">
      <div className="py-10 bg-white sm:py-16 lg:py-24">
        <div className="max-w-5xl px-4 mx-auto sm:px-6 lg:px-8">
          <div className="grid items-center md:grid-cols-2 md:gap-x-20 gap-y-10">
            <div className="relative sm:relative-none pl-16 pr-10 sm:pl-14 md:pl-0 xl:pr-0 md:order-2">
              <img
                className="sm:hidden absolute top-6 -right-4 xl:-right-12"
                src="https://cdn.rareblocks.xyz/collection/celebration/images/features/3/dots-pattern.svg"
                alt=""
              />

              <div className="relative  max-w-xs ml-auto">
                <div className="overflow-hidden rounded-xl aspect-w-3 aspect-h-4">
                  <img
                    className="object-cover w-full h-full scale-150"
                    src="https://i.ibb.co/t238SzN/jason-briscoe-AQl-J19oc-WE-unsplash.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div className="md:order-1">
              <h2 className="text-3xl font-bold leading-tight text-lime-600 sm:text-4xl lg:text-5xl">
                Find Your
                <span className=" text-amber-600"> Dream Space </span> in
                Zimbabwe
              </h2>
              <p className="mt-4 text-base leading-relaxed text-gray-600">
                Explore a world of real estate opportunities
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto mb-8">
        <PropertyFilter />
      </div>
    </section>
  );
};

export default ListingsPage;
