import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { BsWhatsapp } from "react-icons/bs";
import { HiOutlineMail } from "react-icons/hi";
import { useParams } from "react-router-dom";
export default function ContactForm() {
  const { id } = useParams();
  const [property, setProperty] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(false);

  function handleSubscribe() {
    // handle subscription logic
    setIsSubscribed(true);
  }

  useEffect(() => {
    if (!id) {
      return;
    }
    axios.get("/listings/" + id).then((res) => {
      setProperty(res.data);
    });
  }, [id]);

  if (!property) return "";

  return (
    <section>
      <div className=" max-w-md border p-2 rounded-2xl border-gray-100">
        <div>
          <div className="mx-2 font-bold  text-lg">Contact Owner:</div>
          <p className="text-sm px-2 mb-1 text-gray-500">
            Connect with the property owner or agent by email, calling or
            messaging on WhatsApp.
          </p>
          <form className="flex flex-col gap-y-4">
            <input
              className="border border-gray-300 bg-gray-50 focus:border-lime-600 outline-none rounded w-full px-4 h-14 text-sm"
              type="text"
              placeholder="Enter your name"
              id="name-input"
            />

            <textarea
              className="border border-gray-300 bg-gray-50 focus:border-lime-600 outline-none resize-none rounded-xl w-full p-4 h-36 text-sm text-gray-400"
              placeholder="Hello, I am interested in this property"
              id="message-input"
            ></textarea>

            <button
              className="bg-blue-50 p-1 w-24 justify-center rounded-full flex items-center gap-2"
              onClick={(e) => {
                e.preventDefault();

                const name = document.getElementById("name-input").value;
                const message = document.getElementById("message-input").value;

                const subject = `I am Interested in this ${property.ti}`;
                let body = message;

                if (message) {
                  body = `I am Interested in this ${property.title} my name is ${name}`;
                }

                if (name) {
                  body = `My name is ${name}.\n\n${body}`;
                }

                window.location.href = `mailto:${
                  property.email
                }?subject=${subject}&body=${encodeURIComponent(body)}`;
              }}
            >
              <HiOutlineMail className="text-lime-600 w-5 h-5" />
              <p className="text-lime-600 font-medium">Email</p>
            </button>

            <div className="flex gap-x-2">
              <button
                className="bg-lime-600 hover:bg-lime-600 text-white font-bold rounded-full p-4 h-12 flex justify-center text-sm w-full transition"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = `tel:${property.call}`;
                }}
              >
                Call
              </button>

              <button
                className="bg-green-100 rounded-full w-[50%] flex text-center justify-center p-2"
                onClick={(e) => {
                  e.preventDefault();
                  const defaultMessage = `Hello, I am interested in this property ${property.title}.\n\nCheck out more listings at Hoji Inc. Search for services, properties, restaurants or retailers.\n\nTo advertise with Hojiinc visit www.hojiinc.co.zw today!`;
                  const url = `https://wa.me/${
                    property.call
                  }?text=${encodeURIComponent(defaultMessage)}`;

                  const link = document.createElement("a");
                  link.href = url;
                  link.target = "_blank";
                  link.click();
                }}
              >
                <BsWhatsapp className="justify-center text-green-600 w-7 h-7 mt-0.5" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}
