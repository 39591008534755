import React from "react";

function PaymentSuccessful() {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-24 h-24 text-green-500"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M3.5 10a6.5 6.5 0 1113 0 6.5 6.5 0 01-13 0zm9.178-1.177l-2.5 2.5-1.5-1.5a.5.5 0 00-.707 0l-.5.5a.5.5 0 000 .707l2 2a.5.5 0 00.707 0l3-3a.5.5 0 00-.707-.707z"
          clipRule="evenodd"
        />
      </svg>
      <h1 className="mt-4 text-xl font-bold text-gray-700">
        Payment Successful
      </h1>
      <p className="mt-2 text-gray-500">
        Thank you for your payment. Your order has been confirmed and will be
        processed shortly.
      </p>
      <button className="mt-4 px-4 py-2 font-semibold text-white bg-lime-600 rounded hover:bg-lime-600">
        Continue Shopping
      </button>
    </div>
  );
}

export default PaymentSuccessful;
