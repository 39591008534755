import axios from "axios";
import { ImSpinner2 } from "react-icons/im";
import { Link } from "react-router-dom/dist";
import Image from "./Image";
import { useState } from "react";
import { useContext } from "react";
import { UserContext } from "../UserContext";
import { useEffect } from "react";
import { HiSearch } from "react-icons/hi";
import { BsGraphUp } from "react-icons/bs";

export default function RetailerFilter() {
  const [searchValue, setSearchValue] = useState("");
  const [locationSearchValue, setLocationSearchValue] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [retailers, setRetailers] = useState([]);
  const [clickCounts, setClickCounts] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState({});
  const { user } = useContext(UserContext);

  const incrementClickCount = async (itemId) => {
    try {
      await axios.post("/clicks", { itemId });
      setClickCounts((prevClickCounts) => ({
        ...prevClickCounts,
        [itemId]: (prevClickCounts[itemId] || 0) + 1,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };
  const handleSearch = () => {
    setSelectedLocation(locationSearchValue);
    fetchRetailers();
  };

  const fetchRetailers = async () => {
    setIsLoading(true);
    const categoryQueryParam = selectedCategory
      ? `category=${encodeURIComponent(selectedCategory)}`
      : "";
    const locationQueryParam = selectedLocation
      ? `location=${encodeURIComponent(selectedLocation)}`
      : "";
    const queryParams = [categoryQueryParam, locationQueryParam]
      .filter(Boolean)
      .join("&");
    const url = queryParams
      ? `/retailers-listing?${queryParams}`
      : "/retailers-listing";
    try {
      const response = await axios.get(url);
      const filteredRetailers = response.data.filter((retailer) => {
        // Check if the retailer location or address contains the search keyword
        const searchKeyword = searchValue.toLowerCase();
        const locationKeyword = selectedLocation.toLowerCase();

        return (
          (!selectedLocation ||
            retailer.location.toLowerCase().includes(locationKeyword) ||
            retailer.address.toLowerCase().includes(locationKeyword)) &&
          (retailer.title.toLowerCase().includes(searchKeyword) ||
            retailer.description.toLowerCase().includes(searchKeyword) ||
            retailer.location.toLowerCase().includes(searchKeyword))
        );
      });
      setRetailers(filteredRetailers);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchRetailers();
  }, [selectedCategory, selectedLocation]);

  const handleImageLoad = (itemId) => {
    setImageLoading((prevLoading) => ({
      ...prevLoading,
      [itemId]: false,
    }));
  };

  const handleImageError = (itemId) => {
    setImageLoading((prevLoading) => ({
      ...prevLoading,
      [itemId]: false,
    }));
    // Handle image loading error if needed
  };

  useEffect(() => {
    const fetchClickCounts = async () => {
      try {
        const response = await axios.get("/clicks");
        const { clickCounts } = response.data;
        setClickCounts(clickCounts);
      } catch (error) {
        console.error(error);
      }
    };

    fetchClickCounts();
  }, []);

  return (
    <div className="min-h-screen">
      <div className="flex-row items-center ">
        <div className="flex-col mx-auto max-w-max bg-white rounded-md pb-1">
          <div className="flex lg:flex items-center justify-center gap-1 sm:grid sm:grid-cols-1 py-2 px-4 ">
            <div className="relative">
              <HiSearch className="absolute text-gray-400  h-8 w-8 inset-y-2 left-0 flex items-center pl-3" />
              <input
                type="text"
                placeholder="Retailer title or keyword"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                className="w-full text-md px-2 placeholder-gray-500 border py-2 rounded-lg pl-10 bg-slate-100 focus:outline-none"
              />
            </div>

            <div className="flex items-center">
              <div className="relative">
                <HiSearch className="absolute text-gray-400  h-8 w-8 inset-y-2 left-0 flex items-center pl-3" />
                <input
                  type="text"
                  placeholder="Search location"
                  value={locationSearchValue}
                  onChange={(e) => setLocationSearchValue(e.target.value)}
                  className="w-full text-md px-2 placeholder-gray-500 border py-2 rounded-lg pl-10 bg-slate-100 focus:outline-none"
                />
              </div>
              <button
                className="px-4 ml-2 h-10 w-36 lg:px-2 md:px-2 py-2 text-md font-medium text-white bg-lime-600 rounded-md hover:bg-lime-600 focus:outline-none focus:bg-lime-600"
                onClick={handleSearch}
              >
                Search
              </button>
            </div>
          </div>
          <div className=" ml-4 relative max-w-[195px] mb-4">
            <select
              className=" border-b-2  p-2"
              value={selectedCategory || ""}
              onChange={handleCategoryChange}
            >
              <option value="">Select a category</option>
              <option value="Supermarkets">Supermarkets</option>
              <option value="Convenience Stores">Convenience Stores</option>
              <option value="Fresh Markets">Fresh Markets</option>
              <option value="Shopping Malls">Shopping Malls</option>
              <option value="Electronics Stores">Electronics Stores</option>
              <option value="Fashion and Apparel Stores">
                Fashion and Apparel Stores
              </option>
              <option value="Specialty Stores">Specialty Stores</option>
              <option value="Other">Other</option>
            </select>
          </div>
        </div>
      </div>

      <div className="grid sm:grid-cols-1 max-w-6xl mb-16 mx-auto ms:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {isLoading ? (
          <ImSpinner2
            className="animate-spin text-2xl justify-center text-lime-600 mx-auto
       text-2x1"
          />
        ) : retailers.length > 0 ? (
          retailers.map((retailer) => (
            <Link
              to={"/retailers/" + retailer._id}
              key={retailer._id}
              onClick={() => incrementClickCount(retailer._id)}
            >
              <div className="w-full max-w-[352px] max z-auto cursor-pointer hover:shadow-2xl p-4 transition rounded-lg bg-white">
                <div className="relative aspect-square mb-4">
                  <div className="absolute inset-0 rounded-2xl overflow-hidden">
                    {retailer.photos?.[1] && (
                      <Image
                        className="img w-full h-full object-cover transform transition duration-200 ease-in-out hover:scale-120"
                        src={retailer.photos?.[1]}
                        alt=""
                        onLoad={() => handleImageLoad(retailer._id)}
                        onError={() => handleImageError(retailer._id)}
                      />
                    )}
                    {imageLoading[retailer._id] && (
                      <div className="absolute inset-0 flex items-center justify-center">
                        <ImSpinner2 className="animate-spin text-2xl text-lime-600" />
                      </div>
                    )}
                  </div>
                </div>

                <div className="flex justify-between">
                  <h2 className="font-bold ">{retailer.title}</h2>
                </div>
                <h2 className=" font-medium text-lime-600">
                  {retailer.location}
                </h2>
                <h2 className=" truncate text-sm mb-1 text-gray-600">
                  {retailer.description}
                </h2>
                <div className=" flex items-center justify-between">
                  <div className="inline-block bg-gray-100 font-bold text-gray-700 rounded-full text-[12px] px-4 py-1 mb-2">
                    {retailer.category}
                  </div>

                  {user && user.role === "admin" && (
                    <div className="flex items-center gap-2">
                      <BsGraphUp className="text-sm font-medium text-green-600" />
                      <span className="text-sm font-medium text-gray-500">
                        {clickCounts[retailer._id] || 0}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </Link>
          ))
        ) : (
          <div className="mx-auto max-w-xs justify-center">
            <p className="  justify-center text-gray-300 font-medium">
              No retailers found.
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
