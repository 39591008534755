import { useEffect, useState } from "react";
import PhotosUploader from "../components/PhotosUploader";
import Amenities from "../components/Amenities";
import { HiChevronDown } from "react-icons/hi";
import axios from "axios";
import AccountNav from "../AccountNav";
import { Navigate, useParams } from "react-router-dom";
import ListingsOptions from "../components/ListingsOptions";

export default function NewPropertyForm() {
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [address, setAddress] = useState("");
  const [location, setLocation] = useState("");
  const [beds, setBeds] = useState("");
  const [baths, setBaths] = useState("");
  const [call, setCall] = useState("");
  const [space, setSpace] = useState("");
  const [email, setEmail] = useState("");
  const [addedPhotos, setaddedPhotos] = useState([]);
  const [amenities, setAmenities] = useState([]);
  const [sharable, setSharable] = useState([]);
  const [listingsOptions, setlistingsOptions] = useState([]);

  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if (!id) {
      return;
    }
    axios.get("/listings/" + id).then((res) => {
      const { data } = res;
      setTitle(data.title);
      setCategory(data.category);
      setDescription(data.description);
      setPrice(data.price);
      setAddress(data.address);
      setaddedPhotos(data.photos);
      setAmenities(data.amenities);
      setSharable(data.sharable);
      setlistingsOptions(data.listingsOptions);
      setBeds(data.beds);
      setBaths(data.baths);
      setSpace(data.space);
      setCall(data.call);
      setEmail(data.email);
      setLocation(data.location);
    });
  }, [id]);

  function inputHeader(text) {
    return <h2 className="text-2xl mt-4">{text}</h2>;
  }

  function inputDescription(text) {
    return <p className=" text-sm text-gray-500">{text}</p>;
  }

  function preInput(Header, description) {
    return (
      <>
        {inputHeader(Header)}
        {inputDescription(description)}
      </>
    );
  }

  async function saveProperty(ev) {
    ev.preventDefault();
    const propertyData = {
      title,
      category,
      description,
      price,
      listingsOptions,
      call,
      email,
      address,
      addedPhotos,
      location,
      beds,
      baths,
      space,
      amenities,
      sharable,
    };
    try {
      if (id) {
        // update
        await axios.put("/listings", {
          id,
          ...propertyData,
        });
        setRedirect(true);
      } else {
        // new property
        await axios.post("/properties", {
          ...propertyData,
        });
        setRedirect(true);
      }
    } catch (err) {
      console.error(err);
      setRedirect(true);
    }
  }

  if (redirect) {
    return <Navigate to={"/account/listings"} />;
  }

  return (
    <div className="container mx-auto mb-8">
      <AccountNav />
      <form onSubmit={saveProperty}>
        <h2 className="text-2xl mt-4">Category</h2>
        <p className="text-sm text-gray-500 mb-2">
          Please select the category that best describes your property:
        </p>
        <div className="relative w-full mb-4">
          <select
            value={category}
            onChange={(ev) => setCategory(ev.target.value)}
            className="form-input p-2 "
          >
            <option value="Default">select category</option>
            <option value="House">House</option>
            <option value="Apartment">Apartment</option>
            <option value="Land">Land</option>
            <option value="Commercial">Commercial</option>
            <option value="Office">Office</option>
            <option value="Short-Stays">Short-Stays</option>
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <HiChevronDown />
          </div>
        </div>

        {preInput(
          "Title",
          "Please enter a short and catchy title for your property:"
        )}
        <input
          className="form-input"
          type="text"
          value={title}
          onChange={(ev) => setTitle(ev.target.value)}
          placeholder="Property title"
        />

        {preInput("Address", "Please enter the full address of your property:")}
        <div className="grid grid-cols-2 md:grid-cols-4 w-full  gap-4 mt-2">
          <input
            type="text"
            value={address}
            placeholder="address"
            onChange={(ev) => setAddress(ev.target.value)}
            className="border  w-full  py-3 px-3 mb-2 mt-2 rounded-2xl"
          />
          <input
            type="text"
            value={location}
            placeholder="location"
            onChange={(ev) => setLocation(ev.target.value)}
            className="border py-3 px-3 rounded-2xl"
          />
        </div>

        {preInput(
          "Contact info",
          "Please provide contact info linked to the property:"
        )}
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-2">
          <input
            type="number"
            value={call}
            placeholder="+263 712 345 689"
            onChange={(ev) => setCall(ev.target.value)}
            className="border py-3 px-3 mb-2 mt-2 rounded-2xl"
          />
          <input
            type="email"
            value={email}
            placeholder="Enter email"
            onChange={(ev) => setEmail(ev.target.value)}
            className="border py-3 px-3 rounded-2xl"
          />
        </div>

        {preInput(
          "Photos",
          "Please upload at least one photo of your property:"
        )}
        <PhotosUploader addedPhotos={addedPhotos} onChange={setaddedPhotos} />

        {preInput("Description", "Enter a short description of the property:")}
        <textarea
          value={description}
          onChange={(ev) => setDescription(ev.target.value)}
          className="w-full border my-2 py-3 px-3 rounded-2xl"
        />

        {preInput(
          "Info",
          "Please provide some basic information about your property:"
        )}
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-2">
          <input
            type="number"
            value={beds}
            placeholder="Number of bedrooms"
            onChange={(ev) => setBeds(ev.target.value)}
            className="border py-3 px-3 rounded-2xl"
          />
          <input
            type="number"
            value={baths}
            placeholder="Number of bathrooms"
            onChange={(ev) => setBaths(ev.target.value)}
            className="border py-3 px-3 rounded-2xl"
          />
          <input
            type="number"
            value={space}
            placeholder="Square footage"
            onChange={(ev) => setSpace(ev.target.value)}
            className="border py-3 px-3 rounded-2xl"
          />
        </div>

        {preInput("Price", "Please enter price details:")}
        <input
          type="text"
          value={price.toLocaleString()}
          onChange={(ev) => {
            const formattedValue = ev.target.value.replace(/[^0-9]/g, "");
            setPrice(parseInt(formattedValue));
          }}
          placeholder="$550"
          className="form-input"
        />

        {preInput(
          "Amenities",
          "Please select the amenities that your property offers:"
        )}
        <div className="grid gap-2 grid-cols-6 md:grid-cols-4 sm:grid-cols-4 ms:grid-cols-4 mt-2">
          <Amenities selected={amenities} onChange={setAmenities} />
        </div>
        {preInput("Listing", "Is the property for sale or for rent?")}
        <div className=" mt-2">
          <ListingsOptions
            selected={listingsOptions}
            onChange={setlistingsOptions}
          />
        </div>

        <button className="bg-lime-600 font-medium text-white justify-center py-2 px-3 rounded-full mt-4 text-sm">
          Save Property
        </button>
      </form>
    </div>
  );
}
