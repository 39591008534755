import { useRef } from "react";
import Image from "../assets/img/restaurants-banner.png";
import Image2 from "../assets/img/food.png";
import { useEffect } from "react";

export default function RestaurantsBanner() {
  const ref = useRef();
  useEffect(() => {
    const handleScroll = () => {
      const posY = window.pageYOffset / 2;
      ref.current.style.backgroundPositionY = `${posY}px`;

      const scrollPos = window.pageYOffset;
      const height = window.innerHeight;
      const bannerHeight = ref.current.offsetHeight;
      const content = ref.current.querySelector(".content");

      if (scrollPos < bannerHeight) {
        const progress = (scrollPos / bannerHeight) * 100;
        const opacity = 1 - progress / 100;
        const scale = 1 - progress / 300;
        const translateY = (1 - scale) * 50;
        content.style.opacity = opacity;
        content.style.transform = `scale(${scale}) translateY(${translateY}px)`;
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <section
      className="relative h-[600px] md:pt-8 sm:h-[450px] w-full banner items-center shadow-lg "
      style={{ backgroundImage: `url(${Image})` }}
      ref={ref}
    >
      <div className="flex items-center lm:flex sm:flex md:flex lg:flex">
        {/* text */}
        <div className="lg:ml-8  max-h-[450px] xl:ml-[135px] flex flex-col items-center lg:items-start text-center lg:text-left justify-center flex-1 px-4 lg:px-0 content">
          <div className="max-w-2xl rounded-md shadow-lg px-8 py-8 bg-white">
            <h1 className=" text-lime-600 font-semibold text-base ">
              Savour the flavours of Zimbabwe
            </h1>
            <h1 className="text-7xl md:text-left lg:text-7xl text-amber-600 font-bold leading-none mb-6">
              What are you
              <span className="text-lime-600">Hungry </span> For?
            </h1>
          </div>
        </div>
        <div className=" content">
          <img
            src={Image2}
            className=" max-w-[608px] sm:max-w-[250px] md:max-w-[450px]"
            alt=""
          />
        </div>
      </div>
    </section>
  );
}
