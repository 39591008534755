import axios from "axios";
import { useState } from "react";
import { ImSpinner2 } from "react-icons/im";
import { toast } from "react-toastify";

export default function Advertise() {
  const [isSending, setIsSending] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [surname, setSurname] = useState("");
  const [phone, setPhone] = useState("");
  const [id, setId] = useState("");
  const [address, setAddress] = useState("");
  const [position, setPosition] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [productService, seProductService] = useState("");
  const [propertyType, setPropertyType] = useState("");
  const [agreed, setAgreed] = useState("");
  const [isChecked, setIsChecked] = useState(true);

  function handleCheckboxChange() {
    setIsChecked(!isChecked);
  }

  const [formErrors, setFormErrors] = useState({
    firstName: "",
    surname: "",
    email: "",
  });

  const validateFirstName = () => {
    if (firstName.trim() === "") {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        firstName: "FirstName is required",
      }));
    } else {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        firstName: "",
      }));
    }
  };

  const validateSurname = () => {
    if (surname.trim() === "") {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        surname: "Surname is required",
      }));
    } else {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        surname: "",
      }));
    }
  };

  const validateEmail = () => {
    if (email.trim() === "") {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        email: "Email is required",
      }));
    } else {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        email: "",
      }));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "firstName") {
      setFirstName(value);
      validateFirstName();
    } else if (name === "surname") {
      setSurname(value);
      validateSurname();
    } else if (name === "email") {
      setEmail(value);
      validateEmail();
    } else if (name === "phone") {
      setPhone(value);
    } else if (name === "address") {
      setAddress(value);
    } else if (name === "id") {
      setId(value);
    } else if (name === "companyName") {
      setCompanyName(value);
    } else if (name === "position") {
      setPosition(value);
    } else if (name === "productService") {
      seProductService(value);
    } else if (name === "propertyType") {
      setPropertyType(value);
    } else if (name === "message") {
      setMessage(value);
    } else if (name === "agreed") {
      setIsChecked(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    validateFirstName();
    validateSurname();
    validateEmail();

    if (Object.values(formErrors).some((error) => error !== "")) {
      toast.error("Please fill in all required fields correctly.");
      return;
    }

    setIsSending(true);

    const data = {
      firstName,
      email,
      message,
      surname,
      phone,
      id,
      address,
      position,
      companyName,
      productService,
      propertyType,
      isChecked,
    };

    try {
      const response = await axios.post("/advertise", data);

      if (response.status === 200) {
        // Form submission successful
        toast.success(
          "We have recieved your request. We will get in touch with you soon."
        );
        setFirstName("");
        setEmail("");
        setMessage("");
        setSurname("");
        setPhone("");
        setId("");
        setAddress("");
        setPosition("");
        setCompanyName("");
        seProductService("");
        setPropertyType("");
        setIsChecked("");
      } else {
        // Form submission failed
        toast.error("An error occurred. Please try again later.");
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred. Please try again later.");
    }

    setIsSending(false);
  };

  return (
    <section>
      <div className="mx-auto text-left md:max-w-lg mt-16 lg:max-w-2xl md:text-center">
        <h2 className="sm:p-2 text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl lg:leading-tight">
          <span className="relative inline-block">
            <span className="absolute inline-block w-full h-2 bg-lime-300 bottom-1.5"></span>
            <span className="relative text-amber-600"> Advertise with us</span>
          </span>
        </h2>
      </div>
      <div className="text-center sm:p-2 max-w-5xl mx-auto text-gray-500 mt-8">
        if you would like to advertise your service, property, retail or
        restaurant, please don't hesitate to contact us using the form below.
        Our friendly and knowledgeable team is always here to help and will get
        back to you as soon as possible.
      </div>
      <form onSubmit={handleSubmit} className="mt-6 mb-16 max-w-5xl mx-auto">
        <div className="flex gap-5">
          <div className=" w-full mt-3">
            <label className=" text-sm text-gray-600 dark:text-gray-200">
              First name <span className=" text-red-600">*</span>
            </label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              onChange={handleInputChange}
              value={firstName}
              placeholder="John"
              className="block w-full px-5 text-sm py-3 mt-1 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-amber-400 dark:focus:border-amber-400 focus:ring-amber-400 focus:outline-none"
            />
          </div>

          <div className=" w-full mt-3">
            <label className=" text-sm text-gray-600 dark:text-gray-200">
              Surname <span className=" text-red-600">*</span>
            </label>
            <input
              type="text"
              id="surname"
              name="surname"
              onChange={handleInputChange}
              value={surname}
              placeholder="Doe"
              className="block w-full px-5 text-sm py-3 mt-1 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-amber-400 dark:focus:border-amber-400 focus:ring-amber-400 focus:outline-none"
            />
          </div>
        </div>

        <div className="flex gap-5">
          <div className=" w-full mt-3">
            <label className=" text-sm text-gray-600 dark:text-gray-200">
              Email <span className=" text-red-600">*</span>
            </label>
            <input
              type="email"
              id="email"
              name="email"
              onChange={handleInputChange}
              value={email}
              placeholder="Email"
              className="block w-full px-5 text-sm py-3 mt-1 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-amber-400 dark:focus:border-amber-400 focus:ring-amber-400 focus:outline-none"
            />
          </div>

          <div className=" w-full mt-3">
            <label className=" text-sm text-gray-600 dark:text-gray-200">
              Phone number
            </label>
            <input
              type="text"
              id="phone"
              name="phone"
              onChange={handleInputChange}
              value={phone}
              placeholder="Phone"
              className="block w-full px-5 text-sm py-3 mt-1 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-amber-400 dark:focus:border-amber-400 focus:ring-amber-400 focus:outline-none"
            />
          </div>
        </div>

        <div className="flex gap-5">
          <div className=" w-full mt-3">
            <label className=" text-sm text-gray-600 dark:text-gray-200">
              ID number
            </label>
            <input
              type="text"
              id="id"
              name="id"
              onChange={handleInputChange}
              value={id}
              placeholder="ID/Passport"
              className="block w-full px-5 text-sm py-3 mt-1 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-amber-400 dark:focus:border-amber-400 focus:ring-amber-400 focus:outline-none"
            />
          </div>

          <div className=" w-full mt-3">
            <label className=" text-sm text-gray-600 dark:text-gray-200">
              Address
            </label>
            <input
              type="text"
              id="address"
              name="address"
              onChange={handleInputChange}
              value={address}
              placeholder="address"
              className="block w-full px-5 text-sm py-3 mt-1 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-amber-400 dark:focus:border-amber-400 focus:ring-amber-400 focus:outline-none"
            />
          </div>
        </div>
        <div className="flex gap-5">
          <div className=" w-full mt-3">
            <label className=" text-sm text-gray-600 dark:text-gray-200">
              Company name
            </label>
            <input
              type="text"
              id="companyName"
              name="companyName"
              onChange={handleInputChange}
              value={companyName}
              placeholder="Company"
              className="block w-full px-5 text-sm py-3 mt-1 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-amber-400 dark:focus:border-amber-400 focus:ring-amber-400 focus:outline-none"
            />
          </div>

          <div className=" w-full mt-3">
            <label className=" text-sm text-gray-600 dark:text-gray-200">
              Your position
            </label>
            <input
              type="text"
              id="position"
              name="position"
              onChange={handleInputChange}
              value={position}
              placeholder="Position"
              className="block w-full px-5 text-sm py-3 mt-1 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-amber-400 dark:focus:border-amber-400 focus:ring-amber-400 focus:outline-none"
            />
          </div>
        </div>

        <div className="flex gap-5">
          <div className=" w-full mt-3">
            <label className=" text-sm text-gray-600 dark:text-gray-200">
              Product or Service
            </label>
            <input
              type="text"
              id="productService"
              name="productService"
              onChange={handleInputChange}
              value={productService}
              placeholder="Product/Service"
              className="block w-full px-5 text-sm py-3 mt-1 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-amber-400 dark:focus:border-amber-400 focus:ring-amber-400 focus:outline-none"
            />
          </div>

          <div className=" w-full mt-3">
            <label className=" text-sm text-gray-600 dark:text-gray-200">
              Property Type
            </label>
            <input
              type="text"
              id="propertyType"
              name="propertyType"
              onChange={handleInputChange}
              value={propertyType}
              placeholder="Property"
              className="block w-full px-5 text-sm py-3 mt-1 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-amber-400 dark:focus:border-amber-400 focus:ring-amber-400 focus:outline-none"
            />
          </div>
        </div>

        <div className="w-full mt-6">
          <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">
            Additional information
          </label>
          <textarea
            id="message"
            name="message"
            onChange={handleInputChange}
            value={message}
            className=" w-full px-5 text-sm py-3 mt-1 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-amber-400 dark:focus:border-amber-400 focus:ring-amber-400 focus:outline-none"
            placeholder="Message"
          ></textarea>
        </div>
        <div className=" mx-auto mt-8 max-w-4xl">
          <div className="flex ">
            <input
              type="checkbox"
              name="agree"
              id="agree"
              value={agreed}
              checked={isChecked}
              onChange={handleCheckboxChange}
              className="w-3 h-3 mt-1 text-green-500 bg-white border-gray-200 rounded"
            />

            <label for="agree" className="ml-3 text-sm text-gray-500">
              I understand that Hoji Incorporated (Pvt)Ltd as the provider of
              the Hoji Inc App Platform, Hoji Inc does not own, control, offer
              or manage any Listings or Host Services. Hoji Inc is not a party
              to the contracts entered into directly between Hosts/Property
              Owners/Service Providers/Retailers and Guests/Clients/Prospective
              Clients/Other Businesses, nor is Hoji Inc a real estate broker,
              travel agency, or insurer. Hoji Inc is not acting as an agent in
              any capacity for any Member.
            </label>
          </div>
          <label for="agree" className="ml-3 text-sm  text-gray-500">
            <div className="flex">
              <input
                type="checkbox"
                name="agreed"
                id="agreed"
                value={agreed}
                checked={isChecked}
                onChange={handleCheckboxChange}
                className="w-3 h-3 mt-1 text-green-500 bg-white border-gray-200 rounded"
              />

              <label for="agree" className="ml-3 text-sm  text-gray-500">
                In filling out and signing this form you agree that you have
                read and acknowledged the terms and conditions of the disclaimer
                and are a legal representative of the company, business,
                property or service in question i.e. owner, manager, real estate
                agent, legally authorized representative, and that the provided
                information is true and accurate.
              </label>
            </div>
          </label>

          <div className="mt-12 text-center">
            <a
              href="/adsdisclaimer"
              title=""
              className="text-lime-600  hover:text-lime-600 hover:underline"
            >
              <span className="text-sm font-bold mx-auto text-gray-700">
                Read the full
              </span>{" "}
              advertisement disclaimer here
            </a>
          </div>
        </div>

        <button
          type="submit"
          className={`px-4 py-2 mt-8 text-white rounded-md hover:bg-lime-700 ${
            isSending ? "cursor-not-allowed bg-gray-300" : "bg-lime-600"
          }`}
          disabled={isSending || !isChecked}
        >
          {isSending ? (
            <ImSpinner2 className="animate-spin text-2xl justify-center mx-auto text-white" />
          ) : (
            "Send"
          )}
        </button>
      </form>
    </section>
  );
}
