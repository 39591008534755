import React, { useState } from "react";
import { motion } from "framer-motion";
import Pot from "../assets/img/icons/les-07.svg";
import Bakery from "../assets/img/icons/les-06.svg";
import Steak from "../assets/img/icons/les-08.svg";
import Fine from "../assets/img/icons/les-04.svg";
import All from "../assets/img/icons/all.svg";
import Fastfood from "../assets/img/icons/les-01.svg";

export default function RestaurantCategories({ onCategorySelect }) {
  const [selectedCategory, setSelectedCategory] = useState("");

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    onCategorySelect(category);
    if (category === "All") {
      onCategorySelect(""); // Clear the selected category
    }
  };

  const categoryVariants = {
    hover: {
      scale: 1.05,
      transition: { duration: 0.3 },
    },
    tap: {
      scale: 0.9,
      transition: { duration: 0.3 },
    },
  };

  const categories = [
    {
      name: "All",
      color: "orange",
      icon: All,
    },
    {
      name: "Fine Dining",
      color: "orange",
      icon: Fine,
    },
    {
      name: "Traditional Food",
      color: "orange",
      icon: Pot,
    },
    {
      name: "Steakhouses",
      color: "orange",
      icon: Steak,
    },
    {
      name: "Fast Food",
      color: "orange",
      icon: Fastfood,
    },

    {
      name: "Bakeries",
      color: "orange",
      icon: Bakery,
    },
  ];

  return (
    <div className="grid mx-auto max-w-4xl lg:grid-cols-6 sm:px-2 mb-6 sm:mx-auto ms:grid-cols-1 sm:grid sm:grid-cols-2 grid-cols-2 md:grid-cols-6 p-4 gap-4 flex-wrap w-full px-2 justify-center">
      {categories.map((category, index) => (
        <motion.div
          key={index}
          className={`flex flex-col items-center justify-center bg-amber-50 rounded-md p-2 cursor-pointer`}
          variants={categoryVariants}
          whileHover="hover"
          whileTap="tap"
          onClick={() => handleCategorySelect(category.name)}
        >
          <div>
            <img
              className={`text-${category.color}-900 w-24 h-24 m-2`}
              src={category.icon}
              alt="Icon"
              style={{
                filter:
                  selectedCategory === category.name
                    ? "brightness(1.5)"
                    : "brightness(1)",
              }}
            />
          </div>
          <div className="">
            <p
              className={`text-center font-bold text-${
                category.color
              }-900 text-sm ${
                selectedCategory === category.name ? "underline" : ""
              }`}
            >
              {category.name}
            </p>
          </div>
        </motion.div>
      ))}
    </div>
  );
}
