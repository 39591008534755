import React, { useContext, useState } from "react";
import { UserContext } from "../UserContext";
import { Link, Navigate, useParams } from "react-router-dom";
import axios from "axios";
import PropertiesPage from "./PropertiesPage";

import AccountNav from "../AccountNav";
import { ImSpinner, ImSpinner2 } from "react-icons/im";
import MyServices from "./MyServices";
import { HiOutlineLogout } from "react-icons/hi";

export default function AccountPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [redirect, setRedirect] = useState(null);
  const { ready, user, setUser } = useContext(UserContext);
  let { subpage } = useParams();
  if (subpage === undefined) subpage = "account";
  console.log(subpage);

  async function logout() {
    await axios.post("/logout");
    setRedirect("/"); // redirect to home page
    setUser(null); // clear user context
  }

  if (!ready) {
    return (
      <ImSpinner2
        className="animate-spin text-2xl mb-40 text-lime-600 mx-auto
      text-2x1 mt-[200px]"
      />
    );
  }

  if (ready && !user && !redirect) {
    return <Navigate to="/SignIn" />;
  }

  if (redirect) {
    return <Navigate to={redirect} />;
  }

  // text-lime-600 font-medium

  return (
    <section className="min-h-screen  items-center justify-center ">
      <div>
        <AccountNav />
        {subpage === "account" && (
          <div className=" max-w-5xl lg:max-w-5xl md:max-w-3xl ms mx-auto ">
            <div className=" pb-2 border-b-2">
              <div className="flex mb-4 justify-between  items-center">
                <h1 className="  text-2xl font-medium">My details</h1>
                <button
                  onClick={logout}
                  className=" items-center gap-2 font-medium text-sm text-red-600 bg-white flex w-24 "
                >
                  Logout
                  <HiOutlineLogout className=" h-4 w-4" />
                </button>
              </div>
              <h3 className=" font-medium">Personal information</h3>
            </div>
            <div className=" flex mt-2 gap-6">
              <p className=" max-w-sm text-sm text-gray-500">
                You can edit your information, except for the email. if you
                would like to change your email you can submit a request on the
                "About" page and we would be happy to assist.
              </p>
              <div>
                <p className=" font-medium text-gray-600 text-sm">Email</p>
                <input
                  type="email"
                  placeholder={user?.email}
                  className="block mb-4 px-5 text-sm py-3 mt-1 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-amber-400 dark:focus:border-amber-400 focus:ring-amber-400 focus:outline-none"
                />
                <p className=" font-medium text-gray-600 text-sm">User name</p>
                <input
                  type="userName"
                  placeholder={user?.name}
                  className="block px-5 mb-4 text-sm py-3 mt-1 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-amber-400 dark:focus:border-amber-400 focus:ring-amber-400 focus:outline-none"
                />
                <div className="flex gap-4">
                  <div>
                    <p className=" font-medium text-gray-600 text-sm">
                      Old password
                    </p>

                    <input
                      type="oldPassword"
                      placeholder="Old password"
                      className="block w-full px-5 text-sm py-3 mt-1 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-amber-400 dark:focus:border-amber-400 focus:ring-amber-400 focus:outline-none"
                    />
                  </div>

                  <div>
                    <p className=" font-medium text-gray-600 text-sm">
                      New password
                    </p>
                    <div>
                      <input
                        type="newPassword"
                        placeholder="New password"
                        className="block w-full mb-4 px-5 text-sm py-3 mt-1 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-amber-400 dark:focus:border-amber-400 focus:ring-amber-400 focus:outline-none"
                      />
                      <p className=" font-medium text-gray-600 text-sm">
                        Confirm new password
                      </p>
                      <input
                        type="confirmNewPassword"
                        placeholder="confirm new password"
                        className="block w-full px-5 text-sm py-3 mt-1 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-amber-400 dark:focus:border-amber-400 focus:ring-amber-400 focus:outline-none"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex mt-8 justify-end">
              <button className="w-28 bg-lime-600 text-white py-1 rounded-lg">
                Save
              </button>
            </div>
          </div>
        )}
        {subpage === "listings" && <PropertiesPage />}
        {subpage === "service" && <MyServices />}
      </div>
    </section>
  );
}
