export default function ListingsOptions({ selected, onChange }) {
  function handleCbClick(ev) {
    const { name, checked } = ev.target;
    // if (typeof onChange === "function")
    if (checked) {
      onChange([...selected, name]);
    } else {
      onChange([...selected.filter((selectedName) => selectedName !== name)]);
    }
  }

  return (
    <>
      <div className="flex gap-2 text-gray-600">
        <label className="border p-4 flex rounded-2xl gap-2 items-center cursor-pointer">
          <input
            checked={selected.includes("/month")}
            type="checkbox"
            name="/month"
            onChange={handleCbClick}
          />
          Rentals
        </label>
        <label className="border p-4 flex rounded-2xl gap-2 items-center cursor-pointer">
          <input
            checked={selected.includes("/night")}
            type="checkbox"
            name="/night"
            onChange={handleCbClick}
          />
          Short-Stay
        </label>
        <label className=" border p-4 flex rounded-2xl gap-2 items-center cursor-pointer">
          <input
            checked={selected.includes("For Sale")}
            type="checkbox"
            name="For Sale"
            onChange={handleCbClick}
          />
          For Sale
        </label>
      </div>
    </>
  );
}
