import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { BsWhatsapp } from "react-icons/bs";
import Image from "./Image";
import { HiOutlineGlobe, HiOutlineMail } from "react-icons/hi";
import { Link, useParams } from "react-router-dom";

export default function RestaurantContactForm() {
  const { id } = useParams();
  const [restaurant, setRestaurant] = useState(null);

  useEffect(() => {
    if (!id) {
      return;
    }
    axios.get("/restaurants-listings/" + id).then((res) => {
      setRestaurant(res.data);
    });
  }, [id]);

  if (!restaurant) return "";

  return (
    <section>
      <div className=" max-w-md border p-3 rounded-2xl border-gray-100">
        <div>
          <div className=" mb-3">
            <div className="relative">
              <div className=" inset-0 rounded-2xl overflow-hidden">
                {restaurant.photos?.[0] && (
                  <Image
                    className="img w-full h-52  object-cover transform transition duration-200 ease-in-out hover:scale-120"
                    src={restaurant.photos?.[0]}
                    alt=""
                  />
                )}
              </div>
            </div>
          </div>
          <div className="inline-block  bg-red-100 font-bold text-red-700 rounded-full text-[12px] px-4 py-1">
            {restaurant.category}
          </div>
          <div className=" py-3 flex justify-between items-center">
            <div>
              <a
                href={restaurant.website}
                className="flex items-center gap-1 text-sky-600 font-bold text-lg hover:text-lime-600 hover:underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                <HiOutlineGlobe className="text-lime-600 " />{" "}
                <div>
                  <p className="text-lime-600 text-sm font-semibold ">
                    Website
                  </p>
                </div>
              </a>{" "}
            </div>
          </div>{" "}
          <p className="text-sm px-2 mb-3 text-gray-500">
            Connect with the restaurant by email, calling or messaging on
            WhatsApp.
          </p>
          <div className="flex flex-col gap-y-4">
            <button
              className="bg-blue-50 p-1 w-24 justify-center text-sm rounded-full flex items-center gap-2"
              onClick={(e) => {
                e.preventDefault();

                const name = document.getElementById("name-input").value;
                const message = document.getElementById("message-input").value;

                const subject = `I am Interested in this ${restaurant.ti}`;
                let body = message;

                if (message) {
                  body = `I am Interested in this ${restaurant.title} my name is ${name}`;
                }

                if (name) {
                  body = `My name is ${name}.\n\n${body}`;
                }

                window.location.href = `mailto:${
                  restaurant.email
                }?subject=${subject}&body=${encodeURIComponent(body)}`;
              }}
            >
              <HiOutlineMail className="text-lime-600 w-5 h-5" />
              <p className="text-lime-600 font-medium">Email</p>
            </button>

            <div className="flex gap-x-2">
              <button
                className="  border-lime-600 bg-white text-center hover:border-lime-600 border-2 text-lime-600 font-bold rounded-full px-4 py-2 h-12  justify-center text-sm w-full transition"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = `tel:${restaurant.call}`;
                }}
              >
                Call
              </button>

              <button
                className="bg-green-100 rounded-full w-[50%] flex text-center justify-center p-2"
                onClick={(e) => {
                  e.preventDefault();
                  const defaultMessage = `Hello, I am interested in this restaurant ${restaurant.title}.\n\nCheck out more listings at Hoji Inc. Search for services, properties, restaurants or retailers.\n\nTo advertise with Hojiinc visit www.hojiinc.co.zw today!`;
                  const url = `https://wa.me/${
                    restaurant.call
                  }?text=${encodeURIComponent(defaultMessage)}`;

                  const link = document.createElement("a");
                  link.href = url;
                  link.target = "_blank";
                  link.click();
                }}
              >
                <BsWhatsapp className="justify-center text-green-600 w-7 h-7 mt-0.5" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
