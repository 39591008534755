import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ImSpinner2 } from "react-icons/im";
import Image from "./Image";
import { BsLightningCharge } from "react-icons/bs";

const selectedIds = [
  "6482f7101cee3641304884e1",
  "648a379feb0328aaeab47fb6",
  "6483255cc5bca10bb70e728b",
  "6479c0cff62e18032efc00a4",
];

export default function ServicesSlider() {
  const [services, setServices] = useState([]);

  useEffect(() => {
    axios.get("/service-listing").then((res) => {
      const filteredServices = res.data.filter((service) =>
        selectedIds.includes(service._id)
      );
      setServices(filteredServices);
    });
  }, []);

  return (
    <div className="max-w-6xl mt-8 mx-auto">
      <div className=" max-w-md px-8">
        <h1 className=" text-xl font-bold">Featured Services</h1>
        <p className=" text-gray-500 font-light">
          Discover our curated selection of featured listings.
        </p>
      </div>

      {services.length > 0 ? (
        <div className="grid   lg:grid-cols-4 ms:grid-cols-2 sm:grid-cols-1 grid-cols-2 md:grid-cols-4 gap-4 flex-wrap sm:px-4 px-16 justify-center">
          {services.map((service) => (
            <Link to={"/service/" + service._id} key={service._id}>
              <div className="max-w-xl mb-8  sm:flex-col cursor-pointer md:flex-col p-2 transition rounded-xl bg-white flex lg:flex-col">
                <div className="">
                  <div className="rounded-xl sm:mb-4 lg:w-54 lg:h-48 lg:m-2 overflow-hidden">
                    <div className="absolute flex gap-1 items-center text-white font-normal text-xs bg-lime-100 m-2 py-1 px-4 rounded-xl bg-opacity-50">
                      <BsLightningCharge />
                      Sponsored
                    </div>

                    {service.photos?.[0] && (
                      <Image
                        className="img sm:w-full w-54 h-48 object-cover"
                        src={service.photos?.[0]}
                        alt=""
                      />
                    )}
                  </div>
                </div>
                <div className=" px-3 w-full sm:w-auto lg:w-full">
                  <div>
                    <div className="flex gap-8 text-left items-center justify-between">
                      <div className="">
                        <h2 className="font-bold lg:text-xl md:text-lg text-xl ">
                          {service.title}
                        </h2>
                        <h2 className="text-sm font-medium text-gray-600 mb-2">
                          {service.location}
                        </h2>
                        <h2 className="text-sm max-w-xs hidden text-lime-600 mb-4">
                          {service.address}
                        </h2>
                      </div>
                    </div>
                    <div className="text-left">
                      <div className="inline-block border border-lime-700 font-bold text-lime-700 rounded-full text-[12px] px-4 py-1 mb-2">
                        {service.category}
                      </div>
                      <h2 className="text-sm font-medium mt-2 hover hidden text-sky-500">
                        {service.website}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      ) : (
        <ImSpinner2 className="animate-spin text-2xl justify-center text-lime-600 mx-auto text-2x1" />
      )}
    </div>
  );
}
