import React, { useState } from "react";

export default function Faq() {
  const [accordions, setAccordions] = useState([false, false, false, false]);

  const toggleAccordion = (index) => {
    const newAccordions = [...accordions];
    newAccordions[index] = !newAccordions[index];
    setAccordions(newAccordions);
  };

  return (
    <section className="py-10 bg-white sm:py-16 lg:py-24">
      <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="max-w-2xl mx-auto text-center">
          <h2 className="text-3xl font-bold leading-tight text-amber-600 sm:text-4xl lg:text-5xl">
            Frequently <span className=" text-lime-600">Asked</span> Questions
          </h2>
        </div>

        <div className="max-w-3xl mx-auto mt-8 space-y-4 md:mt-16">
          <div className="transition-all rounded-lg duration-200 bg-white border border-gray-200 shadow-lg shadow-slate-50 cursor-pointer hover:bg-amber-50">
            <button
              type="button"
              className="flex items-center justify-between w-full px-4 py-5 sm:p-6"
              onClick={() => toggleAccordion(0)}
            >
              <span className="flex text-lg font-semibold text-black">
                What is Hoji Inc?
              </span>

              <svg
                className={`w-6 h-6 text-gray-400 ${
                  accordions[0] ? "rotate-180" : ""
                }`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </button>

            {accordions[0] && (
              <div className="px-4 pb-5 sm:px-6 sm:pb-6">
                <p>
                  Hoji Inc is Zimbabwe's newest marketing and listing platform,
                  connecting consumers with the finest business offerings across
                  the country. Our user-friendly platform aims to provide a
                  convenient way for individuals and businesses of all sizes to
                  showcase their products, services, properties, and more.
                </p>
              </div>
            )}
          </div>

          <div className="transition-all rounded-lg duration-200 bg-white border border-gray-200 cursor-pointer hover:bg-amber-50 shadow-lg shadow-slate-50">
            <button
              type="button"
              className="flex items-center justify-between w-full px-4 py-5 sm:p-6"
              onClick={() => toggleAccordion(1)}
            >
              <span className="flex text-lg font-semibold text-black">
                How can I list my business on Hoji Inc?
              </span>
              <svg
                className={`w-6 h-6 text-gray-400 ${
                  accordions[1] ? "rotate-180" : ""
                }`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </button>

            {accordions[1] && (
              <div className="px-4 pb-5 sm:px-6 sm:pb-6">
                <p>
                  It's easy! Simply follow the "Advertise With Us" link located
                  at the bottom of our website, under the Resources
                  column.Provide your company details, contact information,
                  product images, and a description of your offerings. Our team
                  will create your profile, and you can request updates as
                  needed.
                </p>
              </div>
            )}
          </div>

          <div className="transition-all rounded-lg duration-200 bg-white border border-gray-200 cursor-pointer hover:bg-amber-50 shadow-lg shadow-slate-50">
            <div>
              <button
                type="button"
                className="flex items-center justify-between w-full px-4 py-5 sm:p-6"
                onClick={() => toggleAccordion(2)}
              >
                <span className="flex text-lg font-semibold text-black">
                  What are the benefits of advertising on Hoji Inc?
                </span>

                <svg
                  className={`w-6 h-6 text-gray-400 ${
                    accordions[2] ? "rotate-180" : ""
                  }`}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>

              {accordions[2] && (
                <div className="px-4 pb-5 sm:px-6 sm:pb-6">
                  <p>
                    By advertising on Hoji Inc, you gain exposure to a wide
                    audience, including the opportunity to be featured on our
                    platform and social media channels.
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className="transition-all rounded-lg duration-200 bg-white border border-gray-200 cursor-pointer hover:bg-amber-50 shadow-lg shadow-slate-50">
            <button
              type="button"
              className="flex items-center justify-between w-full px-4 py-5 sm:p-6"
              onClick={() => toggleAccordion(3)}
            >
              <span className="flex text-lg font-semibold text-black">
                What services does Hoji Inc offer?
              </span>

              <svg
                className={`w-6 h-6 text-gray-400 ${
                  accordions[3] ? "rotate-180" : ""
                }`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </button>
            {accordions[3] && (
              <div className="px-4 pb-5 sm:px-6 sm:pb-6">
                <p>
                  Hoji Inc offers businesses the opportunity to list their
                  offerings on our platform, making it easier for consumers to
                  discover them. We provide various listing packages tailored to
                  different needs, ranging from free to premium options.
                </p>
              </div>
            )}
          </div>
          <div className="transition-all rounded-lg duration-200 bg-white border border-gray-200 cursor-pointer hover:bg-amber-50 shadow-lg shadow-slate-50">
            <button
              type="button"
              className="flex items-center justify-between w-full px-4 py-5 sm:p-6"
              onClick={() => toggleAccordion(3)}
            >
              <span className="flex text-lg font-semibold text-black">
                How do I edit my listing information?
              </span>

              <svg
                className={`w-6 h-6 text-gray-400 ${
                  accordions[3] ? "rotate-180" : ""
                }`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </button>
            {accordions[3] && (
              <div className="px-4 pb-5 sm:px-6 sm:pb-6">
                <p>
                  To edit your listing, simply contact us with the requested
                  changes, and our team will update your profile promptly.
                </p>
              </div>
            )}
          </div>
        </div>

        <p className="text-center text-gray-600 max-w-3xl mx-auto textbase mt-9">
          If you have any further questions or require assistance, please don't
          hesitate to reach out to our support team through the contact
          information provided on our website. We look forward to helping you
          promote your business effectively on HojiInc.co.zw!
        </p>
      </div>
    </section>
  );
}
