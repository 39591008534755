import RetailerFilter from "../components/RetailerFilter";
import RetailAdsBanner from "../components/RetailAdsBanner";

export default function RetailersPage() {
  return (
    <section>
      <div>
        <RetailAdsBanner />
      </div>
      <div class="pt-4 bg-white sm:pt-8 lg:pt-8">
        <div class="max-w-5xl px-4 mx-auto sm:px-6 lg:px-8">
          <div className=" mx-auto text-center max-w-2xl">
            <h2 class="text-3xl font-bold leading-tight text-lime-600 sm:text-4xl lg:text-5xl">
              Discover Hidden
              <span className=" text-amber-600"> Treasures</span>
            </h2>
            <p class="mt-4 text-base  leading-relaxed text-gray-600">
              Unleash your shopping desires and uncover unique finds
            </p>
          </div>
        </div>
      </div>

      <div>
        <RetailerFilter />
      </div>
    </section>
  );
}
