import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  HiOutlineLocationMarker,
  HiX,
  HiChevronDoubleLeft,
  HiChevronDoubleRight,
} from "react-icons/hi";
import RetailerContactForm from "../components/RetailerContactForm";
import Image from "../components/Image";
import { BiImage } from "react-icons/bi";

export default function RetailerDetails() {
  const { id } = useParams();
  const [retailer, setRetailer] = useState(null);
  const [showAllPhotos, setShowAllPhotos] = useState(false);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);

  useEffect(() => {
    if (!id) {
      return;
    }
    axios.get("/retailers-listings/" + id).then((res) => {
      setRetailer(res.data);
      window.scrollTo(0, 0);
    });
  }, [id]);

  if (!retailer) return "";

  if (showAllPhotos) {
    const handleNextPhoto = () => {
      setCurrentPhotoIndex((prevIndex) =>
        prevIndex === retailer.photos.length - 1 ? 0 : prevIndex + 1
      );
    };

    const handlePreviousPhoto = () => {
      setCurrentPhotoIndex((prevIndex) =>
        prevIndex === 0 ? retailer.photos.length - 1 : prevIndex - 1
      );
    };

    return (
      <div className="photo-modal mb-16 mt-16">
        <div className="modal-content">
          <h2 className="text-3xl mb-4">Photos of {retailer.title}</h2>
          <div className="close-button mt-16">
            <button
              onClick={() => setShowAllPhotos(false)}
              className="flex w-auto bg-lime-600 text-white items-center py-2 px-4 rounded-full"
            >
              <HiX />
              Close photos
            </button>
          </div>
          <div className="photo-grid h-[550px] relative">
            {retailer?.photos?.length > 0 && (
              <>
                <button
                  className="absolute text-white left-0 top-1/2 transform -translate-y-1/2 bg-lime-500 px-2 w-8 py-1 rounded-full"
                  onClick={handlePreviousPhoto}
                >
                  <HiChevronDoubleLeft />
                </button>
                <div className="image-container">
                  <img
                    className="mx-auto max-h-[750px] h-full object-contain"
                    src={retailer.photos[currentPhotoIndex]}
                    alt=""
                  />
                </div>
                <button
                  className="absolute text-white right-0 top-1/2 transform -translate-y-1/2 bg-lime-500 w-8 px-2 py-1 rounded-full"
                  onClick={handleNextPhoto}
                >
                  <HiChevronDoubleRight />
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <section className="mt-16 h-full   max-w-7xl mx-auto lg:max-w-6xl md:max-w-5xl  mb-28">
      <div className="">
        {/* Text Details */}
        <div className="flex mx-auto px-4  xl:flex lg:flex  md:grid md:grid-cols-1 sm:grid sm:grid-cols-1 max-w-6xl items-center gap-4 mb-2">
          {/* Title */}
          <div className="flex items-center  max-w-3xl">
            <h1 className="text-2xl font-bold">{retailer.title}</h1>
          </div>

          {/* Address and Location */}
          <div className="flex gap-1   ">
            <HiOutlineLocationMarker className="text-gray-600" />
            <p className=" text-black text-sm font-medium">
              {retailer.address}
            </p>
            <h3 className="text-sm text-lime-600 font-medium ">
              {retailer.location}
            </h3>
          </div>
        </div>
        <div className="flex justify-between mx-auto  gap-6 max-w-6xl">
          <div className=" grid-flow-col ">
            <div className="relative border mx-auto flex-grow sm:flex-grow md:flex-grow-0 lg:flex-grow xl:flex-grow-0  p-2 rounded-2xl border-gray-100">
              <div className="grid grid-cols-3 gap-2 text-gray-600">
                <div className="col-span-3 text-gray-600">
                  {retailer.photos && retailer.photos.length > 0 ? (
                    <div className="grid grid-cols-3 gap-2 mt-2 text-gray-500">
                      {retailer.photos.slice(1, 7).map((photo, index) => (
                        <div key={index}>
                          {photo && (
                            <Image
                              className="w-full h-56 lg:h-56 md:h-56 sm:h-28  object-cover rounded-lg transform transition duration-200 ease-in-out hover:scale-105"
                              src={photo}
                              alt=""
                            />
                          )}
                          <button
                            onClick={() => setShowAllPhotos(true)}
                            className="flex gap-1 absolute font-bold text-xs bg-lime-600 px-4 text-white rounded-2xl py-2 bottom-2 w-auto right-2"
                          >
                            <BiImage className="w-4 h-4" />
                            Show more
                          </button>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="flex items-center justify-center h-24">
                      <p className="text-gray-400 text-center">No Images</p>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <h2 className="mb-1 mt-4 font-bold  text-md">Description</h2>
            <p className=" mb-6 text-gray-600 text-sm max-w-3xl">
              {retailer.description}
            </p>
          </div>

          <div className="sm:hidden  md:hidden lg:block xl:block flex-grow">
            <RetailerContactForm />
          </div>
        </div>

        {/* Contact Form (Responsive) */}

        <div className="lg:hidden md:hidden sm:mt-4 ">
          <RetailerContactForm />
        </div>
      </div>
    </section>
  );
}
