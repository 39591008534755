import { HiChevronDown } from "react-icons/hi";
import AccountNav from "../AccountNav";
import { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import axios from "axios";
import PhotosUploader from "../components/PhotosUploader";

export default function NewRetailerForm() {
  const { id } = useParams();
  const [priceRange, setPriceRange] = useState({ minPrice: 0, maxPrice: 0 });
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [call, setCall] = useState("");
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");
  const [address, setAddress] = useState("");
  const [website, setWebsite] = useState("");
  const [email, setEmail] = useState("");
  const [addedPhotos, setaddedPhotos] = useState([]);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if (!id) {
      return;
    }
    axios.get("/retailers-listings/" + id).then((res) => {
      const { data } = res;
      setTitle(data.title);
      setCategory(data.category);
      setDescription(data.description);
      setPriceRange(data.priceRange);
      setAddress(data.address);
      setaddedPhotos(data.photos);
      setCall(data.call);
      setEmail(data.email);
      setLocation(data.location);
      setWebsite(data.website);
    });
  }, [id]);

  function inputHeader(text) {
    return <h2 className="text-2xl mt-4">{text}</h2>;
  }

  function inputDescription(text) {
    return <p className="text-sm text-gray-500">{text}</p>;
  }

  function preInput(Header, description) {
    return (
      <>
        {inputHeader(Header)}
        {inputDescription(description)}
      </>
    );
  }

  async function saveRetailer(ev) {
    ev.preventDefault();
    const formattedPriceRange = `${priceRange.minPrice}-${priceRange.maxPrice}`;
    const retailerData = {
      title,
      category,
      call,
      description,
      location,
      address,
      website,
      priceRange: formattedPriceRange,
      email,
      addedPhotos,
    };
    try {
      if (id) {
        //update
        await axios.put("/retailers-listings", {
          id,
          ...retailerData,
        });
        setRedirect(true);
      } else {
        //create
        await axios.post("/retailers", retailerData);
        setRedirect(true);
      }
    } catch (err) {
      console.error(err);
      setRedirect(true);
    }
  }

  if (redirect) {
    return <Navigate to="/account/retailers" />;
  }

  return (
    <div className="container mx-auto mb-8">
      <AccountNav />
      <form onSubmit={saveRetailer}>
        <div className="">
          {preInput("Category", "What type of retailer is it?")}
          <div className="relative w-full mb-4">
            <select
              className="form-input"
              value={category}
              onChange={(ev) => setCategory(ev.target.value)}
            >
              <option value="">Select a category</option>
              <option value="Supermarkets">Supermarkets</option>
              <option value="Convenience Stores">Convenience Stores</option>
              <option value="Fresh Markets">Fresh Markets</option>
              <option value="Shopping Malls">Shopping Malls</option>
              <option value="Electronics Stores">Electronics Stores</option>
              <option value="Fashion and Apparel Stores">
                Fashion and Apparel Stores
              </option>
              <option value="Specialty Stores">Specialty Stores</option>
              <option value="Other">Other</option>
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <HiChevronDown />
            </div>
          </div>

          {preInput("Title", "Give your retailer a name")}
          <input
            className="form-input"
            type="text"
            value={title}
            onChange={(ev) => setTitle(ev.target.value)}
          />
          {preInput("Description", "Describe your retailer")}
          <textarea
            className="form-input"
            value={description}
            onChange={(ev) => setDescription(ev.target.value)}
          />
          {preInput("Location", "Where is your retailer located?")}
          <input
            className="form-input"
            type="text"
            value={location}
            onChange={(ev) => setLocation(ev.target.value)}
          />
          {preInput("Address", "What is the address of your retailer?")}
          <input
            className="form-input"
            type="text"
            value={address}
            onChange={(ev) => setAddress(ev.target.value)}
          />
          {preInput("Website", "What is the website of your retailer?")}
          <input
            className="form-input"
            type="text"
            value={website}
            onChange={(ev) => setWebsite(ev.target.value)}
          />
          {preInput("Price", "What is the price of your retailer?")}
          <input
            className="form-input"
            type="text"
            value={`${priceRange.minPrice}-${priceRange.maxPrice}`}
            onChange={(ev) => {
              const [minPrice, maxPrice] = ev.target.value.split("-");
              setPriceRange({ minPrice, maxPrice });
            }}
          />
          {preInput(
            "Retailer Call",
            "What is the phone number of your retailer?"
          )}
          <input
            className="form-input"
            type="text"
            value={call}
            onChange={(ev) => setCall(ev.target.value)}
          />
          {preInput("Email", "What is the email of your retailer?")}
          <input
            className="form-input"
            type="text"
            value={email}
            onChange={(ev) => setEmail(ev.target.value)}
          />
          {preInput("Photos", "Add photos of your retailer")}
          <PhotosUploader addedPhotos={addedPhotos} onChange={setaddedPhotos} />

          <button
            className="bg-lime-600 mt-8 text-white p-2 rounded-lg"
            type="submit"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
}
