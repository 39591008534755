import { HiChevronDown } from "react-icons/hi";
import AccountNav from "../AccountNav";
import { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import axios from "axios";
import PhotosUploader from "../components/PhotosUploader";

export default function NewRestaurantPage() {
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [call, setCall] = useState("");
  const [priceRange, setPriceRange] = useState({ minPrice: 0, maxPrice: 0 });

  const [description, setDescription] = useState("");
  const [cuisines, setCuisines] = useState("");
  const [meals, setMeals] = useState("");
  const [operatingHours, setOperatingHours] = useState("");
  const [location, setLocation] = useState("");
  const [address, setAddress] = useState("");
  const [website, setWebsite] = useState("");
  const [capacity, setCapacity] = useState("");
  const [email, setEmail] = useState("");
  const [addedPhotos, setaddedPhotos] = useState([]);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if (!id) {
      return;
    }
    axios.get("/restaurants-listings/" + id).then((res) => {
      const { data } = res;
      setTitle(data.title);
      setCuisines(data.cuisines);
      setMeals(data.meals);
      setOperatingHours(data.operatingHours);
      setCategory(data.category);
      setPriceRange(data.priceRange);
      setDescription(data.description);
      setCapacity(data.capacity);
      setAddress(data.address);
      setaddedPhotos(data.photos);
      setCall(data.call);
      setEmail(data.email);
      setLocation(data.location);
      setWebsite(data.website);
    });
  }, [id]);

  function inputHeader(text) {
    return <h2 className="text-2xl mt-4">{text}</h2>;
  }

  function inputDescription(text) {
    return <p className="text-sm text-gray-500">{text}</p>;
  }

  function preInput(Header, description) {
    return (
      <>
        {inputHeader(Header)}
        {inputDescription(description)}
      </>
    );
  }

  async function saveRestaurant(ev) {
    ev.preventDefault();
    const formattedPriceRange = `${priceRange.minPrice}-${priceRange.maxPrice}`;
    const restaurantData = {
      title,
      category,
      call,
      cuisines,
      meals,
      operatingHours,
      description,
      location,
      address,
      website,
      priceRange: formattedPriceRange,
      capacity,
      email,
      addedPhotos,
    };
    try {
      if (id) {
        //update
        await axios.put("/restaurants-listings", {
          id,
          ...restaurantData,
        });
        setRedirect(true);
      } else {
        //create
        await axios.post("/restaurants", restaurantData);
        setRedirect(true);
      }
    } catch (err) {
      console.error(err);
      setRedirect(true);
    }
  }

  if (redirect) {
    return <Navigate to="/account/restaurants" />;
  }

  return (
    <div className="container mx-auto mb-8">
      <AccountNav />
      <form onSubmit={saveRestaurant}>
        <div className="">
          {preInput("Type", "What type of restaurant is it?")}
          <div className="relative w-full mb-4">
            <select
              className="form-input"
              value={category}
              onChange={(ev) => setCategory(ev.target.value)}
            >
              <option value="">Type of restaurant</option>
              <option value="Fine Dining">Fine Dining</option>
              <option value="Traditional Food">Traditional Food</option>
              <option value="Fast Food">Fast Food</option>
              <option value="Bakeries">Bakeries</option>
              <option value="Cafés and Coffee Shops">
                Cafés and Coffee Shops
              </option>
              <option value="Steakhouses">Steakhouses</option>
              <option value="Seafood Restaurants">Seafood Restaurants</option>
              <option value="Pizza Restaurants">Pizza Restaurants</option>
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <HiChevronDown />
            </div>
          </div>

          <div className="relative w-full mb-4">
            <select
              className="form-input"
              value={meals}
              onChange={(ev) => setMeals(ev.target.value)}
            >
              <option value="">Type of Meals</option>
              <option value="Breakfast">Breakfast</option>
              <option value="Family-style meals">Family-style meals</option>
              <option value="Takeaway with delivery">
                Takeaway with delivery
              </option>
              <option value="Takeaway">Takeaway</option>
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <HiChevronDown />
            </div>
          </div>

          {preInput("Title", "Give your restaurant a name")}
          <input
            className="form-input"
            type="text"
            value={title}
            onChange={(ev) => setTitle(ev.target.value)}
          />
          {preInput("Description", "Describe your restaurant")}
          <textarea
            className="form-input"
            value={description}
            onChange={(ev) => setDescription(ev.target.value)}
          />
          {preInput("Location", "Where is your restaurant located?")}
          <input
            className="form-input"
            type="text"
            value={location}
            onChange={(ev) => setLocation(ev.target.value)}
          />
          {preInput("Address", "What is the address of your restaurant?")}
          <input
            className="form-input"
            type="text"
            value={address}
            onChange={(ev) => setAddress(ev.target.value)}
          />
          {preInput("Website", "What is the website of your restaurant?")}
          <input
            className="form-input"
            type="text"
            value={website}
            onChange={(ev) => setWebsite(ev.target.value)}
          />
          {preInput(
            "Operating Hours",
            "What are the operating hours of your restaurant? *use this format 08:00-20:00"
          )}
          <input
            className="form-input"
            type="text"
            value={operatingHours}
            onChange={(ev) => setOperatingHours(ev.target.value)}
          />

          {preInput("Capacity", "What is the capacity of your restaurant?")}
          <input
            className="form-input"
            type="text"
            value={capacity}
            onChange={(ev) => setCapacity(ev.target.value)}
          />
          {preInput("Cuisines", "What are your restaurant's cuisines?")}
          <input
            className="form-input"
            type="text"
            value={cuisines}
            onChange={(ev) => setCuisines(ev.target.value)}
          />
          {preInput(
            "Price Range",
            "What is the price range of your restaurant?"
          )}
          <input
            className="form-input"
            type="text"
            value={`${priceRange.minPrice}-${priceRange.maxPrice}`}
            onChange={(ev) => {
              const [minPrice, maxPrice] = ev.target.value.split("-");
              setPriceRange({ minPrice, maxPrice });
            }}
          />

          {preInput(
            "Restaurant Call",
            "What is the phone number of your restaurant?"
          )}
          <input
            className="form-input"
            type="text"
            value={call}
            onChange={(ev) => setCall(ev.target.value)}
          />
          {preInput("Email", "What is the email of your restaurant?")}
          <input
            className="form-input"
            type="text"
            value={email}
            onChange={(ev) => setEmail(ev.target.value)}
          />
          {preInput("Photos", "Add photos of your restaurant")}
          <PhotosUploader addedPhotos={addedPhotos} onChange={setaddedPhotos} />

          <button
            className="bg-lime-600 mt-8 text-white p-2 rounded-lg"
            type="submit"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
}
