import axios from "axios";
import { useEffect, useState } from "react";
import { BiArea, BiBath, BiBed, BiCamera, BiImage } from "react-icons/bi";
import { useParams } from "react-router-dom";
import {
  HiChevronDoubleLeft,
  HiChevronDoubleRight,
  HiHand,
  HiWifi,
} from "react-icons/hi";
import { RiCarLine, RiDropLine, RiLock2Line } from "react-icons/ri";
import { FaSolarPanel, FaWater, FaWind } from "react-icons/fa";
import { HiOutlineLocationMarker, HiX } from "react-icons/hi";
import Image from "../components/Image";
import ContactForm from "../components/ContactForm";
import MoreLikeProperty from "../components/MoreLikeProperty";

export default function PropertyDetails() {
  const { id } = useParams();
  const [property, setProperty] = useState(null);
  const [showAllPhotos, setShowAllPhotos] = useState(false);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);

  const amenitiesIcons = {
    wifi: <HiWifi className="text-blue-800 h-5 w-5 font-normal" />,
    parking: <RiCarLine className="text-blue-800 h-5 w-5 font-normal" />,
    swimmingPool: <FaWater className="text-blue-800 h-5 w-5 font-normal" />,
    airConditioning: <FaWind className="text-blue-800 h-5 w-5 font-normal" />,
    solarBackup: <FaSolarPanel className="text-blue-800 h-5 w-5 font-normal" />,
    borehole: <RiDropLine className="text-blue-800 h-5 w-5 font-normal" />,
    "walled & Gated": (
      <RiLock2Line className="text-blue-800 h-5 w-5 font-normal" />
    ),
    sharable: <p>Property can be shared</p>,
  };

  useEffect(() => {
    if (!id) {
      return;
    }
    axios.get("/listings/" + id).then((res) => {
      setProperty(res.data);
      window.scrollTo(0, 0);
    });
  }, [id]);

  if (!property) return "";

  if (showAllPhotos) {
    const handleNextPhoto = () => {
      setCurrentPhotoIndex((prevIndex) =>
        prevIndex === property.photos.length - 1 ? 0 : prevIndex + 1
      );
    };

    const handlePreviousPhoto = () => {
      setCurrentPhotoIndex((prevIndex) =>
        prevIndex === 0 ? property.photos.length - 1 : prevIndex - 1
      );
    };

    return (
      <div className="photo-modal mb-16 mt-16">
        <div className="modal-content">
          <h2 className="text-3xl mb-4">Photos of {property.title}</h2>
          <div className="close-button mt-16">
            <button
              onClick={() => setShowAllPhotos(false)}
              className="flex w-auto bg-lime-600 text-white items-center py-2 px-4 rounded-full"
            >
              <HiX />
              Close photos
            </button>
          </div>
          <div className="photo-grid h-[550px] relative">
            {property?.photos?.length > 0 && (
              <>
                <button
                  className="absolute text-white left-0 top-1/2 transform -translate-y-1/2 bg-lime-500 px-2 w-8 py-1 rounded-full"
                  onClick={handlePreviousPhoto}
                >
                  <HiChevronDoubleLeft />
                </button>
                <div className="image-container">
                  <img
                    className="mx-auto max-h-[750px] h-full object-contain"
                    src={property.photos[currentPhotoIndex]}
                    alt=""
                  />
                </div>
                <button
                  className="absolute text-white right-0 top-1/2 transform -translate-y-1/2 bg-lime-500 w-8 px-2 py-1 rounded-full"
                  onClick={handleNextPhoto}
                >
                  <HiChevronDoubleRight />
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <section className=" mt-4 max-w-7xl mx-auto lg:max-w-6xl md:max-w-5xl  mb-16">
      <div className="flex  gap-6">
        <div className="relative border flex-grow sm:flex-grow md:flex-grow-0 lg:flex-grow xl:flex-grow-0 w-[850px] p-2 rounded-2xl border-gray-100">
          {/* Property Photos */}

          <div className="grid grid-cols-3 gap-2 text-gray-600">
            {/* Main Photo */}
            <div className="col-span-3 text-gray-600">
              <div className="relative h-0 pb-[40%]">
                {property.photos?.[0] && ( // if there is a photo
                  <Image
                    className="absolute inset-0 w-full h-full max-auto rounded-2xl object-cover"
                    src={property.photos?.[0]}
                    alt=""
                  />
                )}

                {/* Show More Button */}
                <button
                  onClick={() => setShowAllPhotos(true)}
                  className="flex gap-1 absolute font-bold text-xs bg-lime-600 px-4 text-white rounded-2xl py-2 bottom-2 w-auto right-2"
                >
                  <BiImage className="w-4 h-4" />
                  Show more
                </button>
              </div>

              {/* Additional Photos */}
              <div className="grid grid-cols-3 gap-2 mt-2 text-gray-500">
                {property.photos?.slice(1, 7).map((photo, index) => (
                  <div key={index}>
                    {photo && (
                      <Image
                        className="w-full h-24 object-cover rounded-lg transform transition duration-200 ease-in-out hover:scale-105"
                        src={photo}
                        alt=""
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="sm:hidden md:hidden lg:block xl:block flex-grow">
          <ContactForm />
        </div>
      </div>

      <div className="flex gap-6 ">
        {/* Property Details */}
        <div>
          {/* Category */}
          <div className="flex items-center justify-between mt-8">
            <div className="inline-block  bg-red-100 font-bold text-red-700 rounded-full text-[12px] px-8 py-1">
              {property.category}
            </div>

            {/* Price */}
            <div className="flex items-center">
              <div className="px-2 text-lg font-bold">Price:</div>
              <div className="text-2xl flex text-lime-600 font-bold">
                {" "}
                ${property.price}
                {property.listingsOptions &&
                property.listingsOptions.length > 0 ? (
                  property.listingsOptions.map((listingsOptions, index) => (
                    <div key={index} className="flex gap-2 items-center">
                      <span className="text-gray-500 text-sm font-medium">
                        {listingsOptions}
                      </span>
                    </div>
                  ))
                ) : (
                  <p></p>
                )}
              </div>
            </div>
          </div>

          {/* Title */}
          <div className="flex items-center justify-between max-w-3xl">
            <h1 className="mt-6 text-2xl font-bold">{property.title}</h1>
          </div>

          {/* Address and Location */}
          <div className="flex gap-1 mt-2">
            <HiOutlineLocationMarker className="text-gray-500" />
            <p className=" text-black text-sm font-medium">
              {property.address}
            </p>
            <h3 className="text-sm text-lime-600 font-medium ">
              {property.location}
            </h3>
          </div>

          {/* Property Details */}
          <div className="flex gap-6 mt-4 font-bold text-sm">
            {/* Bathrooms */}
            <div>
              <p>Bathrooms</p>
              <div className="flex mt-2 text-gray-600 items-center ">
                <BiBath className="h-4 w-4" />
                <p className=" font-normal px-1">{property.baths}</p>
              </div>
            </div>

            {/* Bedrooms */}
            <div>
              <p>Bedrooms</p>
              <div className="flex mt-2 text-gray-600 items-center ">
                <BiBed className="h-4 w-4" />
                <p className=" font-normal px-1">{property.beds}</p>
              </div>
            </div>

            {/* Area Space */}
            <div>
              <p>Area Space</p>
              <div className="flex mt-2 text-gray-600 items-center ">
                <BiArea className="h-4 w-4" />
                <p className=" font-normal px-1">{property.space}</p>
              </div>
            </div>
          </div>

          {/* Description */}
          <h2 className="mt-8 font-bold text-xl">Description:</h2>
          <p className=" text-gray-500 max-w-3xl">{property.description}</p>

          {/* Amenities */}
          <div>
            <h2 className="mt-8 font-bold text-xl">Amenities:</h2>
            <div className="grid grid-cols-3 xl:grid xl:grid-cols-4 lg:grid lg:grid-cols-3 md:grid md:grid-cols-3 sm:grid sm:grid-cols-2 ms:grid ms:grid-cols-2 max-w-2xl gap-4 mt-2">
              {property.amenities && property.amenities.length > 0 ? (
                property.amenities.map((amenity, index) => (
                  <div key={index} className="flex gap-2 items-center">
                    {amenitiesIcons[amenity]}
                    <span className="text-gray-500 text-sm font-medium">
                      {amenity}
                    </span>
                  </div>
                ))
              ) : (
                <p className="text-gray-500">
                  No amenities linked to the property.
                </p>
              )}
            </div>
          </div>

          {/* Sharable */}
          <div>
            <h2 className="mt-8 font-bold text-xl">Sharable:</h2>
            <div className="grid grid-cols-3 max-w-3xl md:max-w-3xl sm:max-w-xl gap-4 mt-2">
              {property.sharable && property.sharable.length > 0 ? (
                property.sharable.map((sharable, index) => (
                  <div key={index} className="flex gap-2 items-center">
                    {amenitiesIcons[sharable]}
                    <span className="text-gray-500 font-medium">
                      {sharable}
                    </span>
                  </div>
                ))
              ) : (
                <p className=" text-gray-500 text-sm font-medium">
                  Property not open for Sharing
                </p>
              )}
            </div>
          </div>

          {/* Contact Form (Responsive) */}

          <div className="lg:hidden md:hidden sm:mt-4 ">
            <ContactForm />
          </div>

          {/* More properties Form (Responsive) */}
          <div className="mt-8 px-8 max-w-4xl md:max-w-4xl lg:hidden xl:hidden">
            <h3 className="font-bold mb-4 text-md">
              More properties like this:
            </h3>
            <div className=" ">
              <MoreLikeProperty property={property} />
            </div>
          </div>
        </div>

        {/* More properties Form */}

        <div className="mt-8 hidden md:hidden sm:hidden-none lg:block xl:block px-8 max-w-[400px]">
          <h3 className="font-bold mb-4 text-md">More properties like this:</h3>
          <div className="">
            <MoreLikeProperty property={property} />
          </div>
        </div>
      </div>
    </section>
  );
}
