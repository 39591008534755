export default function PrivacyPolicy() {
  return (
    <section className="py-10">
      <div className="bg-gradient-to-b from-lime-600 to-lime-600 p-24">
        <h1 className="text-center font-bold text-white text-3xl">
          Privacy Policy
        </h1>
      </div>
      <div className="">
        <div
          className=" relative inset-0 flex items-center justify-center"
          aria-hidden="true"
        >
          <div className="max-w-5xl  mx-auto bg-white pr-8 pl-8 py-10 rounded-xl ">
            <h2 className="text-md text-gray-800 font-bold">
              About this Application
            </h2>
            <p className="text-sm mb-4">
              At Hoji Inc, we value your privacy and are committed to protecting
              your personal information. Our Privacy Policy explains how we
              collect, use, and disclose your information when you use our
              platform. By using Hoji Inc, you consent to the practices
              described in this Privacy Policy.
            </p>

            <h2 className="text-md text-gray-800 font-bold">
              Information Collection:
            </h2>
            <p className="text-sm mb-4">
              We may collect personal information from you, including your name,
              email address, and password when you create an account. We may
              also collect non-personal information, such as your IP address,
              browser type, and operating system, for statistical analysis and
              to improve our services.
            </p>

            <h2 className="text-md text-gray-800 font-bold">
              Use of Information:
            </h2>
            <p className="text-sm mb-4">
              We use your personal information to provide, maintain, and improve
              our platform, as well as to communicate with you regarding your
              account and our services. We may also use your information to send
              you promotional and marketing communications, which you can opt
              out of at any time.
            </p>

            <h2 className="text-md text-gray-800 font-bold">
              Information Sharing:{" "}
            </h2>
            <p className="text-sm mb-4">
              We do not sell, trade, or rent your personal information to third
              parties. However, we may share your information with trusted
              third-party service providers who assist us in operating our
              platform, conducting our business, or servicing you.
            </p>

            <h2 className="text-md text-gray-800 font-bold">Data Security:</h2>
            <p className="text-sm mb-4">
              We employ industry-standard security measures to protect your
              personal information from unauthorized access, disclosure,
              alteration, or destruction. However, no method of transmission
              over the Internet or electronic storage is 100% secure, and we
              cannot guarantee absolute security.
            </p>

            <h2 className="text-md text-gray-800 font-bold">Cookies:</h2>
            <p className="text-sm mb-4">
              Hoji Inc uses cookies to enhance your browsing experience and
              provide personalized services. You can set your browser to refuse
              cookies or alert you when cookies are being sent, but some
              features of our platform may not function properly without
              cookies.
            </p>

            <h2 className="text-md text-gray-800 font-bold">
              Children's Privacy:
            </h2>
            <p className="text-sm mb-4">
              Hoji Inc is not intended for use by individuals under the age of
              16. We do not knowingly collect personal information from
              children. If you believe we have collected personal information
              from a child, please contact us immediately.
            </p>

            <h2 className="text-md text-gray-800 font-bold">
              Changes to the Privacy Policy
            </h2>
            <p className="text-sm mb-4">
              We may update our Privacy Policy from time to time. We will notify
              you of any changes by posting the new Privacy Policy on our
              website. Your continued use of Hoji Inc after the changes are made
              will signify your acceptance of the revised Privacy Policy.
            </p>

            <h2 className="text-md text-gray-800 font-bold">
              If you have any questions or concerns about our Terms of Service
              or Privacy Policy, please contact our support team for further
              assistance.
            </h2>
            <p className="text-sm mb-4">
              We reserve the right to terminate or suspend your account and
              access to Hoji Inc at any time and for any reason without prior
              notice. You may also terminate your account by contacting our
              support team.
            </p>
            <p className="text-sm mb-4">Hoji Incorporated (Pvt) Ltd</p>
            <p className="text-sm mb-4">Operating as Hoji Inc</p>
            <p className="text-sm mb-4">
              Corner 6th & Central Esco Court Suite 1, Harare, Zimbabwe
            </p>
            <p className="text-sm mb-4">Website: www.hojiinc.co.zw</p>
          </div>
        </div>
      </div>
    </section>
  );
}
