import { GA4React } from "ga-4-react";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HouseContextProvider from "./components/HouseContext";

const ga4react = new GA4React("G-KNTWMJHTVN");
ga4react.initialize().catch((err) => console.error(err));

ReactDOM.render(
  <React.StrictMode>
    <HouseContextProvider>
      <Router>
        <App />
        <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} />
      </Router>
    </HouseContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
