export default function AdsDisclaimer() {
  return (
    <section className="py-10">
      <div className="bg-gradient-to-b from-lime-600 to-lime-600 p-24">
        <h1 className="text-center font-bold text-white text-3xl">
          Disclaimer for Hoji Inc
        </h1>
      </div>
      <div className="">
        <div
          className=" relative inset-0 flex items-center justify-center"
          aria-hidden="true"
        >
          <div className="max-w-5xl  mx-auto bg-white pr-8 pl-8 py-10 rounded-xl ">
            <h2 className="text-md text-gray-800 font-bold">
              Disclaimer for Prospective Users of Hoji Inc Advertising Services
            </h2>
            <p className="text-sm mb-4">Last updated: June, 2023</p>

            <p className="text-sm mb-4">
              Thank you for considering Hoji Inc as your advertising platform.
              Before proceeding, we would like to provide you with important
              information and a disclaimer regarding the use of our advertising
              services. Please review the following carefully:
            </p>

            <h2 className="text-md text-gray-800 font-bold">
              Advertising Platform Usage
            </h2>
            <p className="text-sm mb-4">
              By utilizing the advertising services offered by Hoji Inc, you
              agree to comply with our terms and conditions, as well as any
              guidelines or policies provided. It is your responsibility to
              ensure the accuracy and completeness of the information, pictures,
              and descriptions provided for your advertisement. We reserve the
              right to review and moderate the content submitted to maintain the
              quality and integrity of our platform.
            </p>

            <h2 className="text-md text-gray-800 font-bold">
              Content Ownership and Accuracy
            </h2>
            <p className="text-sm mb-4">
              By submitting content (including pictures, descriptions, and
              contact information) for your advertisement, you affirm that you
              have the necessary rights and permissions to use the content and
              that it accurately represents your company, products, or services.
              Hoji Inc does not assume any responsibility or liability for any
              copyright infringement, inaccurate information, or misleading
              content provided by advertisers. The responsibility lies solely
              with the advertiser.
            </p>

            <h2 className="text-md text-gray-800 font-bold">
              Privacy and Data Protection
            </h2>
            <p className="text-sm mb-4">
              We are committed to protecting your privacy and handling your
              personal information in accordance with applicable data protection
              laws. However, please be aware that once your advertisement is
              published on our platform, it may be accessible to other users and
              individuals outside our control. We encourage you to review our
              privacy policy for more information on how we handle personal
              data.
            </p>

            <h2 className="text-md text-gray-800 font-bold">
              Verification of Advertisements
            </h2>
            <p className="text-sm mb-4">
              Hoji Inc does not endorse or verify the claims, products, or
              services offered by advertisers. It is your responsibility as a
              user to conduct your own due diligence and exercise caution when
              engaging with advertisers on our platform. We recommend verifying
              the legitimacy and credibility of any company or individual before
              entering into any transactions or agreements.
            </p>

            <h2 className="text-md text-gray-800 font-bold">
              Limitation of Liability
            </h2>
            <p className="text-sm mb-4">
              While we strive to provide a reliable platform, Hoji Inc cannot
              guarantee the accuracy, completeness, or effectiveness of the
              advertisements posted on our website. We disclaim any liability
              for any loss, damage, or inconvenience arising from your use of
              our platform or interactions with advertisers. It is your
              responsibility to evaluate and assess the suitability and
              reliability of any advertised products, services, or companies.
            </p>

            <h2 className="text-md text-gray-800 font-bold">Modifications</h2>
            <p className="text-sm mb-4">
              We reserve the right to modify, update, or remove any
              advertisements or content submitted to our platform, if they are
              found to be in violation of our terms and conditions or if they do
              not meet our quality standards. We may also update our guidelines
              and policies from time to time, and it is your responsibility to
              stay informed about any changes.
            </p>

            <h2 className="text-md text-gray-800 font-bold">
              {" "}
              <h2 className="text-md text-gray-800 font-bold">Contact Us</h2>
            </h2>
            <h2 className="text-md text-gray-800 font-bold">
              Operating as Hoji Inc
            </h2>
            <h2 className="text-md text-gray-800 font-bold">
              Corner 6th & Central Esco Court Suite 1, Harare, Zimbabwe
            </h2>
            <h2 className="text-md text-gray-800 font-bold">
              Website: www.hojiinc.co.zw
            </h2>

            <p className="text-sm mb-4">
              By utilizing the advertising services provided by Hoji Inc, you
              acknowledge that you have read, understood, and agreed to the
              terms of this disclaimer.
            </p>
            <p className="text-sm mb-4">
              If you have any questions or concerns regarding this disclaimer or
              the use of our advertising services, please contact our support
              team for further assistance.
            </p>
            <p className="text-sm mb-4">
              Thank you for choosing Hoji Inc as your advertising platform. We
              look forward to helping you promote your company effectively.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
