import React from "react";
import Image from "../assets/img/vale.png";
import Image2 from "../assets/img/vale2.png";
import { Link } from "react-router-dom";

function BigAd() {
  return (
    <section className="2xl:py-18 2xl:bg-white">
      <div className="px-4 mx-auto rounded-2xl overflow-hidden  max-w-6xl sm:px-6 lg:px-12 relative">
        <div
          style={{
            backgroundImage: `url(https://assets.website-files.com/5837424ae11409586f837994/61195e21f792d7065d2f56ad_noise.png)`,
          }}
          className="sm:py- rounded-2xl lg:py- 2xl:pl- relative"
        >
          <div className="rounded-2xl bg-red-100 items-center ">
            <div className="absolute  top-0 left-0 ">
              <img
                className="relative rounded-2xl w-full max-w-xs mx-auto -mb-60 lg:-mb-64"
                src={Image2}
                alt=""
              />
            </div>
            <div className="mx-auto max-w-2xl  py-36">
              <h2 className="text-3xl px-3 text-center font-bold leading-tight text-red-700 sm:text-4xl lg:text-5xl lg:leading-tight">
                Find Your Perfect Match this February with Us!
              </h2>
              <p className="mt-4 px-4 text-base italic text-center text-red-500">
                Embrace the month of love and discover exquisite flower bouquets
                curated just for you. Let us help you express your affection
                with blooms that speak volumes of your love.
              </p>

              <div className="flex flex-col items-center mt-8 space-x-4 lg:mt-12">
                <Link
                  className="text-lg font-bold border-2 text-red-700 rounded-full p-2 px-8 border-red-700"
                  to={"/retailers/648327c2c5bca10bb70e72bd"}
                >
                  Explore
                </Link>
              </div>
            </div>

            <div className="absolute top-0 right-0 ">
              <img
                className="relative rounded-2xl w-full max-w-xs mx-auto -mb-60 lg:-mb-64"
                src={Image}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BigAd;
