import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/img/logo.png";
import { UserContext } from "../UserContext";
import { HiMenu, HiUser } from "react-icons/hi";

const Header = () => {
  const { user } = useContext(UserContext);
  const [scrolled, setScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header
      className={`${
        scrolled ? "bg-white shadow-md" : "bg-transparent"
      } sticky top-0 z-50 transition duration-300 ease-in-out`}
    >
      <div className="container  mx-auto px-8 pb-4 py-3 sm:max-w-screen-sm md:max-w-4xl lg:max-w-6xl xl:max-w-7xl">
        <div className="md:flex items-center md:flex-grow md:items-center md:justify-between">
          <div className="flex items-center justify-between flex-grow">
            <div className="text-xl items-center font-bold text-gray-800 md:text-2xl hover:text-amber-600">
              <Link to="/">
                {" "}
                <img src={logo} alt="logo" className="w-28" />{" "}
              </Link>
            </div>

            {/* Mobile menu button */}
            <div className="flex md:hidden">
              <button
                type="button"
                className="text-gray-500 hover:text-gray-600 focus:outline-none focus:text-gray-600"
                aria-label="toggle menu"
                onClick={toggleMenu}
              >
                <HiMenu />
              </button>
            </div>
          </div>

          {/* Mobile Menu open: "block", Menu closed: "hidden" */}
          <div
            className={`${
              menuOpen ? "block" : "hidden"
            } md:flex md:items-center md:justify-between`}
          >
            <div className="flex flex-col md:flex-row md:mx-2">
              <div className=" mr-32 mx-auto">
                <Link
                  to="/"
                  className="my-1 text-sm font-bold text-black md:mx-2 md:my-0 hover:text-gray-600 focus:font-bold focus:text-amber-600"
                >
                  Home
                </Link>
                <Link
                  to="/services"
                  className="my-1 text-sm font-bold text-black md:mx-2 md:my-0 hover:text-gray-600 focus:font-bold focus:text-amber-600"
                >
                  Companies
                </Link>
                <Link
                  to="/retailers"
                  className="my-1 text-sm font-bold text-black md:mx-2 md:my-0 hover:text-gray-600 focus:font-bold focus:text-amber-600"
                >
                  Retailers
                </Link>
                <Link
                  to="/property"
                  className="my-1 text-sm font-bold text-black md:mx-2 md:my-0 hover:text-gray-600 focus:font-bold focus:text-amber-600"
                >
                  Properties
                </Link>
                <Link
                  to="/Restaurants"
                  className="my-1 text-sm font-bold text-black md:mx-2 md:my-0 hover:text-gray-600 focus:font-bold focus:text-amber-600"
                >
                  Food and Dinning
                </Link>
              </div>

              <div>
                <Link
                  to="/advertise"
                  className="px-4 py-2 text-sm flex items-center justify-center gap-2 font-medium text-white rounded-full hover:bg-black focus:outline-none bg-amber-600 focus:bg-amber-200"
                >
                  Advertise
                </Link>
              </div>
            </div>
            {/* 
            <div className="flex  items-center justify-center md:mx-4">
              {user && user.name ? (
                <Link
                  to="/account"
                  className="px-4 py-2 text-sm flex items-center justify-center gap-2 font-medium text-white rounded-full hover:bg-blue-200 focus:outline-none focus:bg-blue-200"
                >
                  <div className=" rounded-full text-white bg-amber-600 p-1">
                    <HiUser />
                  </div>
                  <div className="text-amber-600">{user.name}</div>
                </Link>
              ) : (
                <div className="lg:w-full md:w-40">
                  <Link
                    to="/Signin"
                    className="px-4 lg:px-4 md:px-2 py-2 text-sm font-medium text-black border border-amber-600 rounded-md mr-2"
                  >
                    Sign In
                  </Link>

                  <Link
                    className="px-4 lg:px-4 md:px-2 py-2 text-sm font-medium text-white bg-amber-600 rounded-md hover:bg-amber-800 focus:outline-none focus:bg-amber-600"
                    to="/SignUp/"
                  >
                    Sign up
                  </Link>
                </div>
              )}
            </div> */}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
