import axios from "axios";
import { useState } from "react";

import { HiOutlineCloudUpload, HiStar, HiTrash } from "react-icons/hi";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import Image from "./Image";
import { ImSpinner2 } from "react-icons/im";

export default function PhotosUploader({ addedPhotos, onChange }) {
  const [photoLink, setPhotoLink] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  async function addPhotoByLink(ev) {
    ev.preventDefault();
    setIsLoading(true);
    try {
      const { data: filename } = await axios.post("/upload-by-link", {
        link: photoLink,
      });
      onChange((prev) => [...prev, filename]);
      setPhotoLink("");
    } catch (error) {
      console.error("Error adding photo by link:", error);
      // Handle the error, such as displaying an error message or taking other appropriate actions
    } finally {
      setIsLoading(false);
    }
  }

  function uploadPhoto(ev) {
    setIsLoading(true);
    const files = ev.target.files;
    const data = new FormData();
    for (let i = 0; i < files.length; i++) {
      data.append("photos", files[i]);
    }
    axios
      .post("/upload", data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        const { data: filenames } = res;
        onChange((prev) => [...prev, ...filenames]);
      })
      .catch((error) => {
        console.error("Error uploading photo:", error);
        // Handle the error, such as displaying an error message or taking other appropriate actions
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function removePhoto(ev, filename) {
    ev.preventDefault();
    onChange([...addedPhotos.filter((photo) => photo !== filename)]);
  }

  function selectAsMain(ev, filename) {
    ev.preventDefault();
    const addedPhotoWithoutSelected = addedPhotos.filter(
      (photo) => photo !== filename
    );
    const newAddedPhotos = [filename, ...addedPhotoWithoutSelected];
    onChange(newAddedPhotos);
  }

  return (
    <>
      <div className="flex gap-2 ">
        <input
          type="text"
          value={photoLink}
          onChange={(ev) => setPhotoLink(ev.target.value)}
          placeholder={"Add using a link ....jpg/Png"}
          className="form-input"
        />
        <button
          onClick={addPhotoByLink}
          className="  w-32 px-2 h-14 font-bold text-sm bg-lime-600 text-white rounded-2xl grow "
        >
          Add&nbsp;photo
        </button>
      </div>

      <div className="grid gap-2 grid-cols-6 lg:grid-cols-6 md:grid-cols-6 ms:grid-cols-4 sm:grid-cols-1">
        {addedPhotos.length > 0 &&
          addedPhotos.map((link) => (
            <div className="h-32 flex relative" key={link}>
              <Image
                className="rounded-2xl  w-full h-32 object-cover"
                src={link}
                alt="image"
              />
              <button
                onClick={(ev) => removePhoto(ev, link)}
                className="cursor-pointer w-6 absolute bottom-2 right-2 text-white bg-black bg-opacity-50 rounded-xl p-1"
              >
                <HiTrash />
              </button>
              <button
                onClick={(ev) => selectAsMain(ev, link)}
                className="cursor-pointer absolute w-6 bottom-2 left-2 text-white bg-black bg-opacity-50 rounded-xl p-1"
              >
                {link === addedPhotos[0] && <AiFillStar />}
                {link !== addedPhotos[0] && <AiOutlineStar />}
              </button>
            </div>
          ))}
        <label className=" cursor-pointer bg-gray-100  border-dashed border-2 items-center justify-center text-center flex w-52 h-32 rounded-2xl text-gray-600">
          <input
            type="file"
            multiple
            className="hidden"
            onChange={uploadPhoto}
          />
          <HiOutlineCloudUpload className=" w-8 h-8" />
          Upload
        </label>
      </div>
      {isLoading && (
        <ImSpinner2
          className="animate-spin text-2xl justify-center text-lime-600 mx-auto
       text-2x1"
        />
      )}
    </>
  );
}
