import { Link } from "react-router-dom";
import Image from "../assets/img/missD.jpg";
import Image2 from "../assets/img/missDlogo.jpg";
import {
  BsFacebook,
  BsInstagram,
  BsLightningCharge,
  BsPhoneFill,
  BsWhatsapp,
} from "react-icons/bs";
import { ImInstagram } from "react-icons/im";

export default function ServiceAdsBanner() {
  return (
    <div className="bg-white sm:hidden shadow-lg lg:flex md:flex xl:flex md:max-w-full justify-between mx-16 my-8 overflow-hidden px-12 xl:max-w-full max-w-6xl items-top flex h-64 rounded-xl">
      <div className="absolute z-10  flex gap-1 items-center text-gray-700 font-normal text-xs bg-gray-100 m-2 py-1 px-4 rounded-xl bg-opacity-50">
        <BsLightningCharge />
        Sponsored
      </div>
      <div className="w-full">
        <div className="flex  justify-between">
          <div className="h-64 w-80 my-20  right-44 z-0">
            <img className=" w-full object-fit" src={Image2} alt="businesses" />
          </div>

          <div className=" my-20  ">
            <h1 className=" flex items-center text-gray-700 text-xl gap-2 font-bold">
              <BsPhoneFill />
              0784 135 980
            </h1>
            <h1 className=" flex items-center text-sm text-gray-700 gap-2 font-bold">
              <BsInstagram />
              missdee_s_Hair
            </h1>
            <h1 className=" flex items-center text-sm text-gray-700 gap-2 font-bold">
              <BsFacebook />
              MissDeesHair
            </h1>
            <button
              className="bg-gray-100  font-bold rounded-full mt-8 items-center gap-2  flex text-center justify-center py-1 px-4"
              onClick={(e) => {
                e.preventDefault();
                const defaultMessage = `Hello, I am interested in MissDee Hair.\n\nCheck out more listings at Hoji Inc. Search for services, properties, restaurants or retailers.\n\nTo advertise with Hojiinc visit www.hojiinc.co.zw today!`;
                const url = `https://wa.me/+263784135890?text=${defaultMessage}`;

                const link = document.createElement("a");
                link.href = url;
                link.target = "_blank";
                link.click();
              }}
            >
              <BsWhatsapp className="justify-center text-green-700 w-4 h-4 " />
              Chat with MissDee
            </button>
          </div>

          <div className="h-64 w-80 left-44 z-0">
            <img
              className="h-full w-full object-cover"
              src={Image}
              alt="businesses"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
