import { Link } from "react-router-dom";
import Image from "../assets/img/banner.png";
// 	https://assets.website-files.com/5837424ae11409586f837994/61195e21f792d7065d2f56ad_noise.png

export default function AdsBanner() {
  return (
    <div
      className="b mx-auto sm:hidden lg:flex md:flex xl:flex md:max-w-full justify-between m-16 overflow-hidden px-12 xl:max-w-5xl max-w-4xl items-center flex h-52 rounded-xl"
      style={{
        backgroundImage: `url(https://assets.website-files.com/5837424ae11409586f837994/61195e21f792d7065d2f56ad_noise.png)`,
      }}
    >
      <div className="relative p-4">
        <h1 className="text-6xl font-bold text-gray-800 max-w-xl">
          Advertise with us
        </h1>
        <p className="text-gray-500">
          We offer monthly analytics on how your ad performed
        </p>
      </div>
      <Link to="/advertise">
        <div className="shadow-lg bg-white justify-center items-center font-bold text-gray-600 rounded-xl  text-center  px-8 flex flex-grow py-3 relative z-10">
          Get started for free
        </div>
      </Link>
      <div className="absolute xl:right-72 lg:right-44 md:right-16 h-52 w-80 right-44 z-0">
        <img
          className="h-full w-full object-cover"
          src={Image}
          alt="businesses"
        />
      </div>
    </div>
  );
}
