import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Image from "./Image";
import { BiArea, BiBath, BiBed } from "react-icons/bi";
import { ImSpinner2 } from "react-icons/im";

export default function MoreLikeProperty({ property }) {
  const [similarProperties, setSimilarProperties] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!property || !property.category) {
      return;
    }
    setIsLoading(true);
    axios
      .get(`/listing?category=${encodeURIComponent(property.category)}&limit=3`)
      .then((res) => {
        setSimilarProperties([...res.data]);
      })
      .catch((error) => {
        console.error("Error fetching similar properties:", error);
        // Handle the error, such as displaying an error message or taking other appropriate actions
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [property]);

  const displayedProperties = similarProperties.slice(0, 3); // Limit the number of properties to 3

  return (
    <div className="sm:flex-col mx-auto gap-2 mb-8">
      {isLoading ? (
        <ImSpinner2
          className="animate-spin text-2xl justify-center text-lime-600 mx-auto
       text-2x1"
        />
      ) : displayedProperties.length > 0 ? (
        displayedProperties.map((property) => (
          <Link to={"/property/" + property._id} key={property._id}>
            <div className="max-w-2xl shadow-lg shadow-transparent-[40%] mb-4 max z-auto border sm:flex-col border-gray-100 cursor-pointer md:flex-row p-2 transition rounded-xl bg-white flex lg:flex-row">
              <div className="">
                <div className="">
                  <div className="rounded-xl sm:mb-4 lg:m-0 overflow-hidden">
                    {property.photos?.[0] && (
                      <Image
                        className="img sm:w-full lg:w-56 lg:h-24 w-48 h-48 object-cover transform transition duration-200 ease-in-out hover:scale-120"
                        src={property.photos?.[0]}
                        alt=""
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="ml-2 w-full sm:w-auto lg:w-full">
                <div>
                  <div className="flex gap-8 items-center justify-between">
                    <div className="">
                      <h2 className="font-bold text-slate-700 text-lg">
                        {property.title}
                      </h2>
                      <h2 className="text-sm  text-lime-600 ">
                        {property.location}
                      </h2>

                      <div className=" gap-2 items-center">
                        <div className="flex items-center gap-1 font-bold text-sm">
                          <div>
                            <div className="flex text-gray-600 items-center ">
                              <BiBath className="h-4 w-4" />
                              <p className=" font-normal px-1">
                                {property.baths}
                              </p>
                            </div>
                          </div>

                          <div>
                            <div className="flex text-gray-600 items-center ">
                              <BiBed className="h-4 w-4" />
                              <p className=" font-normal px-1">
                                {property.baths}
                              </p>
                            </div>
                          </div>

                          <div>
                            <div className="flex text-gray-600 items-center ">
                              <BiArea className="h-4 w-4" />
                              <p className=" font-normal px-1">
                                {property.space}
                              </p>
                            </div>
                          </div>
                        </div>{" "}
                        <p className="font-bold text-lime-600">
                          ${property.price}
                        </p>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        ))
      ) : (
        <p>No similar properties available.</p>
      )}
    </div>
  );
}
