import { useContext, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import axios from "axios";
import { UserContext } from "../UserContext";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [redirect, setRedirect] = useState(false);
  const { setUser } = useContext(UserContext);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loginError, setLoginError] = useState("");

  async function handleLoginSubmit(ev) {
    ev.preventDefault();

    let isValid = true;
    if (email.length < 1) {
      setEmailError("Email is required");
      isValid = false;
    } else {
      setEmailError("");
    }
    if (password.length < 1) {
      setPasswordError("Password is required");
      isValid = false;
    } else {
      setPasswordError("");
    }

    if (!isValid) {
      return;
    }

    try {
      const { data } = await axios.post("/SignIn", {
        email,
        password,
      });
      setUser(data);
      setRedirect(true);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 404) {
          setLoginError("User not found");
        } else if (error.response.status === 401) {
          setLoginError("Incorrect email or password");
        } else {
          setLoginError("Something went wrong. Please try again later");
        }
      } else {
        setLoginError("Network error. Please try again later");
      }
    }
  }

  if (redirect) {
    return <Navigate to={"/"} />;
  }

  return (
    <section className="py-8 bg-gray-50 sm:py-8 lg:py-8">
      <div className="px-4 lg:flex-row md:flex ms:grid ms:grid-cols-1 ms:gap-6 sm: sm:gap-4 sm:flex-col  mx-auto flex items-center max-w-7xl sm:px-6 lg:px-8">
        <div className="max-w-2xl mx-auto ">
          <h2 className="text-3xl font-bold leading-tight text-amber-600 sm:text-4xl lg:text-5xl">
            Welcome to Hoji-inc!
          </h2>
          <p className="max-w-xl lg:max-w-xl md:max-w-lg mx-auto mt-4 text-base leading-relaxed text-gray-600">
            Sign up now to access exclusive property listings and services. With
            Hoji-inc, you'll have access to a wide range of properties and
            services, all at your fingertips. Join us today and discover your
            dream home or unlock new opportunities for your business.
          </p>
        </div>

        <div className="relative max-w-md mx-auto ">
          <div className="overflow-hidden bg-white rounded-xl shadow-md">
            <div className="px-4 py-6 sm:px-8 sm:py-7">
              <form onSubmit={handleLoginSubmit}>
                <div className="space-y-5">
                  <div>
                    <label
                      for=""
                      className="text-base font-medium text-gray-900"
                    >
                      {" "}
                      Email address{" "}
                    </label>
                    <div className="mt-2.5 relative text-gray-400 focus-within:text-gray-600">
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg
                          className="w-5 h-5"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                          />
                        </svg>
                      </div>

                      <input
                        type="email"
                        value={email}
                        onChange={(ev) => setEmail(ev.target.value)}
                        placeholder="Enter email to get started"
                        className="block w-full py-4 pl-10 pr-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-xl focus:outline-none focus:border-orange-600 caret-orange-600"
                      />
                    </div>
                    {emailError && (
                      <div className="mt-2 text-sm text-red-500">
                        {emailError}
                      </div>
                    )}
                  </div>

                  <div>
                    <label
                      for=""
                      className="text-base font-medium text-gray-900"
                    >
                      {" "}
                      Password{" "}
                    </label>
                    <div className="mt-2.5 relative text-gray-400 focus-within:text-gray-600">
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg
                          className="w-5 h-5"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M12 11c0 3.517-1.009 6.799-2.753 9.571m-3.44-2.04l.054-.09A13.916 13.916 0 008 11a4 4 0 118 0c0 1.017-.07 2.019-.203 3m-2.118 6.844A21.88 21.88 0 0015.171 17m3.839 1.132c.645-2.266.99-4.659.99-7.132A8 8 0 008 4.07M3 15.364c.64-1.319 1-2.8 1-4.364 0-1.457.39-2.823 1.07-4"
                          />
                        </svg>
                      </div>

                      <input
                        type="password"
                        value={password}
                        onChange={(ev) => setPassword(ev.target.value)}
                        placeholder="Enter your password"
                        className="block w-full py-4 pl-10 pr-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-xl focus:outline-none focus:border-orange-600 caret-orange-600"
                      />
                    </div>
                    {passwordError && (
                      <div className="mt-2 text-sm text-red-500">
                        {passwordError}
                      </div>
                    )}
                    {loginError && (
                      <div className="text-red-500">{loginError}</div>
                    )}
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="inline-flex items-center justify-center w-full px-4 py-4 text-base font-semibold text-white transition-all duration-200 bg-amber-600 border border-transparent rounded-md focus:outline-none hover:bg-orange-600 focus:bg-orange-600"
                    >
                      Sign In
                    </button>
                  </div>

                  <div className="text-center">
                    <p className="text-base text-gray-600">
                      Don't have an account?{" "}
                      <a
                        href="/SignUp"
                        title=""
                        className="font-medium text-orange-500 transition-all duration-200 hover:text-orange-600 hover:underline"
                      >
                        Create Account
                      </a>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignIn;
