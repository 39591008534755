import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";
import PaymentSuccessful from "../pages/PaymentSuccessful";
import PaymentFailed from "../pages/PaymentFailed";

export default function Pricing() {
  const [amount, setAmount] = useState("");
  const [currency, setCurrency] = useState("ZWL");
  const [endUserId, setEndUserId] = useState("");
  const [description, setDescription] = useState("");
  const [paymentId, setPaymentId] = useState("");
  const [paymentStatus, setPaymentStatus] = useState(null);

  //RTGS Rate
  const handlePackageSelection = (selectedAmount, selectedCurrency) => {
    let adjustedAmount = parseFloat(selectedAmount); // Convert the amount to a number

    if (selectedCurrency === "ZWL") {
      adjustedAmount *= 1800;

      // else revert to initial USD amount
    }

    setAmount(adjustedAmount);
    setCurrency(selectedCurrency);
  };

  const handleSubmit = () => {
    setPaymentStatus("pending");

    let adjustedAmount = amount;
    // if (currency === "ZWL") {
    //   adjustedAmount *= 1800;
    // }

    const packet = {
      uniqueId: "udipPPXLANKzbQoWX0",
      amount: adjustedAmount,
      endUserId: endUserId,
      description: "testing send",
      currency: currency,
      notifyUrl:
        "http://clicknpay.africa/update-service/v1/ecocash/status-update",
    };

    axios
      .post(
        "https://backendservices.clicknpay.africa:2081/ecocashapi/initiate",
        packet
      )
      .then((resp) => {
        console.log(resp);
        setPaymentId(resp.data.paymentId);
      })
      .catch((err) => {
        console.log(err);
        setPaymentStatus("failed");
      });
  };

  useEffect(() => {
    if (paymentId !== "") {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      axios
        .get(
          `https://backendservices.clicknpay.africa:2081/ecocashapi/status/${endUserId}/${paymentId}`,
          config
        )
        .then((resp) => {
          console.log(resp);
          if (resp.data.transaction.status === "COMPLETED") {
            setPaymentStatus("success");
          } else {
            setPaymentStatus("failed");
          }
        })
        .catch((err) => {
          console.log(err);
          setPaymentStatus("failed");
        });
    }
  }, [endUserId, paymentId]);

  return (
    <section className="py-10 bg-gray-50 sm:py-16 lg:py-24">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">
            Pricing & Plans
          </h2>
          <p className="max-w-3xl mx-auto mt-4 text-base leading-relaxed text-gray-600">
            Choose the plan that suits your needs and budget. Our pricing
            options are designed to offer you flexibility and value, with
            features that help you achieve your goals.
          </p>
        </div>

        <div className="flex max-w-4xl  gap-6 mx-auto  mt-14 md:gap-9">
          {/* Day */}
          <div className="overflow-hidden bg-transparent border-2 border-gray-200 rounded-md">
            <div className="p-6 md:py-8 md:px-9">
              <h3 className="text-xl font-semibold text-black">Urgent</h3>
              <p className="mt-2.5 text-sm text-gray-600">
                You will have acces to contact details for the day
              </p>

              <div className="flex items-end mt-5">
                <div className="flex items-start">
                  <span className="text-xl font-medium text-black"> $ </span>
                  <p className="text-6xl font-medium tracking-tight">1</p>
                </div>
                <span className="ml-0.5 text-lg text-gray-600"> / day </span>
              </div>

              <button
                className="inline-flex items-center justify-center w-full px-4 py-3 mt-6 font-semibold text-gray-900 transition-all duration-200 bg-transparent border-2 rounded-full border-lime-600 hover:bg-lime-600 hover:text-white focus:text-white focus:bg-lime-600"
                onClick={() => handlePackageSelection("1", "USD")}
              >
                Subscribe
              </button>
            </div>
          </div>

          {/* Week */}
          <div className="overflow-hidden bg-white border-2 border-transparent rounded-md">
            <div className="p-6 md:py-8 md:px-9">
              <h3 className="text-xl font-bold text-lime-600">
                Property Hunter
              </h3>
              <p className="mt-2.5 text-sm text-gray-600">
                You will have acces to contact details for 7 days
              </p>

              <div className="flex items-end mt-5">
                <div className="flex items-start">
                  <span className="text-xl font-medium text-black"> $ </span>
                  <p className="text-6xl font-medium tracking-tight">6</p>
                </div>
                <span className="ml-0.5 text-lg text-gray-600"> / week </span>
              </div>

              <button
                className="inline-flex items-center justify-center w-full px-4 py-3 mt-6 font-semibold text-white transition-all duration-200 border-2 border-transparent rounded-full bg-gradient-to-r from-lime-600 to-blue-800 hover:opacity-80 focus:opacity-80"
                onClick={() => handlePackageSelection("6", "USD")}
              >
                Subscribe
              </button>
            </div>
          </div>

          {/* Month */}
          <div className="overflow-hidden bg-transparent border-2 border-gray-200 rounded-md">
            <div className="p-6 md:py-8 md:px-9">
              <h3 className="text-xl font-semibold text-black">Agent</h3>
              <p className="mt-2.5 text-sm text-gray-600">
                You will have full acces to contact details for 30 days
              </p>

              <div className="flex items-end mt-5">
                <div className="flex items-start">
                  <span className="text-xl font-medium text-black"> $ </span>
                  <p className="text-6xl font-medium tracking-tight">10</p>
                </div>
                <span className="ml-0.5 text-lg text-gray-600"> / month </span>
              </div>

              <button
                className="inline-flex items-center justify-center w-full px-4 py-3 mt-6 font-semibold text-gray-900 transition-all duration-200 bg-transparent border-2 rounded-full border-lime-600 hover:bg-lime-600 hover:text-white focus:text-white focus:bg-lime-600"
                onClick={() => handlePackageSelection("10", "USD")}
              >
                Subscribe
              </button>
            </div>
          </div>
        </div>

        <div>
          {paymentStatus === "success" && <PaymentSuccessful />}
          {paymentStatus === "failed" && <PaymentFailed />}
          {paymentStatus === "pending" && <p>Checking payment status...</p>}
        </div>

        <div>
          <label htmlFor="amount">Amount:</label>
          <input
            type="number"
            id="amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="currency">Currency:</label>
          <select
            id="currency"
            value={currency}
            onChange={(e) => {
              const selectedCurrency = e.target.value;
              let adjustedAmount = amount;

              if (selectedCurrency === "ZWL") {
                adjustedAmount *= 1800; // Multiply the amount by 1800 for ZWL currency
              } else if (selectedCurrency === "USD") {
                adjustedAmount /= 1800;
              }

              setAmount(adjustedAmount);
              setCurrency(selectedCurrency);
            }}
          >
            <option value="ZWL">ZWL</option>
            <option value="USD">USD</option>
          </select>
        </div>

        <div>
          <label htmlFor="endUserId">Ecocash Phone number:</label>
          <input
            type="text"
            id="endUserId"
            value={endUserId}
            onChange={(e) => setEndUserId(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="description">Payment ref:</label>
          <input
            type="text"
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <button onClick={handleSubmit}>Submit</button>
      </div>
    </section>
  );
}
