import Logo from "../assets/img/dzimbabwe/redlogo.png";
import Money from "../assets/img/dzimbabwe/money.png";
import Bg from "../assets/img/dzimbabwe/BG.png";
import { BsLightningCharge } from "react-icons/bs";

export default function RetailAdsBanner() {
  return (
    <div
      className="bg-white sm:hidden shadow-lg lg:flex md:flex xl:flex md:max-w-full justify-between mx-16 my-8 overflow-hidden pl-12 xl:max-w-full max-w-6xl items-top flex h-72 rounded-xl"
      style={{
        backgroundImage: `url(${Bg})`,
        backgroundSize: "cover", // You can adjust this value (e.g., 'contain', '100% auto', etc.)
      }}
    >
      <div className="absolute z-10  flex gap-1 items-center text-gray-700 font-normal text-xs bg-gray-100 m-2 py-1 px-4 rounded-xl bg-opacity-50">
        <BsLightningCharge />
        Sponsored
      </div>
      <div className="w-full">
        <div className="flex  justify-between">
          <div className="h-64 w-80 my-20  right-44 z-0">
            <img className=" w-full object-fit" src={Logo} alt="businesses" />
          </div>

          <div className=" my-8  ">
            <h1 className="font-black italic text-white  bg-red-800 px-4 inline-block text-xl">
              Galvanized Steel
            </h1>
            <h1 className="  items-center  text-6xl gap-2 font-black">
              ROOFING
            </h1>
            <h1 className="  items-center text-red-800   text-6xl gap-2 font-black italic">
              SHEETS IN
            </h1>
            <h1 className="  items-center  text-6xl gap-2 font-black">STOCK</h1>
            <img
              className=" w-[450px] object-fit"
              src={Money}
              alt="businesses"
            />
          </div>
          <div className="bg-gradient-to-r from-transparent to-white justify-center">
            <div className="h-64 justify-center text-center my-20 w-80 left-44 z-0">
              <h1 className="font-black italic text-red-800 text-xl">
                Free Delivery
              </h1>
              <h1 className="font-black italic text-slate-700 text-xl">
                +263 776 673 056
              </h1>
              <h1 className="font-black italic text-slate-700 text-xl">
                +263 773 307 799
              </h1>
              <h1 className="font-black italic text-slate-700 text-xl">
                +263 718 852 489
              </h1>

              <h1 className=" italic text-sm text-slate-700">
                19 Leyland Road, New Ardbennie, Harare, ZW
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
