import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import debounce from "lodash.debounce";
import { ImSpinner2 } from "react-icons/im";
import { HiOutlineLocationMarker, HiOutlineSearch } from "react-icons/hi";

const HomeSearch = () => {
  let navigate = useNavigate(); // Access the history object
  const [inputValue, setInputValue] = useState("");

  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const [selectedItemIndex, setSelectedItemIndex] = useState(-1);

  const handleSearch = () => {
    // Redirect to the results page with the search query
    navigate(`/results?query=${inputValue}`);
  };

  const handleKeyDown = (event) => {
    switch (event.key) {
      case "ArrowUp":
        setSelectedItemIndex(Math.max(selectedItemIndex - 1, -1));
        event.preventDefault();
        break;
      case "ArrowDown":
        setSelectedItemIndex(
          Math.min(selectedItemIndex + 1, searchResults.length - 1)
        );
        event.preventDefault();
        break;
      default:
        break;
    }
  };

  const MAX_RESULTS = 10;

  const handleMouseEnter = (index) => {
    setSelectedItemIndex(index);
  };

  const handleMouseLeave = () => {
    setSelectedItemIndex(-1);
  };

  const debouncedSearch = useCallback(
    debounce(async (query) => {
      try {
        const propertyResponse = await axios.get("/listing");
        const serviceResponse = await axios.get("/service-listing");
        const restaurantsResponse = await axios.get("/restaurants-listing");
        const retailersResponse = await axios.get("/retailers-listing");

        // Combine the results from all endpoints
        const allResults = [
          ...propertyResponse.data,
          ...serviceResponse.data,
          ...restaurantsResponse.data,
          ...retailersResponse.data,
        ];

        const filteredResults = allResults
          .filter((item) => {
            const searchQuery = query.toLowerCase();
            const itemTitle = item.title.toLowerCase();
            const itemCategory = item.category.toLowerCase();
            const itemLocation = item.location.toLowerCase();

            return (
              itemTitle.includes(searchQuery) ||
              itemCategory.includes(searchQuery) ||
              itemLocation.includes(searchQuery)
            );
          })
          .slice(0, MAX_RESULTS);

        setSearchResults(filteredResults);
      } catch (error) {
        console.error(error);
      }
    }, 500),
    []
  );

  useEffect(() => {
    if (inputValue !== "") {
      debouncedSearch(inputValue);
    } else {
      setSearchResults([]);
    }
  }, [inputValue, debouncedSearch]);

  return (
    <div className="max-w-md md:max-w-3xl border-2 rounded-2xl shadow-md p-2 mx-auto relative">
      <div className="flex lg:flex md:grid-cols-1 md:grid md:gap-2 ms:grid-cols-1 ms:grid ms:gap-2 sm:grid-cols-1 sm:grid sm:gap-2 ">
        <div className="relative flex-grow">
          <HiOutlineSearch className="absolute text-gray-400 h-8 w-8 inset-y-2 left-0 flex items-center pl-3" />
          <input
            id="search-input"
            type="text"
            placeholder="What are you looking for?"
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            className="w-full pl-10 rounded-xl py-3 text-md placeholder-gray-500 focus:outline-none"
          />

          {!loading && searchResults.length > 0 && inputValue !== "" && (
            <ul
              className={`search-results ${
                selectedItemIndex !== -1
                  ? "bg-white mt-2 absolute px-4 py-2 w-[350px] rounded-lg"
                  : "bg-white mt-2  absolute px-4 py-2 w-[350px] rounded-lg"
              }`}
            >
              {searchResults.map((item, index) => (
                <li
                  key={item.id}
                  className={`search-result-item ${
                    index === selectedItemIndex ? "selected" : ""
                  }`}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => {
                    setInputValue(item.title);
                    setSearchResults([]);
                    setSelectedItemIndex(-1);
                  }}
                >
                  {item.title}
                </li>
              ))}
            </ul>
          )}
        </div>

        <button
          className=" max-w-md md:w-[20%] lg:w-[20%] sm:w-full bg-amber-600 px-4 py-3 text-white font-medium rounded-xl 
          transition-colors duration-300 ease-in-out hover:bg-amber-600 focus:bg-amber-600 "
          disabled={loading}
          onClick={handleSearch}
        >
          Find
        </button>
      </div>
    </div>
  );
};

export default HomeSearch;
